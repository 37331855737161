// Hooks
import useScript from '../../hooks/utils/useScript';

const ZendeskScript = () => {
  const showZendesk = process.env.NEXT_PUBLIC_ZENDESK_GLOBAL_DISABLED === 'false';

  const src = showZendesk ? process.env.NEXT_PUBLIC_ZENDESK_URI : null;
  const options = { id: 'ze-snippet' };

  useScript(src, options);

  return null;
};

export default ZendeskScript;
