import PropTypes from 'prop-types';
import Image from 'next/image';
import {
  CardItem,
  CardImage,
  CardContents,
  CardHeader,
  CardBody,
  CardLogo,
  CardCustomerName,
  Barcode,
  Points,
  PointsLabel,
  PointsContainer,
  MyCardWrapper,
} from './MyCardStyles';
import { formatCardString } from '../../../helpers/stringHelpers';

export default function MyCard({ customData, customerData }) {
  const { barcodeLabel, cardBackgroundImage, logo } = customData.MyCardForm;

  return (
    <MyCardWrapper>
      <CardItem className="w-full flex">
        <CardContents className="card-content-wrapper">
          <CardHeader className="w-full">
            <CardImage>
              <CardLogo>{logo && <Image src={logo.url} alt="" loading="eager" className="mt-4" fill sizes="100vw" />}</CardLogo>
              {cardBackgroundImage && (
                <Image
                  src={cardBackgroundImage.url}
                  alt=""
                  loading="eager"
                  fill
                  sizes="100vw"
                  style={{
                    objectFit: 'cover',
                  }}
                />
              )}
              <CardCustomerName>
                {customerData?.customer?.data?.firstName} {customerData?.customer?.data?.lastName}
              </CardCustomerName>
              <PointsContainer>
                <Points>
                  {customerData?.balance} <PointsLabel>points</PointsLabel>
                </Points>
              </PointsContainer>
            </CardImage>
          </CardHeader>
          <CardBody>
            <Barcode>
              {customerData?.barcodeImageBase64 && (
                <Image
                  src={customerData.barcodeImageBase64}
                  height="62"
                  width="228"
                  loading="eager"
                  alt={barcodeLabel}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              )}
              <div className="m-2">{formatCardString(customerData?.customer?.data?.sceneCardNumber)}</div>
            </Barcode>
          </CardBody>
        </CardContents>
      </CardItem>
    </MyCardWrapper>
  );
}

MyCard.propTypes = { customData: PropTypes.object, customerData: PropTypes.object };
