import { useEffect } from 'react';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateAnnouncement } from '../../../store/actions';

// Hooks
import useAnnouncementDetail from '../../hooks/announcements/useAnnouncementDetail';
import useOfferDetails from '../../hooks/offers/useOfferDetails';

// Helper
import Loader from '../Loader/Loader';
import { dynamicSpotlightContent } from '../../../helpers/spotlightHelper';
import SpotlightModalContents from './SpotlightModalContents';
import OffersCardModal from './OffersCardModal';

export default function SpotlightCardModal({ customData, item, languageCode, sectionTitle, index }) {
  const { linkedType, linkedCode } = item;

  const {
    actions,
    state: { labels },
  } = useStateMachine({ updateAnnouncement });

  const { announcementDetail, isLoading, error } = useAnnouncementDetail(languageCode, linkedCode, linkedType);
  const { offerDetails } = useOfferDetails(linkedCode, languageCode);
  const contentData = dynamicSpotlightContent(linkedType, item, announcementDetail);

  useEffect(() => {
    if (!isLoading && announcementDetail) {
      actions.updateAnnouncement(announcementDetail, isLoading);
    }
  }, [announcementDetail, isLoading]);

  if (isLoading) return <Loader />;

  if (linkedType === 'Offer' && offerDetails) {
    return (
      <OffersCardModal
        offer={offerDetails}
        customData={customData}
        languageCode={languageCode}
        sectionTitle={sectionTitle}
        listName=""
        id={index}
        isSpotlight
      />
    );
  }

  if (linkedType === 'Announcement' && contentData) {
    return (
      <SpotlightModalContents
        content={contentData}
        detailsLabel={labels?.detailsLabel}
        termsAndConditionsLabel={labels?.termsAndConditionsLabel}
      />
    );
  }

  return null;
}
