import PropTypes from 'prop-types';
import Link from 'next/link';
import Image from 'next/image';

export default function FooterSocial({ footer }) {
  return (
    <div className="social-links">
      {footer.fields.mediaLinks &&
        footer.fields.mediaLinks.map((navitem, index) => (
          <Link
            key={`m-${index}`}
            href={navitem.fields.page.href}
            as={navitem.fields.page.href}
            target={navitem.fields.page.target}
            aria-label={navitem.fields.image.label}
          >
            {navitem.fields.image && (
              <Image
                src={navitem.fields.image.url}
                alt={navitem.fields.image.label}
                width={20}
                height={20}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            )}
          </Link>
        ))}
    </div>
  );
}

FooterSocial.propTypes = { footer: PropTypes.object };
