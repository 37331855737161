// CONSTANTS
const nowDate = new Date();
const fromDate = new Date();
const toDate = new Date();

// SORTING

export function sortByAgilitySequence(list) {
  if (!list) return;

  return list.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);
}

// DATE RANGES
function monthsList(rawMonths) {
  return rawMonths && rawMonths?.filter((mon) => mon.properties.definitionName === 'ListItem');
}

function deriveMonthsObjectList(months) {
  return (
    months &&
    months.map((m) => ({
      code: m?.fields?.code,
      name: m?.fields?.name,
    }))
  );
}

function deriveMonthRangeFromRawMonths(rawMonths) {
  const monthRange = [];
  const months = deriveMonthsObjectList(monthsList(rawMonths));

  for (let i = 0; i <= 12; i++) {
    toDate.setFullYear(nowDate.getFullYear());
    fromDate.setFullYear(nowDate.getFullYear());
    toDate.setMonth(nowDate.getMonth() - i + 1);
    fromDate.setMonth(nowDate.getMonth() - i);
    toDate.setDate(1);
    fromDate.setDate(1);

    const fromMonthString = `${fromDate.getFullYear()}/${fromDate.getMonth() + 1}/${fromDate.getDate()}`;
    const toMonthString = `${toDate.getFullYear()}/${toDate.getMonth() + 1}/${toDate.getDate()}`;

    monthRange.push({
      code: `${fromMonthString}A${toMonthString}`,
      name: `${months.filter((mon) => parseInt(mon.code) === fromDate.getMonth() + 1)[0]?.name} ${fromDate.getFullYear()}`,
    });
  }

  return monthRange;
}

function deriveDateRangeFromRawDateRange(rawDateRange) {
  const todayString = `${nowDate.getFullYear()}/${nowDate.getMonth() + 1}/${nowDate.getDate()}`;

  return (
    rawDateRange &&
    rawDateRange.map((m) => {
      // eslint-disable-next-line no-shadow
      const fromDate = new Date();
      fromDate.setDate(nowDate.getDate() - m.fields.code);
      const fromString = `${fromDate.getFullYear()}/${fromDate.getMonth() + 1}/${fromDate.getDate()}`;

      return {
        code: `${fromString}A${todayString}`,
        name: m.fields?.name,
      };
    })
  );
}

export function deriveDateRangeList(rawDateRange, rawMonths) {
  const dateRange = deriveDateRangeFromRawDateRange(rawDateRange);
  const monthRange = deriveMonthRangeFromRawMonths(rawMonths);

  return [...dateRange, ...monthRange];
}

// ERROR CODES
function filterErrorMessage(rawErrorMessages, code) {
  return rawErrorMessages?.filter((em) => em.fields.errorCode.startsWith(code));
}

export function deriveErrorMessageList(rawErrorMessages, errorCodes) {
  const errorMessages = [];

  errorCodes.forEach((code) => {
    filterErrorMessage(rawErrorMessages, code).forEach((message) => {
      errorMessages.push({
        code: message.fields.errorCode,
        message: message.fields.errorMessage,
      });
    });
  });

  return errorMessages;
}

// Inline Messages
export function deriveInlineMsgList(inlineMessageList) {
  if (!inlineMessageList) return null;

  return sortByAgilitySequence(inlineMessageList).map((item) => ({
    title: item.fields.title,
    message: item.fields.message,
    provinces: item.fields.provinces_TextField || '',
    version: item.properties.versionID,
    pub: item.fields.displayForAnonymousUser || 'false',
    startDate: item.fields.startDate || null,
    endDate: item.fields.endDate || null,
    contentID: item.contentID,
  }));
}
