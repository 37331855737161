import { useState } from 'react';
import PropTypes from 'prop-types';

import Image from 'next/image';
import { FormProvider, useForm } from 'react-hook-form';

import { OptionsButton } from './EditAddressStyles';
import EditAddressModal from './EditAddressModal';

import { disableScrolling } from '../../../../helpers/behavourHelper';
import { handleGAEvents } from '../../../../helpers/handleGoogleAnalyticsHelper';

export default function EditAddress(props) {
  const { editLabel } = props;
  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const [showModal, setShowModal] = useState(false);

  const handleOptionClick = () => {
    setShowModal(!showModal);
    handleGAEvents(editLabel, 'Account', 'Edit Address Click', 'profile_edit_address_click');

    disableScrolling();
  };
  return (
    <>
      <OptionsButton aria-label={editLabel} onClick={handleOptionClick} role="button">
        <Image
          src="/assets/pencil-icon.svg"
          alt={editLabel}
          title={editLabel}
          height={16}
          width={16}
          style={{
            maxWidth: '100%',
            height: 'auto',
            margin: '0 auto',
          }}
        />
      </OptionsButton>

      {showModal && (
        <FormProvider {...formMethods}>
          <EditAddressModal formMethods={formMethods} {...props} showModal={showModal} setShowModal={setShowModal} />
        </FormProvider>
      )}
    </>
  );
}

EditAddress.propTypes = {
  editLabel: PropTypes.string,
};
