import PropTypes from 'prop-types';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';

// Global state
import { useStateMachine } from 'little-state-machine';

import { HeaderLogoOnlyWrapper } from './HeaderStyles';

// Helpers
import {
  handleGaEcommImpression,
  getGiftCardOrderMapping,
  getBYOTOrderMapping,
  getP4COrderMapping,
} from '../../../../helpers/handleGoogleAnalyticsHelper';
import { handleKeyDown } from '../../../../helpers/utils';
import { amountSelected, calcPointsTotal, byotAmountSelected } from '../../../../helpers/pointsHelpers';

export default function HeaderWithLogoOnly({ image, title, hideSpacing }) {
  const {
    state: {
      giftCards: { redeem, selectedGiftCardIndex },
      points: { added },
      transactions: { awards },
    },
  } = useStateMachine();

  const total = calcPointsTotal(added) * redeem.quantity;
  const router = useRouter();

  function notifyGiftCardItemRemoved() {
    const amount = amountSelected(added);
    handleGaEcommImpression(
      'gc_remove_from_cart',
      getGiftCardOrderMapping(
        total,
        redeem,
        amount,
        '',
        'reward_giftcard_remove_from_cart',
        'Gift Card Remove From Cart',
        selectedGiftCardIndex,
        amount
      )
    );
  }

  function notifyBYOTItemRemoved() {
    const amount = byotAmountSelected(added);
    const awardProductCodeBYOT = awards
      ? awards.awardProduct.filter((award) => award.id === added.id).map((items) => items.code)
      : '';
    handleGaEcommImpression('byot_remove_from_cart', getBYOTOrderMapping(total, redeem, amount, awardProductCodeBYOT[0]));
  }

  function notifyP4CItemRemoved() {
    const awardProductCodePFC = added
      ? added
          .filter((award) => award.id === awards.awardProduct.id)
          .map((items) => items.code)
          .toString()
      : '';
    const amount = amountSelected(added);
    handleGaEcommImpression('p4c_remove_from_cart', getP4COrderMapping(total, redeem, amount, awardProductCodePFC));
  }

  const handleClick = () => {
    // first condition for gift card redeem, second for byot
    if (redeem && Object.keys(redeem.giftCardDetails).length !== 0 && redeem.step === 2) {
      notifyGiftCardItemRemoved();
    } else if (redeem && redeem.quantity > 0 && router.asPath.indexOf('travel') !== -1) {
      notifyBYOTItemRemoved();
    } else if (redeem && redeem.quantity > 0 && router.asPath.indexOf('redeem-points-for-credit') !== -1) {
      notifyP4CItemRemoved();
    }
  };

  if (image || title) {
    return (
      <HeaderLogoOnlyWrapper hideSpacing={hideSpacing}>
        <Link
          href="/"
          role="link"
          aria-label={title}
          onClick={handleClick}
          onKeyDown={(e) => handleKeyDown(e, handleClick)}
          tabIndex="0"
        >
          <Image
            src={image}
            alt={`${title}`}
            width={110}
            height={34}
            quality={25}
            priority
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Link>
      </HeaderLogoOnlyWrapper>
    );
  }
  return null;
}

HeaderWithLogoOnly.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  hideSpacing: PropTypes.bool,
};
