/* eslint-disable no-shadow */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';
import { Controller, useFormContext } from 'react-hook-form';
import { updateUser } from '../../../store/actions';
import ErrorMessage from '../../common/Errors/ErrorMessage';
import FieldLayout from '../../layouts/FieldLayout';

import { StyledInput, FormInputLabel } from '../FormStyles';

function FormNumberInput({ isDisabled, isReadOnly, name, label, defaultVal, maxAllowedInput, placeholder, rules, onFocus }) {
  const { state, actions } = useStateMachine({ updateUser });
  const [inputVal, setInputVal] = useState(defaultVal || '');

  const formContext = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { control, setValue, getValues, formState } = formContext;

  // Component Mount -> set the default label once only
  useEffect(() => {
    if (defaultVal) {
      setValue(name, defaultVal);
    }
  }, []);

  useEffect(() => {
    // Every state update after mount, we need to capture the user input and
    // update the form to use the new values

    if (inputVal && inputVal.length <= maxAllowedInput) {
      setValue(name, inputVal || defaultVal);
      actions.updateUser({ [name]: inputVal });
    }
  }, [inputVal]);

  const error = formState?.errors?.[name];
  const isError = (error && formState.touchedFields?.[name]) || (error && formState.isSubmitted);

  const handleChange = (e) => {
    if ((e.target.value && e.target.value.length <= maxAllowedInput) || e.target.value === '') {
      const result = e.target.value.replace(/[^0-9]/gi, '');
      setInputVal(result);
      setValue(result);
    }

    return getValues(name);
  };

  return (
    <FieldLayout>
      {Boolean(label) && (
        <FormInputLabel className="mt-3" id={`form-${name}`} htmlFor={name}>
          {label}
        </FormInputLabel>
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultVal}
        rules={{
          ...rules,
        }}
        // eslint-disable-next-line no-unused-vars
        render={({ field: { onBlur, name, ref }, fieldState: { invalid, isTouched, isDirty, error }, formState }) => (
          <>
            <StyledInput
              id={name}
              name={name}
              type="text"
              aria-invalid={!!error}
              aria-labelledby={`form-${name}`}
              aria-disabled={isDisabled}
              aria-readonly={isReadOnly}
              placeholder={placeholder}
              inputRef={ref}
              disabled={isDisabled}
              onBlur={onBlur}
              value={inputVal}
              className={`${isDisabled ? 'field-is-disabled' : ''}${
                (error && isTouched) || (error && formState.isSubmitted) ? ' field-has-error' : ''
              }`}
              onChange={handleChange}
              isError={Boolean(error)}
              onFocus={onFocus}
            />
          </>
        )}
      />
      {isError && <ErrorMessage error={error} />}
    </FieldLayout>
  );
}

FormNumberInput.propTypes = {
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  defaultVal: PropTypes.string,
  placeholder: PropTypes.string,
  rules: PropTypes.object,
  isReadOnly: PropTypes.bool,
  maxAllowedInput: PropTypes.number,
};

export default FormNumberInput;
