import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';
import { updatePoints, updateTransactions, updateLayout } from '../../../../store/actions';

// Components
import WrapperLayout from '../../../layouts/WrapperLayout';
import ConfirmedRedeemed from '../ConfirmedRedeemed';

// Shop Form V2
import PointsTransactions from './points-transactions/PointsTransactions';
import ConfirmDetails from './ConfirmDetails';

// Hooks
import useDynamicHeader from '../../../hooks/utils/useDynamicHeader';

/**
 *
 * @prop {customData} Object - Agility Data Fields
 * @prop {page} String - tells us what page
 * @prop {step} Number
 *
 *
 */

export default function PointsShop({ customData, page, languageCode }) {
  // Global State
  const {
    state: {
      points: { step },
    },
    actions,
  } = useStateMachine({ updatePoints, updateTransactions, updateLayout });

  useEffect(() => {
    // On Mount, go back to step 1 and clear award selections
    // this is to reset form when switching between byot/pfc redemption
    if (step >= 1) {
      actions.updatePoints({
        step: 1,
        added: [],
      });
    }
  }, []);

  useDynamicHeader(step);

  const pointsTransactions = (
    <WrapperLayout className="w-full relative" isGray mobileIsWhite>
      <div className="global-content-wrapper">
        <PointsTransactions page={page} customData={customData} step={step} languageCode={languageCode} />
      </div>
    </WrapperLayout>
  );

  const renderSwitch = (currentStep) => {
    switch (currentStep) {
      default:
        return pointsTransactions;
      case 1:
        return pointsTransactions;
      case 2:
        return (
          <WrapperLayout className="w-full relative pt-8 pb-6 md:pb-28" isGray mobileIsWhite>
            <div className="global-content-wrapper">
              <ConfirmDetails page={page} customData={customData} languageCode={languageCode} />
            </div>
          </WrapperLayout>
        );
      case 3:
        return (
          <WrapperLayout className="w-full relative py-10" isGray mobileIsWhite fullMinHeight>
            <div className="global-content-wrapper">
              <ConfirmedRedeemed page={page} customData={customData} />
            </div>
          </WrapperLayout>
        );
    }
  };

  return <>{renderSwitch(step)}</>;
}

PointsShop.propTypes = {
  customData: PropTypes.object,
  page: PropTypes.string,
  languageCode: PropTypes.string,
};
