import { useQuery } from 'react-query';
import { getProvinces } from '../../../pages/api/services';

export default function useProvinces(languageCode) {
  const { isLoading, error, data } = useQuery(['fetchProvinces', languageCode], async () => {
    try {
      if (languageCode) {
        const res = await getProvinces(languageCode);
        return res.data.data;
      }
    } catch (error) {
      console.error(error);
    }
  });

  return { isLoading, error, provinces: data };
}
