import RichTextArea from './RichTextArea';
import Heading from './Heading';
import CreateAccount from './CreateAccount';
import CreateAccountGK from './CreateAccountGK';
import SignIn from './SignIn';
import PageHeader from './PageHeader';
import BrandStores from './BrandStores';
import GiftCards from './GiftCards';
import BrandPage from './BrandPage';
import FeatureBannersCarousel from './FeatureBannersCarousel';
import BrandRewards from './BrandRewards';
import RedeemTrending from './RedeemTrending';
import BYOT from './BYOT';
import PartialBYOT from './PartialBYOT';
import PFC_V2 from './PFC_V2';
import RecentOrders from './RecentOrders';
import Orders from './Orders';
import OrderDetails from './OrderDetails';
import UserStateRichTextArea from './UserStateRichTextArea';
import SingleSignon from './SingleSignon';
import SsoLoading from './SsoLoading';
import TestSSO from './TestSSO';
import GiftCardsPage from './GiftCardsPage';
import CategoryRewards from './CategoryRewards';
import GiftCardDetailsPage from './GiftCardDetailsPage';
import ProfilePage from './ProfilePage';
import SettingsPage from './SettingsPage';
import PointsPage from './PointsPage';
import OffersPage from './OffersPage';
import SharedHeroBanner from './SharedHeroBanner';
import HomepageUserBanner from './HomepageUserBanner';
import SharedAccordionContentIntroSection from './SharedAccordionContentIntroSection';
import SharedAccordionAccessibilitySection from './SharedAccordionAccessibilitySection';
import SharedAccordionSecuritySection from './SharedAccordionSecuritySection';
import SharedJuicerFeedSection from './SharedJuicerFeedSection';
import SharedTwoColumnContentSection from './SharedTwoColumnContentSection';
import SharedDownloadAppSection from './SharedDownloadAppSection';
import GuestEarnRewardsSection from './GuestEarnRewardsSection';
import LimitedTimeOffersPage from './LimitedTimeOffersPage';
import SharedAccordionPrivacySection from './SharedAccordionPrivacySection';
import SharedAccordionTermsAndConditionsSection from './SharedAccordionTermsAndConditionsSection';
import GuestFeatureBannersCarousel from './GuestFeatureBannersCarousel';
import HomepageOffersBanner from './HomepageOffersBanner';
import Spotlights from './Spotlights';
import Events from './Events';
import MyCardPage from './MyCardPage';
import ScotiabankCardsCarousel from './ScotiabankCardsCarousel';
import GuestJoinSceneSection from './GuestJoinSceneSection';
import SharedFAQSections from './SharedFAQSections';
import SharedFAQSection from './SharedFAQSection';
import AboutPage from './AboutPage';
import UnsubscribePage from './UnsubscribePage';
import SendMyCardPage from './SendMyCardPage';
import ErrorPage from './ErrorPage';
import Onboarding from './Onboarding';
import SharedAccordionPartnerTermsAndConditionsSection from './SharedAccordionPartnerTermsAndConditionsSection';
import TravelServiceSection from './TravelServiceSection';
import SettingsGuestPage from './SettingsGuestPage';
import GuestOfferDetails from './GuestOfferDetails';
import GAModule from './GAModule';
import SharedTabbedContent from './SharedTabbedContent';
import SharedEarnRewardsSection from './SharedEarnRewardsSection';
import SharedRichTextArea from './SharedRichTextArea';
import AboutSceneLogoDescriptionSection from './AboutSceneLogoDescriptionSection';
import SharedAboutEmpireSection from './SharedAboutEmpireSection';
import SharedComingSoonLocationsSection from './SharedComingSoonLocationsSection';
import GridSection3Columns from './GridSection3Columns';
import GridSection4Columns from './GridSection4Columns';
import RedeemCategoriesByProvince from './RedeemCategoriesByProvince';
import SpendPointsV2 from './SpendPointsV2';
import OurPartners from './OurPartners';
import Zendesk from './Zendesk';
import PinPage from './PinPage';
// All of the Agility Page Module Components that are in use in this site need to be imported into this index file.
// Place Page Modules in allModules array below, passing in a name and the component.

const allModules = [
  { name: 'RichTextArea', module: RichTextArea },
  { name: 'Heading', module: Heading },
  { name: 'CreateAccount', module: CreateAccount },
  { name: 'SignIn', module: SignIn },
  { name: 'PageHeader', module: PageHeader },
  { name: 'BrandStores', module: BrandStores },
  { name: 'GiftCards', module: GiftCards },
  { name: 'BrandPage', module: BrandPage },
  { name: 'FeatureBannersCarousel', module: FeatureBannersCarousel },
  { name: 'BrandRewards', module: BrandRewards },
  { name: 'RedeemTrending', module: RedeemTrending },
  { name: 'BYOT', module: BYOT },
  { name: 'PartialBYOT', module: PartialBYOT },
  { name: 'PFCv2', module: PFC_V2 },
  { name: 'RecentOrders', module: RecentOrders },
  { name: 'Orders', module: Orders },
  { name: 'OrderDetails', module: OrderDetails },
  { name: 'UserStateRichTextArea', module: UserStateRichTextArea },
  { name: 'SingleSignon', module: SingleSignon },
  { name: 'SsoLoading', module: SsoLoading },
  { name: 'TestSSO', module: TestSSO },
  { name: 'GiftCardsPage', module: GiftCardsPage },
  { name: 'CategoryRewards', module: CategoryRewards },
  { name: 'GiftCardDetailsPage', module: GiftCardDetailsPage },
  { name: 'ProfilePage', module: ProfilePage },
  { name: 'SettingsPage', module: SettingsPage },
  { name: 'PointsPage', module: PointsPage },
  { name: 'OffersPage', module: OffersPage },
  { name: 'SharedHeroBanner', module: SharedHeroBanner },
  { name: 'HomepageUserBanner', module: HomepageUserBanner },
  { name: 'SharedAccordionContentIntroSection', module: SharedAccordionContentIntroSection },
  { name: 'SharedAccordionAccessibilitySection', module: SharedAccordionAccessibilitySection },
  { name: 'SharedAccordionSecuritySection', module: SharedAccordionSecuritySection },
  { name: 'SharedJuicerFeedSection', module: SharedJuicerFeedSection },
  { name: 'SharedTwoColumnContentSection', module: SharedTwoColumnContentSection },
  { name: 'SharedDownloadAppSection', module: SharedDownloadAppSection },
  { name: 'GuestEarnRewardsSection', module: GuestEarnRewardsSection },
  { name: 'LimitedTimeOffersPage', module: LimitedTimeOffersPage },
  { name: 'SharedAccordionPrivacySection', module: SharedAccordionPrivacySection },
  { name: 'SharedAccordionTermsAndConditionsSection', module: SharedAccordionTermsAndConditionsSection },
  { name: 'GuestFeatureBannersCarousel', module: GuestFeatureBannersCarousel },
  { name: 'HomepageOffersBanner', module: HomepageOffersBanner },
  { name: 'Spotlights', module: Spotlights },
  { name: 'Events', module: Events },
  { name: 'MyCardPage', module: MyCardPage },
  { name: 'ScotiabankCardsCarousel', module: ScotiabankCardsCarousel },
  { name: 'GuestJoinSceneSection', module: GuestJoinSceneSection },
  { name: 'GuestOfferDetails', module: GuestOfferDetails },
  { name: 'SharedFAQSections', module: SharedFAQSections },
  { name: 'SharedFAQSection', module: SharedFAQSection },
  { name: 'AboutPage', module: AboutPage },
  { name: 'UnsubscribePage', module: UnsubscribePage },
  { name: 'SendMyCardPage', module: SendMyCardPage },
  { name: 'ErrorPage', module: ErrorPage },
  { name: 'Onboarding', module: Onboarding },
  { name: 'SharedAccordionPartnerTermsAndConditionsSection', module: SharedAccordionPartnerTermsAndConditionsSection },
  { name: 'TravelServiceSection', module: TravelServiceSection },
  { name: 'SettingsGuestPage', module: SettingsGuestPage },
  { name: 'GAModule', module: GAModule },
  { name: 'CreateAccount-GK', module: CreateAccountGK },
  { name: 'SharedTabbedContent', module: SharedTabbedContent },
  { name: 'SharedEarnRewardsSection', module: SharedEarnRewardsSection },
  { name: 'SharedRichTextArea', module: SharedRichTextArea },
  { name: 'AboutSceneLogoDescriptionSection', module: AboutSceneLogoDescriptionSection },
  { name: 'SharedAboutEmpireSection', module: SharedAboutEmpireSection },
  { name: 'SharedComingSoonLocationsSection', module: SharedComingSoonLocationsSection },
  { name: 'GridSection3Columns', module: GridSection3Columns },
  { name: 'GridSection4Columns', module: GridSection4Columns },
  { name: 'RedeemCategoriesByProvince', module: RedeemCategoriesByProvince },
  { name: 'SpendPointsV2', module: SpendPointsV2 },
  { name: 'OurPartners', module: OurPartners },
  { name: 'Zendesk', module: Zendesk },
  { name: 'PinPage', module: PinPage },
];

export const getModule = (moduleName) => {
  if (!moduleName) return null;
  const obj = allModules.find((m) => m.name.toLowerCase() === moduleName.toLowerCase());
  if (!obj) return null;
  return obj.module;
};
