import { useState } from 'react';
import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';

import Image from 'next/image';
import FieldLayout from '../../layouts/FieldLayout';
import { StyledReadonlyInput, CopyTextReadonlyWrapper } from '../FormStyles';
import { updateNotification } from '../../../store/actions';

function FormReadonlyInput({
  isDisabled,
  name,
  label,
  defaultVal,
  fieldType,
  onFocus,
  onClick,
  className,
  handleKeyDown,
  copyImageLabel,
  value,
  copiedSuccessfulyLabel,
}) {
  // eslint-disable-next-line no-unused-vars
  const [copySuccess, setCopySuccess] = useState('');

  const { actions } = useStateMachine({ updateNotification });

  const copyToClipBoard = async (copyMe) => {
    try {
      actions.updateNotification({
        message: copiedSuccessfulyLabel,
        status: 'success',
      });
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  return (
    <FieldLayout>
      {Boolean(label) && (
        <label id={`form-${name}`} htmlFor={name} className="text-left">
          {label}
        </label>
      )}
      <div className="relative">
        <StyledReadonlyInput
          onFocus={onFocus}
          onClick={onClick}
          name={name}
          id={name}
          type={fieldType || 'text'}
          aria-labelledby={`form-${name}`}
          aria-disabled={isDisabled}
          aria-required
          readOnly
          defaultValue={defaultVal}
          disabled={isDisabled}
          className={className}
          value={value}
        />
        <CopyTextReadonlyWrapper role="button" onClick={() => copyToClipBoard(value)} onKeyDown={handleKeyDown} tabIndex="0">
          <Image
            src="/assets/copy.svg"
            width={30}
            height={30}
            alt={copyImageLabel}
            style={{
              maxWidth: '100%',
              height: 'auto',
              margin: 'auto 0.5rem',
            }}
          />
        </CopyTextReadonlyWrapper>
      </div>
    </FieldLayout>
  );
}

FormReadonlyInput.propTypes = {
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  defaultVal: PropTypes.string,
  fieldType: PropTypes.string,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  handleKeyDown: PropTypes.func,
  className: PropTypes.string,
  copyImageLabel: PropTypes.string,
  value: PropTypes.string,
  copiedSuccessfulyLabel: PropTypes.string,
};

export default FormReadonlyInput;
