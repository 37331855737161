import TagManager from 'react-gtm-module';
import { formatDate } from './dateHelpers';
import { camelCaseToSentence } from './stringHelpers';
import { chunkArr } from './utils';

// Todo: remove this event after testing (login event)
export function handleGALoginEvent(
  eventType,
  data = { userData: { uuid: '', idpUserId: 'mock-idpId' }, nonceId: '', correlationId: '' }
) {
  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GA_TAGMANAGER,
    dataLayer: {
      event: eventType,
      ...data,
    },
    auth: process.env.NEXT_PUBLIC_GTM_AUTH_KEY,
    preview: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
  };
  TagManager.dataLayer(tagManagerArgs);
}

export function handleGAEventsBase(dataLayer) {
  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GA_TAGMANAGER,
    dataLayer,
    auth: process.env.NEXT_PUBLIC_GTM_AUTH_KEY,
    preview: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
  };
  TagManager.dataLayer(tagManagerArgs);
}

export function handleGAEvents(title, page, action, eventType) {
  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GA_TAGMANAGER,
    dataLayer: {
      event: eventType,
      category: page,
      action,
      label: title,
    },
    auth: process.env.NEXT_PUBLIC_GTM_AUTH_KEY,
    preview: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
  };
  TagManager.dataLayer(tagManagerArgs);
}

export function stringConcatHelper(string1, string2) {
  return `${string1} ${string2}`;
}

export function stringConcatHelper4(string1, string2, string3, string4) {
  return `${string1} | ${string2} | ${string3} | ${string4}`;
}

export function viewPointsForCreditEcomm() {
  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GA_TAGMANAGER,
    dataLayer: {
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_name: 'Points For Credit',
            currency: 'CAD',
            item_category: 'Points For Credit',
            price: 0,
            index: 0,
            discount: 0,
          },
        ],
      },
    },
    auth: process.env.NEXT_PUBLIC_GTM_AUTH_KEY,
    preview: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
  };
  TagManager.dataLayer(tagManagerArgs);
}

export function handleGaEcommImpression(event, object) {
  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GA_TAGMANAGER,
    dataLayer: {
      event,
      ecommerce: object,
    },
    auth: process.env.NEXT_PUBLIC_GTM_AUTH_KEY,
    preview: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
  };
  TagManager.dataLayer(tagManagerArgs);
}

export function handleGaEventForSelect(event, id, type) {
  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GA_TAGMANAGER,
    dataLayer: {
      event,
      item_id: id,
      content_type: type,
    },
    auth: process.env.NEXT_PUBLIC_GTM_AUTH_KEY,
    preview: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
  };
  TagManager.dataLayer(tagManagerArgs);
}

export function handleGaList(event, items) {
  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GA_TAGMANAGER,
    dataLayer: {
      event,
      ecommerce: {
        items,
      },
    },
    auth: process.env.NEXT_PUBLIC_GTM_AUTH_KEY,
    preview: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
  };
  TagManager.dataLayer(tagManagerArgs);
}

export function handleGaEcommImpressionList(event, list, alt_event = '', eventAction = '', itemListName = '') {
  const items = {
    alt_event,
    eventCategory: 'Gift Cards',
    eventAction,
    list_name: itemListName,
    item_list_name: itemListName,

    items: list.map((card) => ({
      item_id: card?.brandId,
      item_name: stringConcatHelper(card?.brandName, 'GC'),
      affiliation: card?.brandName,
      currency: 'CAD',
      index: card?.position || list?.indexOf(card) + 1,
      item_brand: card?.brandName,
      item_category: 'Gift Cards',
      item_category2: card?.cmsContent?.category?.name,
      quantity: 1,
    })),
  };

  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GA_TAGMANAGER,
    dataLayer: {
      event,
      ecommerce: items,
    },
    auth: process.env.NEXT_PUBLIC_GTM_AUTH_KEY,
    preview: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
  };

  TagManager.dataLayer(tagManagerArgs);
}

export function handleGaEcommOfferTileList(event, list, listName, categoryName, province) {
  const items = {
    alt_event: 'offers_impression',
    eventCategory: 'Partner Offers',
    eventAction: 'Offer Impressions',
    list_name: listName,
    items: list?.map((offer) => {
      if (offer?.cmsContent?.title && offer?.cmsContent?.brand?.title && offer?.offerId) {
        const offerItem = {
          item_list_name: listName,
          item_id: offer?.offerCode,
          item_name: offer?.cmsContent?.subTitle,
          affiliation: offer?.cmsContent?.brand?.title,
          index: offer?.position || list?.indexOf(offer) + 1,
          item_brand: offer?.cmsContent?.brand?.title,
          item_category: 'Offers',
          item_category2: categoryName,
          item_variant: offer?.endDate,
          quantity: 1,
          prov_segement: province,
        };
        if (offer?.tags?.includes('loadable')) {
          offerItem.item_category3 = `Loadable${offer?.isOptedIn ? ' - loaded' : ''}`;
        }
        if (offer?.cmsContent?.isCouponBased) {
          offerItem.item_category3 = 'Coupons';
          offerItem.coupon = '';
        }
        return offerItem;
      }
    }),
  };
  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GA_TAGMANAGER,
    dataLayer: {
      event,
      ecommerce: items,
    },
    auth: process.env.NEXT_PUBLIC_GTM_AUTH_KEY,
    preview: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
  };
  TagManager.dataLayer(tagManagerArgs);
}

export function handleGaEcommWhatIsMore(event, eventListName, list) {
  const items = {
    alt_event: 'event_reward_impression',
    eventCategory: 'Events',
    eventAction: 'Events Impressions',
    list_name: eventListName,
    items: list?.map((item) => ({
      item_id: item?.code,
      item_name: item?.title,
      item_list_name: eventListName,
      affiliation: item?.brand?.title,
      index: list?.indexOf(item) + 1,
      item_brand: item?.brand?.title,
      item_category: 'Events',
      item_category2: '',
      item_variant: `${item?.startDate} - ${item?.endDate}`,
      quantity: 1,
    })),
  };
  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GA_TAGMANAGER,
    dataLayer: {
      event,
      ecommerce: items,
    },
    auth: process.env.NEXT_PUBLIC_GTM_AUTH_KEY,
    preview: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
  };
  TagManager.dataLayer(tagManagerArgs);
}

export function handleGaEcommTrandingSliderList(event, list, sectionTitle) {
  const items = {
    alt_event: 'redeem_reward_impression',
    eventCategory: 'Reward',
    eventAction: 'Reward Impressions',
    list_name: sectionTitle,
    items: list.map((reward) => ({
      item_id: reward?.code,
      item_name: reward?.title,
      affiliation: reward?.brandInfo?.fields?.title,
      currency: 'CAD',
      index: list?.indexOf(reward) + 1,
      item_brand: reward?.brandInfo?.fields?.title,
      item_category: 'Rewards',
      item_category2: reward?.category?.fields?.name,
      item_variant: reward?.category?.fields?.code,
      price: 0,
      quantity: 1,
    })),
  };
  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GA_TAGMANAGER,
    dataLayer: {
      event,
      ecommerce: items,
    },
    auth: process.env.NEXT_PUBLIC_GTM_AUTH_KEY,
    preview: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
  };
  TagManager.dataLayer(tagManagerArgs);
}

export function handleGaEcommShopToRedeemList(event, list, sectionTitle) {
  const items = {
    alt_event: 'rewards_shop_redeem_impression',
    eventCategory: 'Shop to Redeem',
    eventAction: 'Shop To Redeem Impression',
    list_name: sectionTitle,
    items: list.map((brand) => ({
      item_list_name: sectionTitle,
      item_id: brand?.code,
      item_name: brand?.title,
      affiliation: brand?.title,
      index: list?.indexOf(brand) + 1,
      item_brand: brand?.title,
      item_category: 'Rewards',
      item_category2: 'Shop to Redeem',
      quantity: 1,
    })),
  };
  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GA_TAGMANAGER,
    dataLayer: {
      event,
      ecommerce: items,
    },
    auth: process.env.NEXT_PUBLIC_GTM_AUTH_KEY,
    preview: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
  };
  TagManager.dataLayer(tagManagerArgs);
}

export function handleGaSignupPageError(errorObject) {
  if (Object.keys(errorObject).length > 0) {
    Object.keys(errorObject).forEach((key) => {
      handleGAEvents(camelCaseToSentence(key), 'Registration Events', 'Error', 'sign_up_error');
    });
  }
}

export function getOffersMappedObject(offer, index, listName, province) {
  if (Object.keys(offer).length) {
    return {
      alt_event: 'offers_tile_click',
      eventCategory: 'Partner Offers',
      eventAction: 'Offer Click',
      eventLabel: `${offer?.cmsContent?.brand?.title} | ${
        listName === 'More offers' ? offer?.cmsContent?.title : offer?.cmsContent?.subTitle
      } | ${offer?.offerCode}`,
      partner: offer?.cmsContent?.brand?.title,
      offer_name: listName === 'More offers' ? offer?.cmsContent?.title : offer?.cmsContent?.subTitle,
      offer_sku: offer?.offerCode,
      category: offer?.category[0] || '',
      list_name: listName,
      tile_position: index,
      offer_type: offer?.cmsContent?.isCouponBased ? 'Coupons' : '',
      prov_segment: province,
      items: [
        {
          item_id: offer?.offerCode,
          item_name: listName === 'More offers' ? offer?.cmsContent?.title : offer?.cmsContent?.subTitle,
          affiliation: offer?.cmsContent?.brand?.title,
          index,
          item_brand: offer?.cmsContent?.brand?.title,
          item_category: 'Offers',
          item_category2: offer?.category[0],
          item_category3: offer?.cmsContent?.isCouponBased ? 'Coupons' : '',
          item_list_name: listName,
          item_variant: offer?.endDate !== null ? formatDate(offer?.endDate, 'MMMM DD, YYYY') : 'N/A',
          quantity: 1,
        },
      ],
    };
  }
  return {};
}

export function getMoreOffersMappedObject(offer, index, listName, province) {
  if (Object.keys(offer).length) {
    return {
      alt_event: 'offers_tile_click',
      eventCategory: 'Partner Offers',
      eventAction: 'Offer Click',
      eventLabel: `${offer?.cmsContent?.brand?.title} | ${
        listName === 'More offers' ? offer?.cmsContent?.title : offer?.cmsContent?.subTitle
      } | ${offer?.offerCode}`,
      partner: offer?.cmsContent?.brand?.title,
      offer_name: listName === 'More offers' ? offer?.cmsContent?.title : offer?.cmsContent?.subTitle,
      offer_sku: offer?.offerCode,
      category: offer?.category[0] || '',
      list_name: listName,
      tile_position: index,
      prov_segment: province,
      items: [
        {
          item_id: offer?.offerCode,
          item_name: listName === 'More offers' ? offer?.cmsContent?.title : offer?.cmsContent?.subTitle,
          affiliation: offer?.cmsContent?.brand?.title,
          index,
          item_brand: offer?.cmsContent?.brand?.title,
          item_category: 'Offers',
          item_category2: offer?.category[0],
          item_list_name: listName,
          item_variant: offer?.endDate !== null ? formatDate(offer?.endDate, 'MMMM DD, YYYY') : 'N/A',
          quantity: 1,
        },
      ],
    };
  }
  return {};
}

export function getOffersModalMappedObject(offer, index, listName, eventAction, altEvent, province) {
  if (Object.keys(offer).length) {
    const offerItems = [
      {
        item_id: offer?.offerCode,
        item_name: listName === 'More offers' ? offer?.cmsContent?.title : offer?.cmsContent?.subTitle,
        affiliation: offer?.cmsContent?.brand?.title,
        index,
        item_brand: offer?.cmsContent?.brand?.title,
        item_category: 'Offers',
        item_category2: offer?.category[0],
        item_list_name: listName,
        item_variant: offer?.endDate !== null ? formatDate(offer?.endDate, 'MMMM DD, YYYY') : 'N/A',
        quantity: 1,
      },
    ];
    if (offer?.cmsContent?.isCouponBased) {
      offerItems[0].item_category3 = 'Coupons';
    }
    const offerItem = {
      alt_event: altEvent,
      eventCategory: 'Partner Offers',
      eventAction,
      eventLabel: `${offer?.cmsContent?.brand?.title} | ${
        listName === 'More offers' ? offer?.cmsContent?.title : offer?.cmsContent?.subTitle
      } | ${offer?.offerCode}`,
      partner: offer?.cmsContent?.brand?.title,
      offer_name: listName === 'More offers' ? offer?.cmsContent?.title : offer?.cmsContent?.subTitle,
      offer_sku: offer?.offerCode,
      category: offer?.category[0] || '',
      list_name: listName,
      currency: 'CAD',
      value: '0',
      tile_position: index,
      offer_type: offer?.cmsContent?.isCouponBased ? 'Coupons' : '',
      prov_segment: province,
      items: offerItems,
    };
    return offerItem;
  }
  return {};
}

export function registerGaEvents(touched) {
  if (Object.keys(touched).length > 0) {
    Object.keys(touched).forEach((key) => {
      handleGAEvents(camelCaseToSentence(key), 'Registration Events', 'Form Field Click', 'sign_up_form_field');
    });
  }
}
export function getConfirmPointsForCreditMapped(
  orderNumber,
  itemName,
  RedemptionPoints,
  awardProductCodePFC,
  amountSelected,
  total,
  alt_event = '',
  eventAction = '',
  totalDollar
) {
  const obj = {
    currency: 'CAD',
    value: RedemptionPoints,
    alt_event,
    eventCategory: itemName,
    eventAction,
    eventLabel: `${itemName} | ${total}`,
    points_redeem: total,
    reward_name: itemName,
    reward_type: totalDollar,
    category: itemName,

    items: [
      {
        item_id: awardProductCodePFC,
        item_name: itemName,
        item_category: itemName,
        item_variant: totalDollar,
        price: total,
        quantity: 1,
      },
    ],
  };

  if (orderNumber !== '') {
    obj.transaction_id = orderNumber;
  }

  return obj;
}

export function getRewardBrandDetails(event, item_id, item_name, sectionTitle, index) {
  const brandDetails = {
    alt_event: 'rewards_shop_redeem_click',
    eventCategory: 'Reward',
    eventAction: 'Shop To Redeem Click',
    eventLabel: `Shop to Redeem | ${item_name}`,
    partner: item_name,
    reward_name: item_name,
    category: 'Shop To Redeem',
    list_name: sectionTitle,
    tile_position: parseInt(index?.split('-')[1]) + 1,
    items: [
      {
        item_list_name: sectionTitle,
        item_id,
        item_name,
        affiliation: item_name,
        index: parseInt(index?.split('-')[1]) + 1,
        item_brand: item_name,
        item_category: 'Rewards',
        item_category2: 'Shop To Redeem',
        price: 0,
        quantity: 1,
      },
    ],
  };
  handleGaEcommImpression(event, brandDetails);
}
export function getBrandOutboundClick(event, item_id, item_name) {
  const brandDetails = getBrandOutboundClickMapped(item_id, item_name);
  handleGaEcommImpression(event, brandDetails);
}

export function getBrandOutboundClickMapped(item_id, item_name) {
  const brandDetails = {
    alt_event: 'rewards_shop_redeem_detail_click',
    eventCategory: 'Reward',
    eventAction: 'Shop To Redeem Outbound Click',
    eventLabel: `Shop to Redeem | ${item_name}`,
    partner: item_name,
    reward_name: item_name,
    category: 'Shop To Redeem',
    items: [
      {
        item_id,
        item_name,
        affiliation: item_name,
        item_brand: item_name,
        item_category: 'Rewards',
        item_category2: 'Shop To Redeem',
        price: 0,
        quantity: 1,
      },
    ],
  };
  return brandDetails;
}

export function getRewardPageButtonClickMapping(item, index, sectionTitle) {
  return {
    currency: 'CAD',
    value: '',
    alt_event: 'reward_detail_click',
    eventCategory: 'Rewards',
    eventAction: 'Reward Outbound Click',
    eventLabel: `${item?.brandInfo?.fields?.title} | '' | ${item?.title}`,
    partner: item?.brandInfo?.fields?.title,
    reward_name: item?.title,
    points_redeem: '',
    reward_sku: item?.code,
    reward_type: item?.pointsConversionLabel,
    category: item?.category?.fields?.name,
    list_name: sectionTitle,
    items: [
      {
        item_list_name: sectionTitle,
        item_id: item?.code,
        item_name: item?.title,
        affiliation: item?.brandInfo?.fields?.title,
        index: index + 1,
        item_brand: item?.brandInfo?.fields?.title,
        item_category: 'Rewards',
        item_category2: item?.category?.fields?.name,
        item_variant: item?.category?.fields?.code,
        price: '',
        quantity: 1,
      },
    ],
  };
}

export function getEmailOptLabel(data) {
  let gaLabel = 'Email -';
  for (const key in data) {
    if (data[key] == true && key !== 'EmailOptin' && key !== 'SMS') {
      gaLabel = `${gaLabel} ${key}`;
    }
  }
  return gaLabel;
}

export function spendPointsForCreditEcomm(
  event,
  itemName,
  total,
  amountSelected,
  selectedCardId,
  altEvent = '',
  eventAction = '',
  totalDollar
) {
  const isCurrencyValue = event === 'p4c_begin_checkout' || event === 'p4c_add_to_cart' || event === 'byot_add_to_cart';

  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GA_TAGMANAGER,
    dataLayer: {
      event,
      ecommerce: {
        alt_event: altEvent,
        eventCategory: itemName,
        eventAction,
        eventLabel: `${itemName} | ${total}`,
        points_redeem: total,
        reward_name: itemName,
        reward_type: totalDollar,
        category: itemName,
        currency: event === isCurrencyValue ? 'CAD' : '',
        value: event === isCurrencyValue ? total : '',
        items: [
          {
            item_name: itemName,
            price: total,
            item_variant: isCurrencyValue ? totalDollar : '',
            item_id: selectedCardId,
            item_category: itemName,
            quantity: 1,
          },
        ],
      },
    },
    auth: process.env.NEXT_PUBLIC_GTM_AUTH_KEY,
    preview: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
  };
  TagManager.dataLayer(tagManagerArgs);
}

export function getRewardTileClickMapping(reward, index, sectionTitle) {
  if (Object.keys(reward).length) {
    return {
      alt_event: 'reward_tile_click',
      eventCategory: 'Reward',
      eventAction: 'Reward Detail Click',
      eventLabel: `${reward?.brandInfo?.fields?.title} | '' | ${reward?.title}`,
      partner: reward?.brandInfo?.fields?.title,
      reward_name: reward?.title,
      points_redeem: '',
      reward_sku: reward?.code,
      reward_type: reward?.pointsConversionLabel,
      category: reward?.category?.fields?.name,
      list_name: sectionTitle,
      tile_position: parseInt(index?.split('-')[1]) + 1,

      items: [
        {
          currency: 'CAD',
          item_id: reward?.code,
          item_name: reward?.title,
          affiliation: reward?.brandInfo?.fields.title,
          index: parseInt(index?.split('-')[1]) + 1,
          item_brand: reward?.brandInfo?.fields.title,
          item_category: 'Rewards',
          item_category2: reward?.category?.fields?.name,
          item_list_name: sectionTitle,
          item_variant: reward?.pointsConversionLabel,
          price: '',
          quantity: 1,
        },
      ],
    };
  }
}

export function getRewardTileViewMapping(reward, index, sectionTitle) {
  if (Object.keys(reward).length) {
    return {
      currency: 'CAD',
      value: 0,
      alt_event: 'reward_tile_click',
      eventCategory: 'Reward',
      eventAction: 'Reward Detail Click',
      eventLabel: `${reward?.brandInfo?.fields?.title} | '' | ${reward?.title}`,
      partner: reward?.brandInfo?.fields?.title,
      reward_name: reward?.title,
      points_redeem: '',
      reward_sku: reward?.code,
      reward_type: reward?.pointsConversionLabel,
      category: reward?.category?.fields?.name,
      list_name: sectionTitle,
      tile_position: parseInt(index?.split('-')[1]) + 1,

      items: [
        {
          item_id: reward?.code,
          item_name: reward?.title,
          affiliation: reward?.brandInfo?.fields.title,
          index: parseInt(index?.split('-')[1]) + 1,
          item_brand: reward?.brandInfo?.fields.title,
          item_category: 'Rewards',
          item_category2: reward?.category?.fields?.name,
          item_list_name: sectionTitle,
          item_variant: reward?.pointsConversionLabel,
          price: '',
          quantity: 1,
        },
      ],
    };
  }
}

export function getRewardTileClickHomeMapping(reward, sectionTitle) {
  if (Object.keys(reward).length) {
    return {
      items: [
        {
          currency: 'CAD',
          value: 'N/A',
          items: 'N/A',
          item_id: reward?.code,
          item_name: reward?.title,
          affiliation: reward?.brand?.title,
          coupon: 'N/A',
          discount: 'N/A',
          index: reward?.code?.split('-')[1],
          item_brand: reward?.brand?.title,
          item_category: 'Rewards',
          item_category2: 'N/A',
          item_list_id: 'N/A',
          item_list_name: sectionTitle,
          item_variant: 'N/A',
          location_id: 'N/A',
          price: 0,
          quantity: 1,
        },
      ],
    };
  }
}

export function getEventTileClickMapping(reward, index, sectionTitle) {
  if (Object.keys(reward).length) {
    return {
      alt_event: 'event_tile_click',
      eventCategory: 'Event',
      eventAction: 'Event Detail Click',
      eventLabel: `${reward?.brand?.title} | ${reward?.title}`,
      partner: reward?.brand?.title,
      reward_name: reward?.title,
      reward_sku: reward?.code,
      reward_type: '',
      list_name: sectionTitle,
      tile_position: parseInt(index?.split('-')[1]) + 1,
      items: [
        {
          item_id: reward?.code,
          item_name: reward?.title,
          affiliation: reward?.brand?.title,
          index: parseInt(index?.split('-')[1]) + 1,
          item_brand: reward?.brand?.title,
          item_category: 'Events',
          item_category2: '',
          item_list_name: sectionTitle,
          item_variant: `${reward?.startDate} | ${reward?.endDate}`,
          quantity: 1,
        },
      ],
    };
  }
}

export function getEventTileViewMapping(reward, index, sectionTitle) {
  if (Object.keys(reward).length) {
    return {
      alt_event: 'event_tile_click',
      eventCategory: 'Event',
      eventAction: 'Event Detail Click',
      eventLabel: `${reward?.brand?.title} | ${reward?.title}`,
      partner: reward?.brand?.title,
      reward_name: reward?.title,
      reward_sku: reward?.code,
      reward_type: '',
      list_name: sectionTitle,
      tile_position: parseInt(index?.split('-')[1]) + 1,
      currency: 'CAD',
      value: 0,

      items: [
        {
          item_id: reward?.code,
          item_name: reward?.title,
          affiliation: reward?.brand?.title,
          index: parseInt(index?.split('-')[1]) + 1,
          item_brand: reward?.brand?.title,
          item_category: 'Events',
          item_category2: '',
          item_list_name: sectionTitle,
          item_variant: `${reward?.startDate} | ${reward?.endDate}`,
          quantity: 1,
        },
      ],
    };
  }
}

export function getEventPageButtonClickMapping(item, index, sectionTitle) {
  return {
    alt_event: 'event_detail_click',
    eventCategory: 'Events',
    eventAction: 'Event Outbound Click',
    eventLabel: `${item?.brand?.title} | ${item?.title}`,
    partner: item?.brand?.title,
    reward_name: item?.title,
    reward_sku: item?.code,
    reward_type: '',
    list_name: sectionTitle,
    tile_position: index + 1,
    value: '0',
    currency: 'CAD',
    items: [
      {
        item_id: item?.code,
        item_name: item?.title,
        affiliation: item?.brand?.title,
        index: index + 1,
        item_brand: item?.brand?.title,
        item_category: 'Events',
        item_category2: '',
        item_list_name: sectionTitle,
        item_variant: `${item?.startDate} | ${item?.endDate}`,
        quantity: 1,
      },
    ],
  };
}

export function getFeatureBannerMapping(alt_event, eventAction, content, bannerType, pageName, id, index, sectionTitle, slot) {
  return {
    alt_event,
    eventCategory: 'Feature Banners',
    eventAction,
    eventLabel: `${content?.fields?.title} | ${bannerType}`,
    partner: '',
    feature_banner_name: content?.fields?.title,
    creative_name: content?.fields?.webImage?.label,
    creative_slot: `Page module ${slot + 1}`,
    location_id: pageName,
    promotion_id: id,
    promotion_name: content?.fields?.heading,
    items: [
      {
        item_id: '',
        item_name: content?.fields?.title,
        affiliation: '',
        coupon: '',
        currency: 'CAD',
        discount: 0,
        index: parseInt(index?.split('-')[1]) + 1,
        item_brand: '',
        item_category: pageName,
        item_category2: '',
        item_list_name: sectionTitle || '',
        item_variant: '',
        price: 0,
        quantity: 1,
      },
    ],
  };
}

export function getSpotlightMapping(content, id, bannersNum, index, bannerType) {
  return {
    items: [
      {
        creative_name: content?.fields?.heading,
        creative_slot: `${bannerType} ${bannersNum}`,
        location_id: content?.fields?.cTALink?.href?.split('/')[1],
        promotion_id: id,
        promotion_name: content?.fields?.heading,
        item_id: id,
        item_name: content?.fields?.heading,
        affiliation: 'sceneplus',
        coupon: 'N/A',
        currency: 'CAD',
        discount: 0,
        index: parseInt(index?.split('-')[1]) + 1,
        item_brand: 'sceneplus',
        item_category: 'Rewards',
        item_category2: content?.fields?.cTALink?.href?.split('/')[2],
        item_list_id: 'N/A',
        item_list_name: content?.fields?.title,
        item_variant: 'N/A',
        price: 'N/A',
        quantity: 1,
      },
    ],
  };
}

// spotlights

export function getSpotlightMappingOffer(event, offer, sectionTitle, index, bannerType) {
  const offerDetails = {
    alt_event: 'spotlight_reward_impression',
    eventCategory: bannerType,
    eventAction: 'Spotlight Impressions',
    eventLabel: `${offer?.cmsContent?.title.trim()} | ${offer?.linkedType}`,
    partner: offer?.cmsContent?.title.trim(),
    spotlight_name: offer?.spotlightCode,
    category: offer?.linkedType,
    items: [
      {
        creative_name: offer?.cmsContent?.webImage?.label,
        creative_slot: offer?.cmsContent?.title.trim(),
        location_id: offer?.cmsContent?.page,
        promotion_id: offer?.spotlightCode,
        promotion_name: offer?.cmsContent?.title.trim(),
        item_id: offer?.spotlightCode,
        item_name: offer?.cmsContent?.title.trim(),
        affliation: offer?.cmsContent?.title.trim(),
        item_category: offer?.linkedType,
        item_category2: offer?.linkedCategoryCode.toString() || '',
        item_list_name: sectionTitle,
        index: parseInt(index?.split('-')[1]) + 1 || 1,
        quantity: 1,
      },
    ],
  };

  if (offer?.linkedType) {
    handleGaEcommImpression(event, offerDetails);
  }
}
export function getSpotlightDetailsMappingOffer(event, offer, sectionTitle, index) {
  const offerDetails = {
    alt_event: 'spotlight_reward_click',
    eventCategory: 'Spotlights',
    eventAction: 'Spotlight Click',
    eventLabel: `${offer?.cmsContent?.title.trim()} | ${offer?.linkedType}`,
    partner: offer?.cmsContent?.title.trim(),
    spotlight_name: offer?.spotlightCode,
    category: offer?.linkedType,

    items: [
      {
        creative_name: offer?.cmsContent?.webImage?.label,
        creative_slot: offer?.cmsContent?.title.trim(),
        location_id: offer?.cmsContent?.page,
        promotion_id: offer?.spotlightCode,
        promotion_name: offer?.cmsContent?.title.trim(),
        item_id: offer?.spotlightCode,
        item_name: offer?.cmsContent?.title.trim(),
        affliation: offer?.cmsContent?.title.trim(),
        item_category: offer?.linkedType,
        item_category2: offer?.linkedCategoryCode.toString() || '',
        item_list_name: sectionTitle,
        index: parseInt(index?.split('-')[1]) + 1 || 1,
        quantity: 1,
      },
    ],
  };

  handleGaEcommImpression(event, offerDetails);
}

export function getSpotlightStandaloneMappingOffer(event, offer, sectionTitle, index) {
  const offerDetails = {
    alt_event: 'spotlight_reward_click',
    eventCategory: 'Spotlights',
    eventAction: 'Spotlight Click',
    eventLabel: `${offer?.cmsContent?.title.trim()} | Standalone`,
    partner: offer?.cmsContent?.title.trim(),
    spotlight_name: offer?.spotlightCode,
    category: 'Standalone',

    items: [
      {
        creative_name: offer?.cmsContent?.webImage?.label,
        creative_slot: offer?.cmsContent?.title.trim(),
        location_id: offer?.cmsContent?.page,
        promotion_id: offer?.spotlightCode,
        promotion_name: offer?.cmsContent?.title.trim(),
        item_id: offer?.spotlightCode,
        item_name: offer?.cmsContent?.title.trim(),
        affliation: offer?.cmsContent?.title.trim(),
        item_category: 'Standalone',
        item_category2: '',
        item_list_name: sectionTitle,
        index: parseInt(index?.split('-')[1]) + 1 || 1,
        quantity: 1,
      },
    ],
  };

  handleGaEcommImpression(event, offerDetails);
}
export function getGiftCardOrderMapping(
  total,
  redeem,
  itemVariant,
  orderNumber,
  alt_event = '',
  event_action = '',
  selectedGiftCardIndex,
  amount
) {
  const obj = {
    currency: 'CAD',
    value: total,
    shipping: 0,
    tax: 0,

    alt_event,
    eventCategory: 'Gift Cards',
    eventAction: event_action,
    eventLabel: `${redeem?.giftCardDetails.brandName} GC | ${total} | ${redeem?.selectedFormat}`,
    reward_name: redeem?.giftCardDetails.brandName,
    partner: redeem?.giftCardDetails.brandName,
    reward_sku: redeem?.giftCardDetails?.digital[0]?.externalSku,
    points_redeem: total,
    reward_type: amount,
    giftcard_type: redeem?.selectedFormat,

    items: [
      {
        affiliation: redeem?.giftCardDetails?.brandName,
        item_id: redeem?.giftCardDetails?.brandId,
        item_name: `${redeem?.giftCardDetails?.brandName} GC`,
        discount: 0,
        index: selectedGiftCardIndex > 0 ? selectedGiftCardIndex : '',
        item_brand: redeem?.giftCardDetails?.brandName,
        item_category: 'Gift Cards',
        item_list_name: '',
        item_variant: itemVariant,
        price: total,
        quantity: redeem?.quantity,
      },
    ],
  };

  if (orderNumber !== '') {
    obj.transaction_id = orderNumber;
  }

  return obj;
}

export function getBYOTOrderMapping(totalRedeemedPoints, added, dollarValue, itemNumber) {
  return {
    items: [
      {
        currency: 'CAD',
        value: totalRedeemedPoints,
        item_id: itemNumber,
        item_name: 'Points For Travel',
        affiliation: 'N/A',
        coupon: 'N/A',
        discount: 0,
        index: 1,
        item_brand: 'N/A',
        item_category: 'Points For Travel',
        item_list_id: 'N/A',
        item_list_name: 'N/A',
        item_variant: dollarValue,
        location_id: 'N/A',
        price: totalRedeemedPoints,
        quantity: added.length,
      },
    ],
  };
}

export function getP4COrderMapping(totalRedeemedPoints, redeem, dollarValue, itemNumber) {
  return {
    items: [
      {
        value: totalRedeemedPoints,
        currency: 'CAD',
        item_id: itemNumber,
        item_name: 'Points For Credit',
        affiliation: 'N/A',
        coupon: 'N/A',
        discount: 0,
        index: 'N/A',
        item_brand: 'N/A',
        item_category: 'Points For Credit',
        item_category2: 'N/A',
        item_list_id: 'N/A',
        item_list_name: 'N/A',
        item_variant: dollarValue,
        location_id: 'N/A',
        price: totalRedeemedPoints,
        quantity: redeem?.quantity,
      },
    ],
  };
}

export function sendRegisterOptIn(data) {
  if (data?.sms) {
    handleGAEvents('Receive Text Notifications from Scene+', 'Registration Events', 'SMS Opt In Click', 'sign_up_text_opt_in');
  }
}
export function handleCreditRedeemEvent(title, page, action, eventType, id, contentType) {
  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GA_TAGMANAGER,
    dataLayer: {
      event: eventType,
      category: page,
      content_type: contentType,
      action,
      label: title,
      item_id: id,
    },
    auth: process.env.NEXT_PUBLIC_GTM_AUTH_KEY,
    preview: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
  };
  TagManager.dataLayer(tagManagerArgs);
}

export function handleGaEcommGCViewItemMapping(event, item, index, quantity) {
  if (item) {
    return {
      alt_event: event,
      eventCategory: 'Gift Cards',
      eventAction: 'Gift Card Detail View',
      eventLabel: `${item?.brandName} GC`,
      partner: item?.brandName,
      reward_name: `${item?.brandName} GC`,
      reward_sku: item?.brandId || '',
      category: 'Gift Cards',
      items: [
        {
          item_list_name: '',
          item_id: item?.brandId,
          item_name: item?.brandName,
          affiliation: item?.brandName,
          currency: 'CAD',
          discount: 0,
          index: index || '',
          item_brand: item?.brandName,
          item_category: 'Gift Cards',
          quantity,
        },
      ],
    };
  }
}

function handleGaViewItemList(options) {
  switch (options.type) {
    case 'giftcards':
      handleGaEcommImpressionList(options.eventName, options.list, options.alt_event, options.eventAction, options.itemListName);
      break;
    case 'offers':
      handleGaEcommOfferTileList(options.eventName, options.list, options.itemListName, options.item_category2, options.province);
      break;
    case 'rewards':
      break;
    default:
      break;
  }
}
export function handleGaListByType(
  event,
  list = [],
  alt_event = '',
  eventAction = '',
  itemListName = '',
  item_category2 = '',
  type = '',
  province = '',
  batchSize = 10
) {
  if (list?.length) {
    if (list.length > batchSize) {
      const listWithPosition = list.map((item, idx) => ({ ...item, position: idx + 1 }));
      const batchedList = chunkArr(listWithPosition, batchSize);

      batchedList.forEach((batch) => {
        handleGaViewItemList({
          eventName: event,
          list: batch,
          alt_event,
          eventAction,
          itemListName,
          item_category2,
          type,
          province,
        });
      });
    } else {
      handleGaViewItemList({
        eventName: event,
        list,
        alt_event,
        eventAction,
        itemListName,
        item_category2,
        type,
        province,
      });
    }
  }
}
