import { useMsal } from '@azure/msal-react';
import { useStateMachine } from 'little-state-machine';
import { b2cSignIn } from '../../../b2c/b2cFunctions';
import RegisterGKButton from '../../common/Button/RegisterGKButton';

import { FormFooterContainer, FormFooterInner } from '../FormStyles';
import ButtonSimple from '../../common/Button/ButtonSimple';
import updateStepAction from '../../../store/actions/updateStepAction';

// Helpers
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';

export default function FormFooter({ backButton, buttonText, buttonLoadingText, linkText, submitting, languageCode }) {
  const { instance } = useMsal();
  const { actions, state } = useStateMachine({ updateStepAction });

  const handleClick = () => {
    let stepValue = state.user.step;
    if (state.user.isGhostKitActivationEnabled) {
      if (stepValue > 1 && stepValue <= 3) {
        actions.updateStepAction({ step: --stepValue });
      }
    } else if (stepValue > 1 && stepValue <= 3) {
      if (stepValue === 3) {
        --stepValue;
      }
      actions.updateStepAction({ step: --stepValue });
    }
  };

  const handleSignInClick = () => {
    const dataLayer = {
      event: 'sign_in_from_registration_flow',
      category: 'Registration Events',
      action: 'Sign In Click',
      label: 'Sign In From Step 1',
      sign_up_step: 'Step 1',
    };
    handleGAEventsBase(dataLayer);
    b2cSignIn(instance, languageCode);
  };

  return (
    <FormFooterContainer>
      <FormFooterInner className="w-full flex justify-center self-center px-4 py-6 sm:px-14 justify-between">
        <div className="self-center">
          {state.user.step > 1 && (
            <ButtonSimple type="button" onClick={handleClick} role="button" aria-label={backButton}>
              {linkText}
            </ButtonSimple>
          )}
          {state.user.step === 1 && (
            <ButtonSimple type="button" onClick={handleSignInClick} role="button" aria-label={linkText}>
              {linkText}
            </ButtonSimple>
          )}
        </div>
        <div>
          <RegisterGKButton
            type="submit"
            className="register-primary-button"
            buttonLoadingText={buttonLoadingText}
            submitting={submitting}
            role="button"
            aria-label={buttonText}
          >
            {buttonText}
          </RegisterGKButton>
        </div>
      </FormFooterInner>
    </FormFooterContainer>
  );
}
