import axios from 'axios';

const BFF_BASE_URL = process.env.NEXT_PUBLIC_BFF_URL;
const isDebugMode = `${process.env.NEXT_PUBLIC_DEBUG_MODE}` === 'true';
const disableProvincialSegmentation = process.env.NEXT_PUBLIC_DISABLE_PROVINCIAL_SEGMENTATION === 'false';
if (isDebugMode) {
  console.info(`Making call to BFF --> ${BFF_BASE_URL}`);
}

// ------ VALIDATE EMAIL
export const validateEmail = async (data, options = {}) => {
  const url = `${BFF_BASE_URL}/customer/validate/email`;
  const config = {
    method: 'POST',
    headers: {
      correlationId: options?.session || process.env.NEXT_PUBLIC_CORRELATION_ID,
      hostIp: options?.ip,
      recaptchaToken: options?.token,
    },
  };

  return axios.post(url, data, config);
};

// ------ VALIDATE GK ACTIVATION CODE
export const validateGKActivation = async (data, options = {}) => {
  const url = `${BFF_BASE_URL}/ghostkits/enrollments/verify`;
  const config = {
    method: 'POST',
    headers: {
      correlationId: options?.session || process.env.NEXT_PUBLIC_CORRELATION_ID,
    },
  };

  return axios.post(url, data, config);
};

// ------ CREATE GK CUSTOMER
export const newCustomerGKEnrollment = async (data, options = {}) => {
  const url = `${BFF_BASE_URL}/ghostkits/enrollments`;
  const config = {
    method: 'POST',
    headers: {
      correlationId: options?.session || process.env.NEXT_PUBLIC_CORRELATION_ID,
    },
  };

  return axios.post(url, data, config);
};

// ------ CREATE CUSTOMER
export const newCustomer = async (data, options = {}) => {
  const url = `${BFF_BASE_URL}/v2/customer`;

  const config = {
    method: 'POST',
    headers: {
      correlationId: options?.session || process.env.NEXT_PUBLIC_CORRELATION_ID,
    },
  };

  return axios.post(url, data, config);
};

// ------ GET CUSTOMER
export const getCustomer = async (accessToken) => {
  const url = `${BFF_BASE_URL}/customer`;
  const config = {
    method: 'GET',
    headers: {
      Authorization: `bearer ${accessToken}`,
    },
  };

  const data = await axios.get(url, config);

  return data;
};

// ------ GET CUSTOMER POINTS
export const getCustomerPoints = async (accessToken) => {
  const url = `${BFF_BASE_URL}/customer/portfolio-balance`;
  const config = {
    method: 'GET',
    headers: {
      Authorization: `bearer ${accessToken}`,
    },
  };

  const data = await axios.get(url, config);

  return data;
};

// ------ GET CUSTOMER ADDRESSES
export const getCustomerAddresses = async (accessToken) => {
  const url = `${BFF_BASE_URL}/customer/addresses`;
  const config = {
    method: 'GET',
    headers: {
      Authorization: `bearer ${accessToken}`,
    },
  };

  const data = await axios.get(url, config);

  return data;
};

// ------ UPDATE CUSTOMER
export const updateCustomer = async (data, accessToken) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/customer`;
    const config = {
      method: 'PUT',
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    };
    return axios.put(requestUrl, data, config);
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
  }
};

// ------ SET PRIMARY ADDRESS
export const setPrimary = async (id, accessToken) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/customer/addresses/${id}/setprimary`;
    const config = {
      method: 'PUT',
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    };

    const res = await axios.put(requestUrl, {}, config);

    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
  }
};

// ------ ADD ADDRESS
export const createAddress = async (data, accessToken) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/customer/addresses`;
    const config = {
      method: 'POST',
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    };
    let res = await axios.post(requestUrl, data, config);

    if (res.success && data.primary) {
      const id = res.data.data;
      res = await setPrimary(id, accessToken);
    }

    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
  }
};

// ------ UPDATE ADDRESS
export const updateAddress = async (data, accessToken) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/customer/addresses`;
    const config = {
      method: 'PUT',
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    };

    const id = data.uniqueId;
    let res = await axios.put(requestUrl, data, config);
    if (res.success && data.primary) {
      res = await setPrimary(id, accessToken);
    }

    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
  }
};

// ------ DELETE ADDRESS
export const deleteAddress = async (id, accessToken) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/customer/addresses/${id}`;
    const config = {
      method: 'DELETE',
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    };
    const res = await axios.delete(requestUrl, config);

    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
  }
};

// ------ GET GENDERS
export const getGenders = async (languageCode) => {
  const url = `${BFF_BASE_URL}/lookup/${languageCode}/genders`;
  const res = await axios.get(url);
  return res;
};

// ------ GET PROVINCES
export const getProvinces = async (languageCode) => {
  const url = `${BFF_BASE_URL}/lookup/${languageCode}/provinces`;
  const res = await axios.get(url);
  return res;
};

// ------ GET REWARDS LIST
export const getRewards = async (data, languageCode, accessToken, province) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/rewards/${languageCode}/list`;
    let config;
    if (disableProvincialSegmentation) {
      config = {
        method: 'POST',
        headers: {
          Authorization: `bearer ${accessToken}`,
          province,
        },
      };
    } else {
      config = {
        method: 'POST',
        headers: {
          Authorization: `bearer ${accessToken}`,
        },
      };
    }
    const res = await axios.post(requestUrl, data, config);

    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
  }
};

// ------ GET GIFT CARDS LIST ( FILTERED + PAGINATED )
export const getGiftCards = async (data, languageCode) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/giftcards/${languageCode}`;
    const config = {
      method: 'POST',
    };

    const res = await axios.post(requestUrl, data, config);
    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
  }
};

// ------ GET GIFT CARDS DETAILS
export const getGiftCardDetails = async ({ languageCode, brandId }) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/giftcards/${languageCode}/${brandId}`;
    const config = {
      method: 'GET',
    };

    const res = await axios.get(requestUrl, config);
    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
  }
};

// ------ GET BYOT TRANSACTIONS
export const getBYOTTransactions = async (accessToken, accountuniqueId, fromDate, toDate) => {
  const url = `${BFF_BASE_URL}/transaction/byot`;
  const config = {
    headers: {
      Authorization: `bearer ${accessToken}`,
    },
    params: {
      accountuniqueId,
      fromDate,
      toDate,
      limit: 100,
    },
  };

  const res = await axios.get(url, config);
  return res;
};

// ------ GET ACCOUNTS
export const getAccounts = async (accessToken) => {
  const url = `${BFF_BASE_URL}/customer/accounts`;
  const config = {
    headers: {
      Authorization: `bearer ${accessToken}`,
    },
  };

  const res = await axios.get(url, config);

  return res;
};

// GET AWARD BY CODE AND TYPE
export const getAward = async (code, awardTypeCode) => {
  const url = `${BFF_BASE_URL}/lookup/awards`;

  const config = {
    params: {
      accountSegmentCode: code,
      awardTypeCode,
    },
  };

  const res = await axios.get(url, config);
  return res;
};

// ------ GET ORDER HISTORY
export const getOrders = async (accessToken, fromDate, toDate, pageParam, limit) => {
  const url = `${BFF_BASE_URL}/transaction?page=${pageParam}${limit ? `&limit=${limit}` : ''}`;
  const config = {
    headers: {
      Authorization: `bearer ${accessToken}`,
    },
    params: {
      fromDate,
      toDate,
    },
  };

  const res = await axios.get(url, config);
  return res;
};

// ------ GET POINT HISTORY
export const getPointHistory = async (accessToken, type, category, cardType, fromDate, toDate, pageParam, limit) => {
  const url = `${BFF_BASE_URL}/customer/points/history`;
  const config = {
    method: 'POST',
    headers: {
      Authorization: `bearer ${accessToken}`,
    },
  };

  const data = {
    Types: type,
    Categories: category,
    Cards: cardType,
    FromDate: fromDate,
    ToDate: toDate,
    Page: pageParam,
    Limit: limit,
    Sort: 'DESC',
  };

  return axios.post(url, data, config);
};

// ------ GET OFFERS LIST ( FILTERED + PAGINATED + SORTING )
export const getOffers = async (data, languageCode, accessToken, province) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/customer/offers/${languageCode}`;
    let config;
    if (disableProvincialSegmentation) {
      config = {
        method: 'POST',
        headers: {
          Authorization: `bearer ${accessToken}`,
          province,
        },
      };
    } else {
      config = {
        method: 'POST',
        headers: {
          Authorization: `bearer ${accessToken}`,
        },
      };
    }

    const res = await axios.post(requestUrl, data, config);
    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
  }
};

// GET OFFERS DETAILS AUTH
export const getOfferDetails = async (id, languageCode, accessToken) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/customer/offers/${languageCode}/${id}`;

    const config = {
      method: 'GET',
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    };

    const res = await axios.get(requestUrl, config);
    return res;
  } catch (error) {
    console.error('We have an error in this service', error);
  }
};

// GET OFFER DETAILS PUBLIC
export const getOfferDetailsPublic = async (id, languageCode, encUniqId) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/customer/offers/${languageCode}/${id}/PUBLIC`;

    const config = {
      method: 'GET',
      headers: {
        'enc-unique-id': encUniqId,
      },
    };

    const res = await axios.get(requestUrl, config);
    return res;
  } catch (error) {
    console.error('We have an error in this service', error);
  }
};

// ------ POST OFFERS-OPTIN
export const loadOffers = async (data, accessToken) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/customer/offers/optin`;
    const config = {
      method: 'POST',
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    };

    const res = await axios.post(requestUrl, data, config);
    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
  }
};

// ------ SAVE PFC ORDER
export const savePFC = async (accessToken, headers = {}, data) => {
  const url = `${BFF_BASE_URL}/transaction/pfc`;

  const config = {
    method: 'POST',
    headers: {
      Authorization: `bearer ${accessToken}`,
      ...headers,
    },
  };

  return axios.post(url, data, config);
};

// ------ SAVE BYOT ORDER
export const saveBYOT = async (accessToken, headers = {}, data) => {
  const url = `${BFF_BASE_URL}/transaction/byot`;

  const config = {
    method: 'POST',
    headers: {
      Authorization: `bearer ${accessToken}`,
      ...headers,
    },
  };

  return axios.post(url, data, config);
};

// ------ SAVE GIFT CARD ORDER
export const saveGiftCardOrder = async ({ accessToken, headers = {}, data }) => {
  const url = `${BFF_BASE_URL}/transaction/giftcard`;

  const config = {
    method: 'POST',
    headers: {
      Authorization: `bearer ${accessToken}`,
      ...headers,
    },
  };

  return axios.post(url, data, config);
};

// ------ GET PREFERENCES
export const getPreferences = async (accessToken) => {
  const url = `${BFF_BASE_URL}/customer/preferences`;

  const config = {
    headers: {
      Authorization: `bearer ${accessToken}`,
    },
  };

  return axios.get(url, config);
};

// ------ UPDATE PREFERENCES
export const savePreferences = async (accessToken, data) => {
  const url = `${BFF_BASE_URL}/customer/preferences/update`;

  const config = {
    method: 'POST',
    headers: {
      Authorization: `bearer ${accessToken}`,
    },
  };

  return axios.post(url, data, config);
};

// ------ GET SAVED PREFERENCES (PUBLIC)
export const getSavedPreferencesPublic = async (customerEmailId) => {
  const url = `${BFF_BASE_URL}/customer/preferences/public`;

  const config = {
    headers: {
      'enc-unique-id': customerEmailId,
    },
  };

  return axios.get(url, config);
};

// ------ UPDATE PREFERENCES (PUBLIC)
export const savePreferencesPublic = async (data, customerEmailId) => {
  const url = `${BFF_BASE_URL}/customer/preferences/update/public`;

  const config = {
    method: 'POST',
    headers: {
      'enc-unique-id': customerEmailId,
    },
  };
  return axios.post(url, data, config);
};

// ------ GET EVENTS LIST( FILTERED + PAGINATED + SORTING )
export const getEvents = async (data, languageCode, accessToken, province) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/events/${languageCode}`;
    let config;
    if (disableProvincialSegmentation) {
      config = {
        method: 'POST',
        headers: {
          Authorization: `bearer ${accessToken}`,
          province,
        },
      };
    } else {
      config = {
        method: 'POST',
        headers: {
          Authorization: `bearer ${accessToken}`,
        },
      };
    }

    const res = await axios.post(requestUrl, data, config);
    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
  }
};

// ------ GET SPOTLIGHTS
export const getSpotlights = async (data, languageCode, accessToken, province) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/spotlights/${languageCode}`;
    let config;
    if (disableProvincialSegmentation) {
      config = {
        method: 'POST',
        headers: {
          Authorization: `bearer ${accessToken}`,
          province,
        },
      };
    } else {
      config = {
        method: 'POST',
        headers: {
          Authorization: `bearer ${accessToken}`,
        },
      };
    }

    const res = await axios.post(requestUrl, data, config);

    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
  }
};

// ------ GET ANNOUNCEMENTS
export const getAnnouncements = async (data, languageCode) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/announcements/${languageCode}`;
    const config = {
      method: 'POST',
    };

    const res = await axios.post(requestUrl, data, config);

    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
  }
};

// ------ GET ANNOUNCEMENT DETAIL
export const getAnnouncementDetail = async (accessToken, languageCode, code) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/announcements/${languageCode}/${code}`;
    const config = {
      method: 'POST',
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    };

    const res = await axios.post(requestUrl, null, config);

    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
  }
};

// ------ GET Barcode
export const getBarcode = async (accessToken) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/customer/barcode/image`;
    const config = {
      method: 'GET',
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    };

    const res = await axios.get(requestUrl, config);

    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
  }
};

// ------ Email my card
export const emailMyCard = async (data, options = {}) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/customer/card/digital/public`;
    const config = {
      method: 'POST',
      headers: {
        correlationId: options?.session || process.env.NEXT_PUBLIC_CORRELATION_ID,
        hostIp: options?.ip,
        recaptchaToken: options?.token,
      },
    };

    const res = await axios.post(requestUrl, data, config);

    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }

    return e.response.data;
  }
};

// ------ Send digital  card
export const sendDigitalCard = async (accessToken) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/customer/card/digital`;
    const config = {
      method: 'GET',
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    };

    const res = await axios.post(requestUrl, null, config);

    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
  }
};

// ------ Send physical card
export const sendPhysicalCard = async (accessToken) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/customer/card/physical`;
    const config = {
      method: 'POST',
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    };
    const res = await axios.post(requestUrl, null, config);

    return res.data;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
    return e;
  }
};

/**
 * 3RD PARTY SERVICES
 */

// ------ Handle login GA
export const handleLoginGA = async (accessToken) => {
  try {
    const requestUrl = `${BFF_BASE_URL}/googleanalytics/login-ga`;
    const config = {
      method: 'POST',
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    };

    const res = await axios.post(requestUrl, null, config);

    return res;
  } catch (e) {
    if (isDebugMode) {
      console.error('We have an error in this service', e);
    }
    return e.response.data;
  }
};

// Reverse Geocode
export const reverseGoogleGeocode = async (url, accessToken) => {
  try {
    const config = {
      method: 'GET',
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    };

    const res = await axios.get(url, config);

    return res;
  } catch (error) {
    console.error('reverse address service failed');
    // return empty object when call fails for whatever reason
    return {};
  }
};
