import { useEffect } from 'react';
import useId from 'react-use-uuid';
import useStorage from '../navigation/useStorage';

export default function useCorrelationId() {
  const correlationId = useId();
  const { setItem } = useStorage();

  useEffect(() => {
    setItem('session', correlationId);
  }, []);
}
