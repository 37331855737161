/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import Image from 'next/image';
import { renderHTML } from '@agility/nextjs';

import { Introduction, Section1, BoxWrapper, ImageContainer, Box } from './AboutStyles';

export default function About({ fields }) {
  if (fields) {
    return (
      <>
        <Section1>
          <div className="global-content-wrapper">
            <Introduction dangerouslySetInnerHTML={renderHTML(fields.introduction)} />
            <BoxWrapper>
              <ImageContainer className="mobileShow">
                <Image
                  src={fields.appImage1.url}
                  alt=""
                  loading="eager"
                  role="presentation"
                  fill
                  sizes="100vw"
                  style={{
                    objectFit: 'cover',
                  }}
                />
              </ImageContainer>
              <Box className="yellow">
                <h2>{fields.header1}</h2>

                <div dangerouslySetInnerHTML={renderHTML(fields.message1)} />
              </Box>
              <ImageContainer className="mobileHide">
                <Image
                  src={fields.webImage1.url}
                  alt=""
                  loading="eager"
                  role="presentation"
                  fill
                  sizes="100vw"
                  style={{
                    objectFit: 'cover',
                  }}
                />
              </ImageContainer>
            </BoxWrapper>
            <BoxWrapper>
              <ImageContainer className="mobileHide">
                <Image
                  src={fields.webImage2.url}
                  alt=""
                  loading="eager"
                  role="presentation"
                  fill
                  sizes="100vw"
                  style={{
                    objectFit: 'cover',
                  }}
                />
              </ImageContainer>
              <ImageContainer className="mobileShow">
                <Image
                  src={fields.appImage2.url}
                  alt=""
                  loading="eager"
                  role="presentation"
                  fill
                  sizes="100vw"
                  style={{
                    objectFit: 'cover',
                  }}
                />
              </ImageContainer>
              <Box className="purple">
                <h2>{fields.header2}</h2>

                <div dangerouslySetInnerHTML={renderHTML(fields.message2)} />
              </Box>
            </BoxWrapper>
          </div>
        </Section1>
      </>
    );
  }
  return null;
}

About.propTypes = { fields: PropTypes.object };
