/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import Link from 'next/link';
import Image from 'next/image';

import SignInHeaderLink from '../../../../signin/SignInHeaderLink';
import LocaleSwitcher from '../../../LocaleSwitcher';
import ButtonIcon from '../../../Button/ButtonIcon';

import { Section, Divider, Icon } from '../HeaderStyles';
import HeaderWelcomeMessage from './HeaderWelcomeMessage';
// Hooks
import useInlineMessage from '../../../../hooks/utils/useInlineMessage';

import { enableScrolling } from '../../../../../helpers/behavourHelper';

export default function MobileFlyoutMenu({
  header,
  isLoggedIn,
  data,
  setOpenHumburger,
  openHumburger,
  openLanguageMobile,
  setOpenLanguageMobile,
  languageCode,
  refLanguageMobile,
  locationIcon,
  location,
  toggleProvincialPopup,
  isShowingProvincialPopup,
}) {
  const {
    englishAbbreviatedLabel,
    frenchAbbreviatedLabel,
    englishLabel,
    frenchLabel,
    ariaLabelSelectedLanguage,
    welcomeLabel,
    mainLinks,
    profileLinks,
    moreLinkLabel,
    accountLabel,
    signOutLabel,
    signInIcon,
    anonymousMainLinks,
    signInLabel,
    loadingLabel,
    registerLink,
    anonymousMoreLinks,
    moreLinks,
  } = header;
  const hasInlineMessage = useInlineMessage();
  const handleMenuClose = () => {
    setOpenHumburger(!openHumburger);
    enableScrolling();

    document.body.classList.remove('prevent-mobile-scroll');
  };
  return (
    <div
      id="menuHumburger"
      className={hasInlineMessage ? 'has-inline-message flyout-menu' : 'flyout-menu'}
      style={{ display: openHumburger ? 'flex' : 'none' }}
      aria-expanded={openHumburger}
      aria-hidden={!openHumburger}
    >
      <>
        <button
          onClick={handleMenuClose}
          aria-label="Toggle Menu"
          aria-controls="menuHumburger"
          type="button"
          className="header-link-humburger header-link-humburger-close"
        >
          <Icon>
            <Image
              src="/assets/menu-humburger-close.svg"
              alt="Close Menu"
              height={30}
              width={30}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Icon>
        </button>
      </>

      {isLoggedIn && (
        <>
          <HeaderWelcomeMessage
            data={data}
            welcomeLabel={welcomeLabel}
            locationIcon={locationIcon}
            location={location}
            toggleProvincialPopup={toggleProvincialPopup}
            isShowingProvincialPopup={isShowingProvincialPopup}
          />

          {mainLinks &&
            mainLinks.map((navitem, index) => (
              <Link
                href={navitem.fields.page.href}
                key={index}
                locale={languageCode}
                onClick={() => {
                  setOpenHumburger(!openHumburger);
                  enableScrolling();

                  document.body.classList.remove('prevent-mobile-scroll');
                }}
              >
                {navitem.fields.title}
              </Link>
            ))}

          <Divider role="separator" />

          <p>
            <strong>
              <small>{moreLinkLabel}</small>
            </strong>
          </p>
          {moreLinks &&
            moreLinks.map((navitem, index) => (
              <Link
                href={navitem.fields.page.href}
                key={index}
                locale={languageCode}
                onClick={() => {
                  setOpenHumburger(!openHumburger);
                  enableScrolling();

                  document.body.classList.remove('prevent-mobile-scroll');
                }}
              >
                {navitem.fields.title}
              </Link>
            ))}

          <Divider role="separator" />
          <p>
            <strong>
              <small>{accountLabel}</small>
            </strong>
          </p>
          {profileLinks &&
            profileLinks.map((navitem, index) => (
              <Link
                href={navitem.fields.page.href}
                key={index}
                onClick={() => {
                  setOpenHumburger(!openHumburger);
                  enableScrolling();

                  document.body.classList.remove('prevent-mobile-scroll');
                }}
              >
                {navitem.fields.title}
              </Link>
            ))}
          <Divider role="separator" />

          {isLoggedIn && (
            <SignInHeaderLink
              linkStyle="inline-block"
              languageCode={languageCode}
              signOutLabel={signOutLabel}
              signInIcon={signInIcon.url}
              signInLabel={signInLabel}
              loadingLabel={loadingLabel}
            />
          )}
        </>
      )}

      {!isLoggedIn && (
        <>
          <p className="mb-2">{welcomeLabel}</p>
          <Divider role="separator" />
          <Link
            href={registerLink.href}
            locale={languageCode}
            onClick={() => {
              setOpenHumburger(!openHumburger);
              enableScrolling();

              document.body.classList.remove('prevent-mobile-scroll');
            }}
          >
            {registerLink.text}
          </Link>

          <SignInHeaderLink
            linkStyle="account-avatar"
            languageCode={languageCode}
            signOutLabel={signOutLabel}
            signInIcon={signInIcon.url}
            signInLabel={signInLabel}
            loadingLabel={loadingLabel}
          />

          <Divider role="separator" />

          {anonymousMainLinks &&
            anonymousMainLinks.map((navitem, index) => (
              <Link
                href={navitem.fields.page.href}
                key={index}
                locale={languageCode}
                onClick={() => {
                  setOpenHumburger(!openHumburger);
                  enableScrolling();

                  document.body.classList.remove('prevent-mobile-scroll');
                }}
              >
                {navitem.fields.title}
              </Link>
            ))}
          <Divider role="separator" />
          <p>
            <strong>
              <small>{moreLinkLabel}</small>
            </strong>
          </p>
          {anonymousMoreLinks.map((navitem, index) => (
            <Link
              href={navitem.fields.page.href}
              key={index}
              locale={languageCode}
              onClick={() => {
                setOpenHumburger(!openHumburger);
                enableScrolling();

                document.body.classList.remove('prevent-mobile-scroll');
              }}
            >
              {navitem.fields.title}
            </Link>
          ))}
          <Divider role="separator" />
        </>
      )}

      <Section className="mobile-lang-switch-section" ref={refLanguageMobile}>
        <ButtonIcon
          className="header-link-bold toggle-lang-show"
          onClick={() => {
            setOpenLanguageMobile(!openLanguageMobile);
          }}
          onFocus={() => openLanguageMobile && setOpenLanguageMobile(!openLanguageMobile)}
          type="button"
          ariaHaspopup="true"
          ariaControls="menuLanguageM"
          ariaLabel={ariaLabelSelectedLanguage}
          id="mobile-language-picker-description"
        >
          {languageCode === 'en-ca' ? englishAbbreviatedLabel : frenchAbbreviatedLabel}{' '}
          <span className="ml-2">
            <Image
              src={`${openLanguageMobile ? '/assets/chevron-up-white.svg' : '/assets/chevron-down-white.svg'}`}
              alt=""
              height={15}
              width={15}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </span>
        </ButtonIcon>

        {openLanguageMobile && (
          // eslint-disable-next-line jsx-a11y/role-supports-aria-props
          <div
            id="menuLanguageM"
            className="relative flyout-menu flyout-menu-mobile"
            role="radiogroup"
            aria-describedby="mobile-language-picker-description"
            aria-hidden={!openLanguageMobile}
            aria-expanded={openLanguageMobile}
          >
            <LocaleSwitcher
              locale={languageCode}
              setOpenLanguage={setOpenLanguageMobile}
              englishAbbreviatedLabel={englishAbbreviatedLabel}
              frenchAbbreviatedLabel={frenchAbbreviatedLabel}
              englishLabel={englishLabel}
              frenchLabel={frenchLabel}
            />
          </div>
        )}
      </Section>
    </div>
  );
}
