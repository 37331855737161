import PropTypes from 'prop-types';
import Image from 'next/image';
import { useStateMachine } from 'little-state-machine';
import { DividerVertical, Icon, Section } from '../HeaderStyles';
import ButtonIcon from '../../../Button/ButtonIcon';
import Loader from '../../../Loader/Loader';
import { handleGAEventsBase } from '../../../../../helpers/handleGoogleAnalyticsHelper';

export default function ProvincialSegment({ locationIcon, tabletHide, toggleProvincialPopup, isShowingProvincialPopup, id }) {
  const {
    state: { location },
  } = useStateMachine();
  // account_location_click
  const handleClick = () => {
    toggleProvincialPopup();
    const dataLayer = {
      event: 'account_location_click',
      eventCategory: 'Account',
      eventAction: 'Account Location Click',
      eventLabel: 'Location',
    };

    handleGAEventsBase(dataLayer);
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick();
    }
  };
  return (
    <>
      <Section className={tabletHide ? 'tabletHide' : ''}>
        <ButtonIcon
          id={id}
          className="header-link-map"
          onKeyDown={handleKeyDown}
          type="button"
          ariaHaspopup="true"
          ariaControls="provincialPopup"
          ariaLabel={location?.province}
          onClick={handleClick}
          ariaExpanded={isShowingProvincialPopup}
        >
          <Icon className="map">
            <Image
              src={locationIcon?.url}
              alt=""
              width={24}
              height={24}
              role="presentation"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Icon>
          {location?.province ? location.province : <Loader width="auto" reverse hideText />}
        </ButtonIcon>
      </Section>
      <DividerVertical role="separator" className="tabletHide">
        |
      </DividerVertical>
    </>
  );
}

ProvincialSegment.propTypes = {
  locationIcon: PropTypes.object,
  tabletHide: PropTypes.bool,
  toggleProvincialPopup: PropTypes.func,
  isShowingProvincialPopup: PropTypes.bool,
  id: PropTypes.string,
};
