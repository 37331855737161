/* eslint-disable react/no-danger */
import { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { renderHTML } from '@agility/nextjs';
import Image from 'next/image';
import { useStateMachine } from 'little-state-machine';
import TooltipIcon from './TooltipIcon';

import { TooltipStyles } from './ToolTipStyles';

export default function Tooltip({ dataForId, dataForIdTip, title, message, placement, isHTML, iconSmall = false }) {
  const tooltip = useRef();
  const { state } = useStateMachine();
  const hideTooltip = () => {
    tooltip.current.tooltipRef = null;
  };

  return (
    <TooltipStyles>
      <div className={`input-header ${placement || ''}`}>
        {placement === 'inputLabel' ? (
          <label id={`form-${dataForId}`} htmlFor={dataForId} className="inline-block ml-0 mr-0 my-0 font-normal">
            {title}
          </label>
        ) : (
          title
        )}

        <TooltipIcon dataForId={dataForIdTip || dataForId} title={title} iconSmall={iconSmall} />
        <ReactTooltip
          role="tooltip"
          className="tooltip-icon"
          id={dataForIdTip || dataForId}
          type="light"
          effect="solid"
          globalEventOff="click"
          clickable
          ref={tooltip}
          overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
            const d = document.documentElement;

            return {
              left: Math.max(0, Math.min(d.clientWidth - node.clientWidth, left)),
              top: Math.max(0, Math.min(d.clientHeight - node.clientHeight, top)),
            };
          }}
        >
          <>
            {!isHTML && <div className="tooltip-message">{message}</div>}
            {isHTML && <div dangerouslySetInnerHTML={renderHTML(message)} className="tooltip-message" />}
            <button type="button" className="close-tooltip" onClick={hideTooltip} aria-label={state.labels?.closeLabel}>
              <Image
                src="/assets/close-x.svg"
                alt={state.labels?.closeLabel}
                title={state.labels?.closeLabel}
                width={18}
                height={18}
                data-testid="close-icon"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </button>
          </>
        </ReactTooltip>
      </div>
    </TooltipStyles>
  );
}

Tooltip.propTypes = {
  dataForId: PropTypes.string,
  dataForIdTip: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  placement: PropTypes.string,
  isHTML: PropTypes.bool,
  iconSmall: PropTypes.bool,
};
