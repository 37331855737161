import { useEffect } from 'react';

import { useStateMachine } from 'little-state-machine';
import { firstDate, secondDate } from '../../../helpers/dateHelpers';
import { updateTransactions, updateOrder } from '../../../store/actions';

export default function usePeriod(dateRange, resource) {
  // Global State
  const {
    actions,
    state: { orders },
  } = useStateMachine({ updateTransactions, updateOrder });

  useEffect(() => {
    // Setup the selected card based on the customer data

    let selectedPeriod;

    if (dateRange) {
      selectedPeriod = {
        value: dateRange[0].code,
        label: dateRange[0].name,
        firstDate: firstDate(dateRange[0].code),
        secondDate: secondDate(dateRange[0].code),
      };
    }

    const fnUpdateResource = (param) => {
      switch (param) {
        default:
          return null;
        case 'orders':
          actions.updateOrder({
            orders,
            selectedOrder: orders ? orders[0] : {},
            periods: dateRange,
            selectedPeriod,
          });
          break;
        case 'transactions':
          actions.updateTransactions({
            periods: dateRange,
            selectedPeriod,
          });
          break;
      }
    };

    fnUpdateResource(resource);
  }, [dateRange]);
}
