import { useEffect } from 'react';
import Link from 'next/link';

// Styles
import styled from 'styled-components';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateGiftCards, updateGiftCardsRedeem } from '../../store/actions';

// Hooks
import useGiftCards from '../hooks/giftCards/useGiftCards';
import useGiftCardsCategories from '../hooks/giftCards/useGiftCardsCategories';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import GiftCardList from '../GiftCards/GiftCardList';

// helpers
import { generateCategoriesFrom, generateFilteredListFrom } from '../../helpers/giftCardsHelper';

const GiftcardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.2rem;

  a {
    font-weight: bold;
    text-decoration: underline;
    font-size: ${(props) => props.theme.fontLegal};

    @media screen and (min-width: 768px) {
      font-size: ${(props) => props.theme.fontBody3};
    }
  }
`;

const GiftCards = ({ module, languageCode }) => {
  const { actions, state } = useStateMachine({ updateGiftCards, updateGiftCardsRedeem });

  const {
    session: { isLoggedIn },
    giftCards,
  } = state;

  const { fields } = module;
  const pageCategory = fields && fields?.category_TextField;
  const categories = generateCategoriesFrom(fields.category);

  const { giftCards: giftCardsData, isLoading, refetch } = useGiftCards(languageCode);

  const {
    giftCards: giftCardsCategoryData,
    isLoading: gcCategoryIsLoading,
    refetch: categoryRefetch,
  } = useGiftCardsCategories(languageCode, categories);

  const allGiftcards = giftCardsData?.pages?.map((page) => page.giftCards)[0];
  const allGiftcardsCategory = giftCardsCategoryData?.pages?.map((page) => page)[0];

  const deriveGiftCardList = () => {
    if (typeof pageCategory !== 'string') return allGiftcardsCategory;

    if (pageCategory.toLocaleUpperCase() === 'FEATURED') {
      return allGiftcards;
    }

    return allGiftcardsCategory;
  };
  useEffect(() => {
    if ((giftCardsCategoryData || giftCardsData) && (!gcCategoryIsLoading || !isLoading)) {
      actions.updateGiftCards({
        giftCardsList: deriveGiftCardList(),
        isLoading: gcCategoryIsLoading || isLoading,
        selectedGiftCard: {},
        selectedGiftCardIndex: null,
      });
      actions.updateGiftCardsRedeem({
        step: 1,
        giftCardDetails: {},
        selectedFormat: '',
        quantity: 1,
      });

      if (pageCategory !== 'Featured') {
        categoryRefetch();
      } else {
        refetch();
      }
    }
  }, [giftCardsData, giftCardsCategoryData]);

  if (isLoggedIn && fields.category && giftCards && giftCards?.giftCardsList?.length) {
    return (
      <WrapperLayout className="global-content-wrapper" customStyles={`${fields.isSlider ? 'giftcardsSlider' : 'giftcardsGrid'}`}>
        <GiftcardHeader>
          {fields.title && <h2>{fields.title}</h2>}
          {fields.allGiftCardsLink && fields.allGiftCardsLink.href && (
            <Link href={fields.allGiftCardsLink.href} locale={languageCode}>
              {fields.allGiftCardsLink.text}
            </Link>
          )}
        </GiftcardHeader>

        <GiftCardList
          fields={fields}
          languageCode={languageCode}
          filteredList={categories[0] === 'FEATURED' ? generateFilteredListFrom(giftCards.giftCardsList, 'FEATURED') : null}
          itemListName={fields?.title}
        />
      </WrapperLayout>
    );
  }

  // Show nothing if there are no cards
  return null;
};

export default GiftCards;
