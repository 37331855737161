import Image from 'next/image';
import styled from 'styled-components';

const SectionBanner = styled.div`
  position: relative;
  overflow: hidden;
  height: 18rem;
  max-height: 18rem;
  width: 100%;

  @media screen and (max-width: 767px) {
    height: 9.7rem;
    max-height: 9.7rem;
  }
`;

export default function L3HeaderBanner({ bgImage }) {
  if (bgImage) {
    return (
      <SectionBanner className="relative w-full">
        <Image
          src={bgImage}
          quality={50}
          alt=""
          fill
          sizes="100vw"
          style={{
            objectFit: 'cover',
            objectPosition: '50% 50%',
          }}
        />
      </SectionBanner>
    );
  }

  return null;
}
