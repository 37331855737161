import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateOffers } from '../../../store/actions';

// Components
import SortDropdown from '../../common/Sort/SortDropdown';
import LoadAllOffers from '../LoadAllOffers';
import { OfferActionsWrapper, FilterButtonsWrapper } from '../OfferStyles';
import OffersFilter from '../Filter/OffersFilter';

function OfferActions({ customData, loadAllOffers, offerOptions, showFilter, page, disable }) {
  const disableProvincialSegmentation = process.env.NEXT_PUBLIC_DISABLE_PROVINCIAL_SEGMENTATION === 'false';
  const { sortByLabel } = customData?.pageContent || customData;

  const { actions } = useStateMachine({ updateOffers });

  useEffect(
    () => () => {
      actions.updateOffers({ sortBy: {} });
    },
    []
  );

  if (page === 'loadable') {
    return (
      <OfferActionsWrapper className="block sm:flex justify-between flex-row items-end" page={page}>
        {loadAllOffers && <LoadAllOffers customData={customData.pageContent} page={page} />}
        <FilterButtonsWrapper loadAllOffers={loadAllOffers}>
          <SortDropdown name="sort-offers" label={sortByLabel} options={offerOptions} page={page} />
          {showFilter && <OffersFilter customData={customData} page={page} disable={disable} />}
        </FilterButtonsWrapper>
      </OfferActionsWrapper>
    );
  }

  return (
    <OfferActionsWrapper className="flex justify-between flex-row items-end">
      <SortDropdown name="sort-offers" label={sortByLabel} options={offerOptions} />
      {loadAllOffers && <LoadAllOffers customData={customData.pageContent} />}
      {disableProvincialSegmentation && showFilter && <OffersFilter customData={customData} page={page} disable={disable} />}
    </OfferActionsWrapper>
  );
}

OfferActions.propTypes = {
  loadAllOffers: PropTypes.bool,
  showFilter: PropTypes.bool,
  customData: PropTypes.object.isRequired,
  offerOptions: PropTypes.array,
  page: PropTypes.string,
  disable: PropTypes.bool,
};

OfferActions.defaultProps = {
  page: null,
  disable: false,
};

export default OfferActions;
