// Hooks
import useInlineMessage from '../../../hooks/utils/useInlineMessage';
// Styles

const MainContentPadding = () => {
  const hasInlineMessage = useInlineMessage();

  return (
    <>
      <div id="mainContentPadding" className={hasInlineMessage ? 'inline-message-padding' : ''} />
    </>
  );
};

export default MainContentPadding;
