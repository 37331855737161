/* eslint-disable react/no-danger */

import Link from 'next/link';
import PropTypes from 'prop-types';

import { AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';

import AccordionTitle from './AccordionTitle';
import AccordionBody from './AccordionBody';

function AccordionList({ accordionList, page, languageCode }) {
  if (accordionList && accordionList.length) {
    return accordionList.map((item, index) => (
      <section key={index} id={item.fields.anchorLink || item.contentID}>
        <AccordionItem uuid={item.contentID}>
          <AccordionItemHeading>
            <AccordionItemButton>
              <AccordionTitle page={page} title={item.fields.title} subtitle={item.fields.subtitle} languageCode={languageCode} />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <AccordionBody page={page} content={item.fields.content} languageCode={languageCode} />
            {item.fields.pDF && item.fields.pDF.href && (
              <div className="my-10">
                <Link
                  href={item.fields.pDF.href}
                  as={item.fields.pDF.href}
                  className="inline-block primary-button text-center"
                  rel="noopener"
                  target={(item.fields.pDF.target && item.fields.pDF.target) || '_blank'}
                >
                  {(item.fields.pDF.text && item.fields.pDF.text) || 'PDF'}
                </Link>
              </div>
            )}
            {item.fields.legalMessage && (
              <div className="accordion-legal" dangerouslySetInnerHTML={{ __html: item.fields.legalMessage }} />
            )}
          </AccordionItemPanel>
        </AccordionItem>
      </section>
    ));
  }
  return null;
}

AccordionList.propTypes = { accordionList: PropTypes.array, page: PropTypes.string, languageCode: PropTypes.string };

export default AccordionList;
