/**
 * setItem accepts a type, which will determine where we save the values
 * ie: session or local
 *
 * @returns getter, setter, remove item for local or session storage
 */
const useStorage = () => {
  // helper function to generate the dynamic key for window storage fn.
  const storageType = (type) => `${type ?? 'session'}Storage`;

  // make sure we dont run browser code in ssr
  const isBrowser = (() => typeof window !== 'undefined')();

  // Setters/Getters
  const getItem = (key, type) => (isBrowser ? window[storageType(type)][key] : '');

  const setItem = (key, value, type) => {
    if (isBrowser) {
      window[storageType(type)].setItem(key, value);
      return true;
    }

    return false;
  };

  const removeItem = (key, type) => {
    window[storageType(type)].removeItem(key);
  };

  return {
    getItem,
    setItem,
    removeItem,
  };
};

export default useStorage;
