import PropTypes from 'prop-types';
import Image from 'next/image';
import styled from 'styled-components';

const ImageCircle = styled.div`
  background-color: ${(props) => props.theme.uiWhite};
  position: relative;
  border: 1px solid ${(props) => props.theme.uiGrey02};
  overflow: hidden;
  width: 3.75rem;
  height: 3.75rem;
  margin-right: 2rem;
  @media screen and (max-width: 767px) {
    width: 2.625rem;
    height: 2.625rem;
    margin-right: 1.5rem;
  }
`;

export default function TitleWithLogo({ children, logo }) {
  if (children) {
    return (
      <div className="flex items-center py-10">
        {logo && (
          <ImageCircle className="rounded-full">
            <Image
              src={logo}
              alt=""
              quality={25}
              priority
              fill
              sizes="100vw"
              style={{
                objectFit: 'contain',
                objectPosition: 'left',
              }}
            />
          </ImageCircle>
        )}
        <h1>{children}</h1>
      </div>
    );
  }
  return null;
}

TitleWithLogo.propTypes = { children: PropTypes.string, logo: PropTypes.string };
