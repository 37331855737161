import Image from 'next/image';
import Link from 'next/link';

import { handleKeyDown } from '../../../../helpers/utils';
import { handleGAEvents, getRewardBrandDetails } from '../../../../helpers/handleGoogleAnalyticsHelper';

export default function BrandTile({ id, item, sectionTitle, languageCode, isSlider }) {
  const { code, title, imageSrc, link, linkText } = item;

  const width = isSlider ? 115 : 174;
  const height = isSlider ? 84 : 127;
  const handelOnClick = () => {
    handleGAEvents(linkText, 'Reward', 'Shop To Reward Impression Interaction', 'redeem_home_reward_click');
    getRewardBrandDetails('shoptoredeem_select_item', code, title, sectionTitle, id);
  };

  return (
    <li>
      <Link
        href={link || ''}
        locale={languageCode}
        role="link"
        title={linkText || ''}
        aria-label={`${sectionTitle}: ${title}`}
        onClick={handelOnClick}
        onKeyDown={(e) => handleKeyDown(e, handelOnClick)}
        tabIndex="0"
      >
        <Image
          src={imageSrc}
          alt=""
          width={width}
          height={height}
          loading="lazy"
          style={{
            maxWidth: '100%',
            height: 'auto',
            objectFit: 'contain',
            objectPosition: '50% 50%',
          }}
        />
        {title && <span className=" mt-3.5">{title}</span>}
      </Link>
    </li>
  );
}
