import { useEffect, useState } from 'react';
import { expandLinkedList } from '@agility/nextjs/utils';
import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';
import { deriveErrorMessageList } from '../../helpers/agilityHelpers';
import { updateOffers } from '../../store/actions';

// Components
import WrapperLayout from '../layouts/WrapperLayout';
import Loader from '../common/Loader/Loader';
import OffersList from '../Offers/OffersList';
import NoOffers from '../Offers/NoOffers';
import { OfferActions } from '../Offers/Sections';

// Hooks
import useOffersCleanup from '../hooks/offers/useOffersCleanup';
import useOffers from '../hooks/offers/useOffers';
import useOffersSorting from '../hooks/offers/useOffersSorting';
import useSortedOffersList from '../hooks/offers/useSortedOffersList';

// Helpers
import { deriveOffersFrom, deriveReqBodyFrom } from '../../helpers/offersHelpers';
import { handleGaListByType } from '../../helpers/handleGoogleAnalyticsHelper';
import { checkArrayForItems } from '../../helpers/utils';

const LimitedTimeOffersPage = ({ customData, languageCode }) => {
  const { loadOfferLabel, offerLoadedLabel, offersSort } = customData.pageContent || customData;

  const {
    actions,
    state: {
      session: { isLoggedIn },
      offers,
      location,
    },
  } = useStateMachine({ updateOffers });

  const [reqBody, setReqBody] = useState({
    category: ['ALL'],
    tag: ['limitedtime'],
    brand: [],
    sort: '',
    page: 1,
    limit: 50,
  });

  useEffect(() => {
    if (offers?.filter?.category || offers?.filter?.brands) {
      const filterReqBody = deriveReqBodyFrom('limitedtime', '', 1, 50, offers?.filter?.category, offers?.filter?.brands);

      setReqBody(filterReqBody);
    }
  }, [offers?.filter?.category, offers?.filter?.brands]);
  const { offers: offersData, isLoading, error } = useOffers(languageCode, reqBody);
  const { optionsList, offerOptions } = useOffersSorting(offersSort);
  const sortedList = useSortedOffersList(offers.limitedTimeOffersList, offers.sortBy);

  useEffect(() => {
    if (!isLoading && offersData) {
      actions.updateOffers({ ...deriveOffersFrom(offersData), isLoading });
    }
  }, [offersData]);

  useEffect(() => {
    if (!offers.isLoading && sortedList) {
      handleGaListByType(
        'offer_view_item_list',
        sortedList,
        '',
        '',
        'Limited Time Offers',
        'Limited Time Offers',
        'offers',
        location?.province
      );
    }
  }, [sortedList]);

  useOffersCleanup();

  if (isLoggedIn) {
    if (offers.isLoading || !optionsList || !offerOptions || !offers?.sortBy?.value || !customData?.pageContent) {
      return (
        <WrapperLayout className="global-content-wrapper">
          <Loader />
        </WrapperLayout>
      );
    }
    if (checkArrayForItems(sortedList)) {
      return (
        <WrapperLayout className="global-content-wrapper min-half-screen" isGray customStyles="offersGeneralOffers">
          <OfferActions customData={customData} offerOptions={offerOptions} tab="limitedtime" showFilter page="limitedTime" />

          <OffersList
            customData={customData?.pageContent}
            languageCode={languageCode}
            filteredList={sortedList}
            loadOfferLabel={loadOfferLabel}
            offerLoadedLabel={offerLoadedLabel}
            listName="Limited time offers page"
            sectionTitle="Limited time offers page"
          />
        </WrapperLayout>
      );
    }

    if (!offers.isLoading) {
      return (
        <WrapperLayout className="global-content-wrapper min-half-screen" isGray customStyles="offersGeneralOffers">
          <OfferActions customData={customData} offerOptions={offerOptions} tab="limitedtime" showFilter />
          <NoOffers customData={customData} />
        </WrapperLayout>
      );
    }

    return (
      <WrapperLayout className="global-content-wrapper">
        <Loader />
      </WrapperLayout>
    );
  }
  return <div className="flex justify-center">{/* No account for now */}</div>;
};

LimitedTimeOffersPage.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'offerspagecontentv2',
      languageCode,
    });

    // get ErrorMessages...
    const rawErrorMessages = await api.getContentList({
      referenceName: 'errormessages',
      languageCode,
    });

    const errorCodes = ['General', 'message'];

    let contentItem = contentItemList && contentItemList[0];

    if (contentItem.fields.offersCategories) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'offersCategories',
        sortIDField: 'offersCategories_SortIdField',
      });
    }

    if (contentItem.fields.offersSort) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'offersSort',
        sortIDField: 'offersSort_SortIdField',
      });
    }

    if (contentItem.fields.offersTags) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'offersTags',
      });
    }

    if (contentItem.fields.brandCategories) {
      contentItem = await expandLinkedList({
        agility,
        contentItem,
        languageCode,
        fieldName: 'brandCategories',
      });
      contentItem.fields.brandCategories.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);
    }

    return {
      pageContent: contentItem.fields,
      offersCategories:
        contentItem.fields.offersCategories.map((m) => ({
          code: m.fields.code,
          name: m.fields.name,
          provinces: m.fields.provinces_TextField || '',
        })) || null,
      offersSort:
        contentItem.fields.offersSort.map((m) => ({
          code: m.fields.code,
          name: m.fields.name,
        })) || null,
      offersTags:
        contentItem.fields.offersTags.map((m) => ({
          code: m.fields.code,
          name: m.fields.name,
        })) || null,
      errorsList: deriveErrorMessageList(rawErrorMessages, errorCodes),
      brands:
        contentItem.fields.brandCategories.map((m) => ({
          code: m.fields.code,
          name: m.fields.title,
          provinces: m.fields.provinces_TextField || '',
          categories: m.fields.brandCategories_TextField || '',
        })) || null,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

LimitedTimeOffersPage.propTypes = {
  customData: PropTypes.object,
  languageCode: PropTypes.string,
};
export default LimitedTimeOffersPage;
