import PropTypes from 'prop-types';
import Image from 'next/image';

import { PointsItemWrapper, Extra, TransactionIcon } from './PointsHistoryStyles';

import { totalPointsToLocalString } from '../../../helpers/pointsHelpers';
import { formatToCurrency } from '../../../helpers/utils';
import { formatDynamicLabel } from '../../../helpers/stringHelpers';

export default function PointsItem({ customData, transactionIconTypes, accountSegments, awardsTypes, languageCode, ...props }) {
  const {
    ptsLabel,
    sceneBaseEarn,
    sceneBonusEarn,
    sceneRedemption,
    pointsTransfer,
    pointsAdjustment,
    multiplierLabel,
    sceneReversal,
  } = customData;
  const { awardType, card, points, description, multiplier, transactionAmount, iconTypeCode, pointCategory } = props;

  const transactionIcon = transactionIconTypes.filter((icon) => icon.code === iconTypeCode).map((iconImage) => iconImage.image);

  let transactionHeader;
  let transactionLabels;

  switch (pointCategory.toLowerCase()) {
    case 'earn':
      if (card.toLowerCase() === 'scene') {
        transactionLabels = sceneBaseEarn;
      } else {
        transactionLabels = accountSegments.filter((c) => c.code === card).map((cardName) => cardName.name);
      }
      if (description) {
        transactionHeader = description;
      }
      break;
    case 'redeem':
      transactionLabels = sceneRedemption;
      if (awardType) {
        transactionHeader = awardsTypes.filter((header) => header.code === awardType.code).map((headerName) => headerName.name);
      } else if (description) {
        transactionHeader = description;
      }
      break;
    case 'bonus':
      if (description) {
        transactionHeader = description;
      }
      transactionLabels = sceneBonusEarn;
      break;
    case 'adjustment':
      if (description) {
        transactionHeader = description;
      }
      transactionLabels = pointsAdjustment;
      break;
    case 'transfer':
      if (description) {
        transactionHeader = description;
      }
      transactionLabels = pointsTransfer;
      break;

    case 'reverse':
      if (description) {
        transactionHeader = description;
      }
      transactionLabels = sceneReversal;
      break;

    default:
    // code block
  }

  return (
    <PointsItemWrapper>
      <div>
        {transactionHeader && <p className="block font-bold mb-2 point-item-name">{transactionHeader} </p>}

        <div className="flex items-start">
          {transactionIcon && transactionIcon[0].url && (
            <TransactionIcon>
              <Image src={transactionIcon[0].url || `/assets/no-image.png`} alt="" aria-hidden="true" fill sizes="100vw" />
            </TransactionIcon>
          )}

          <p className="sub-text mb-0 point-item-type leading-normal">
            <span className="mr-2">
              {' '}
              {transactionLabels}
              {transactionAmount > 0 ? ` | ${formatToCurrency(transactionAmount, languageCode)} ` : ' '}
            </span>

            {multiplier && multiplier > 1 && <Extra>{formatDynamicLabel(multiplierLabel, '##MULTIPLIER##', multiplier)}</Extra>}
          </p>
        </div>
      </div>

      {points && (
        <div className="item-points">
          <p>
            {totalPointsToLocalString(parseInt(points), languageCode)}
            <span aria-hidden="true"> {ptsLabel} </span>
            <span className="sr-only"> {ptsLabel} </span>
          </p>
        </div>
      )}
    </PointsItemWrapper>
  );
}

PointsItem.propTypes = {
  customData: PropTypes.object.isRequired,
  languageCode: PropTypes.string.isRequired,
  props: PropTypes.array,
  awardType: PropTypes.object,
  transactionAmount: PropTypes.string,
  card: PropTypes.string,
  points: PropTypes.string,
  description: PropTypes.string,
  multiplier: PropTypes.string,
  location: PropTypes.string,
  iconTypeCode: PropTypes.string,
  transactionIconTypes: PropTypes.array,
  accountSegments: PropTypes.array,
  awardsTypes: PropTypes.array,
  partnerStores: PropTypes.array,
  pointCategory: PropTypes.string,
};
