import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { useStateMachine } from 'little-state-machine';
import { useRouter } from 'next/router';
import { updateNavigation, updateOffers } from '../../../store/actions';

import { TabsWrapper, Tabs, Tab, TabSliderWrapper, BottomShadow } from './TabSelectorStyles';

// Helpers
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';
import { camelCaseToSentence } from '../../../helpers/stringHelpers';
import { getQueryParam } from '../../../helpers/navigationHelpers';
import { tabSelectorSliderSettings } from '../../../helpers/bannersHelper';

/**
 * @component
 *
 * @prop {array} tabs array of objects that must have the shape {name: <string>, code:<string>}
 * @prop {function} handleTabClicked callback handler from parent that will control the side effect of using the filter
 *
 */

export default function TabSelector({ tabs, handleTabClicked, isSlider }) {
  const [selected, setSelected] = useState(null);

  const router = useRouter();

  const {
    state: {
      offers: { selectedFilters },
      navigation: { query },
    },
  } = useStateMachine({ updateNavigation, updateOffers });

  useEffect(() => {
    if (selected === null && selectedFilters) {
      setSelected(selectedFilters);
    }
  }, [selected]);

  useEffect(() => {
    if (router && selectedFilters === '') {
      if (query?.category) {
        // eslint-disable-next-line no-use-before-define
        updateFilters(query.category);
      }
    }

    if (window.location.search.includes('category=') && !window.location.search.includes(selected)) {
      const category = getQueryParam('category', window.location.search);
      if (query?.category !== category) {
        // eslint-disable-next-line no-use-before-define
        updateFilters(category);
      }
    }
  }, [router, selectedFilters, selected]);

  function updateFilters(filter) {
    setSelected(filter);
    handleTabClicked(filter);

    try {
      if (filter !== tabs[0]?.code) {
        const dataLayer = {
          event: 'offers_category_click',
          eventCategory: 'Earn',
          eventAction: 'Filter Click',
          eventLabel: camelCaseToSentence(filter),
          category: camelCaseToSentence(filter),
        };

        handleGAEventsBase(dataLayer);
      }
    } catch {
      //
    }
  }

  const handleFilterSelected = (e) => {
    e.preventDefault();
    updateFilters(e.target.id);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      updateFilters(e.target.id);
    }
  };

  if (isSlider) {
    const settings = tabSelectorSliderSettings();

    return (
      <BottomShadow>
        <TabSliderWrapper tabSlider className="global-content-wrapper" role="tablist" aria-label="Offer Tabs">
          <div className="mobile-gradient" />
          <Slider {...settings}>
            {tabs.map((category, index) => (
              <div key={index}>
                <Tab
                  id={category.code}
                  className={`mx-3 my-2 ${index === 0 ? 'first' : ''} ${index === tabs.length - 1 ? 'last' : ''} ${
                    category.code === selected ? 'selected' : ''
                  } tab-${category.code}`}
                  onClick={handleFilterSelected}
                  role="tab"
                  name={category.name}
                  active={category.code === selected}
                  aria-selected={category.code === selected}
                  aria-controls="offers-tabs"
                  tabIndex="0"
                  onKeyDown={handleKeyDown}
                >
                  {category.name}
                </Tab>
              </div>
            ))}
          </Slider>
        </TabSliderWrapper>
      </BottomShadow>
    );
  }

  return (
    <TabsWrapper>
      <Tabs role="tablist" className="global-content-wrapper flex list-none pl-0">
        {tabs.map((category, index) => (
          <Tab
            key={index}
            id={category.code}
            className={`mx-3 my-2 ${category.code === selected ? 'selected' : ''} ${index === tabs.length - 1 ? 'last' : ''} ${
              index === 0 ? 'first' : ''
            } tab-${category.code}`}
            onClick={handleFilterSelected}
            role="tab"
            name={category.name}
            active={category.code === selected}
            tabIndex="0"
            aria-selected={category.code === selected}
            onKeyDown={handleKeyDown}
          >
            {category.name}
          </Tab>
        ))}
      </Tabs>
    </TabsWrapper>
  );
}

TabSelector.propTypes = {
  tabs: PropTypes.array.isRequired,
  handleTabClicked: PropTypes.func.isRequired,
  isSlider: PropTypes.bool,
};

TabSelector.defaultProps = {
  isSlider: false,
};
