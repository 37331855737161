import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useStateMachine } from 'little-state-machine';
import { updateUser } from '../../../store/actions';

import { SectionDetails, SectionTitle, DetailsInfo } from '../../Points/Shop/PointsShopStyles';

// Components
import ShippingAddressSwitcher from '../../forms/profile/ShippingAddressSwitcher/ShippingAddressSwitcher';
import AddNewAddresss from '../../forms/profile/AddAddress/AddNewAddresss';
import Loader from '../../common/Loader/Loader';

import useCustomerAddress from '../../hooks/account/useCustomerAddress';
import useProvinces from '../../hooks/account/useProvinces';

function ConfirmationShippingAddress({
  shippingAddressLabel,
  addNewAddressLink,
  errorsList,
  customData,
  languageCode,
  profileContent,
  ...props
}) {
  const {
    actions,
    state: {
      user: { shippingAddresses, selectedShippingAddress },
      giftCards: {
        redeem: { selectedFormat },
      },
    },
  } = useStateMachine({ updateUser });

  const { isLoading: addressesLoading, customerAddresses, refetch } = useCustomerAddress();
  const { provinces } = useProvinces(languageCode);

  useEffect(() => {
    if (!addressesLoading && customerAddresses) {
      actions.updateUser({ shippingAddresses: customerAddresses });
    }
  }, [addressesLoading, customerAddresses]);

  const [shippingList, setAddressList] = useState(customerAddresses);

  useEffect(() => {
    setAddressList(customerAddresses);
    if (shippingAddresses) {
      refetch();
    }
  }, [customerAddresses, selectedShippingAddress, shippingAddresses]);

  const isLoading = !shippingAddresses && addressesLoading;

  if (selectedFormat !== 'Physical') return null;

  return (
    <SectionDetails>
      {shippingAddressLabel && <SectionTitle>{shippingAddressLabel}</SectionTitle>}
      <DetailsInfo>
        {isLoading && <Loader width="auto" />}
        {!isLoading && (
          <>
            <ShippingAddressSwitcher
              errorsList={errorsList}
              customData={customData}
              setAddressList={setAddressList}
              shippingList={shippingList}
              profileContent={profileContent}
            />

            <AddNewAddresss
              customData={customData}
              {...props}
              isEditing
              addNewAddressLabel={addNewAddressLink}
              setAddressList={setAddressList}
              provinces={provinces}
              errorsList={errorsList}
              languageCode={languageCode}
              profileContent={profileContent}
              {...profileContent}
              coloredLink
            />
          </>
        )}
      </DetailsInfo>
    </SectionDetails>
  );
}

ConfirmationShippingAddress.propTypes = {
  shippingAddressLabel: PropTypes.string.isRequired,
  addNewAddressLink: PropTypes.string.isRequired,
};

export default ConfirmationShippingAddress;
