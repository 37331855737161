import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Link from 'next/link';

// State
import { useStateMachine } from 'little-state-machine';

// Components
import OffersSlider from '../common/Banners/OffersSlider';
import Loader from '../common/Loader/Loader';
import OffersModal from './OffersModal';
import OffersGrid from './OffersGrid';

import { Section, GridWrapper } from '../common/Banners/BannerStyles';
import { SectionTitle } from './OfferStyles';

// Hooks
import useModal from '../hooks/utils/useModal';

// Helpers
import { handleKeyDown } from '../../helpers/utils';
import { handleGAEvents } from '../../helpers/handleGoogleAnalyticsHelper';

function OffersList({ customData, title, isSlider, filteredList, languageCode, isLimitedTimeOffer, listName, sectionTitle }) {
  const { viewAllLabel } = customData;
  const {
    state: {
      offers: { offersList, isLoading },
    },
  } = useStateMachine();

  const [list, setList] = useState([]);
  const offersNum = filteredList?.length || 0;
  const { isShowing, toggle, currentItemId } = useModal();

  useEffect(() => {
    if (filteredList) {
      setList(filteredList);
    } else {
      setList(offersList);
    }

    return () => setList([]);
  });

  if (isLoading) {
    return <Loader />;
  }

  const handleGAImpression = () => {
    handleGAEvents('View All Offers', 'Partner Offers', 'View All Offers Click', 'offers_view_all_offers_click');
  };

  return (
    <>
      <Section>
        <GridWrapper>
          {title && (
            <div className={`full flex items-center justify-between ${isLimitedTimeOffer ? 'mb-8' : 'mb-6'}`}>
              <SectionTitle>
                {title} <span>({offersNum})</span>
              </SectionTitle>
              {isLimitedTimeOffer && (
                <Link
                  href="/offers/limited-time"
                  locale={languageCode}
                  role="presentation"
                  className="font-bold text-right leading-6"
                  onClick={handleGAImpression}
                  onKeyDown={(e) => handleKeyDown(e, handleGAImpression)}
                >
                  {viewAllLabel}
                </Link>
              )}
            </div>
          )}

          {isSlider && list.length > 2 ? (
            <OffersSlider
              toggle={toggle}
              list={list}
              customData={customData}
              sectionTitle={title || sectionTitle}
              listName={listName}
            />
          ) : (
            <OffersGrid
              toggle={toggle}
              list={list}
              customData={customData}
              isGrid
              sectionTitle={title || sectionTitle}
              listName={listName}
            />
          )}
        </GridWrapper>
      </Section>

      <OffersModal
        isShowing={isShowing}
        toggle={toggle}
        currentItemId={currentItemId}
        offersList={filteredList}
        customData={customData}
        languageCode={languageCode}
        sectionTitle={title || sectionTitle}
        listName={listName}
      />
    </>
  );
}

OffersList.propTypes = {
  customData: PropTypes.object,
  title: PropTypes.string,
  isSlider: PropTypes.bool,
  filteredList: PropTypes.array,
  languageCode: PropTypes.string,
  isLimitedTimeOffer: PropTypes.bool,
  listName: PropTypes.string,
  sectionTitle: PropTypes.string,
};
export default OffersList;
