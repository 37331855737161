/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import Image from 'next/image';
import { useRouter } from 'next/router';

// Styles
import { HeroBannerContainer, HeroDetails } from './HeroBannerStyles';
import Button from '../Button/Button';
import { isEmpty } from '../../../helpers/utils';

export default function HeroBanner({ fields }) {
  const router = useRouter();

  if (!isEmpty(fields)) {
    const { title, description, webImage, appImage, cTALink, cTALabelLoading } = fields;

    if (description && cTALink) {
      return (
        <HeroBannerContainer className="relative w-full h-full overflow-hidden flex items-center justify-start">
          {webImage && webImage?.url && (
            <figure className="absolute gumbo-image hidden md:block">
              <Image
                src={webImage?.url}
                alt=""
                loading="eager"
                fill
                sizes="100vw"
                style={{
                  objectFit: 'cover',
                  objectPosition: 'left top',
                }}
              />
            </figure>
          )}
          {appImage && appImage?.url && (
            <figure className="h-56 w-full relative md:hidden">
              <Image
                src={appImage?.url}
                alt=""
                loading="eager"
                fill
                sizes="100vw"
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center top',
                }}
              />
            </figure>
          )}
          <div className="global-content-wrapper w-full h-full ">
            <HeroDetails>
              {title && <h1 className="gumbo" dangerouslySetInnerHTML={{ __html: title }} />}
              {description && <p>{description}</p>}

              {cTALink && cTALink?.href && (
                <Button
                  type="button"
                  className="hero-cta"
                  onClick={() => router.push(cTALink?.href)}
                  buttonLoadingText={cTALabelLoading}
                  ariaLabel={cTALink?.text}
                >
                  {cTALink?.text}
                </Button>
              )}
            </HeroDetails>
          </div>
        </HeroBannerContainer>
      );
    }
    return (
      <HeroBannerContainer className="relative w-full h-full overflow-hidden flex items-center justify-start">
        {webImage && webImage?.url && (
          <div className="absolute top-0 bottom-0 left-1/2 right-0 hidden md:block">
            <Image
              src={webImage?.url}
              alt=""
              loading="eager"
              fill
              sizes="100vw"
              style={{
                objectFit: 'cover',
                objectPosition: 'center center',
              }}
            />
          </div>
        )}
        {appImage && appImage?.url && (
          <div className="h-40 w-full relative md:hidden">
            <Image
              src={appImage?.url}
              alt=""
              loading="eager"
              fill
              sizes="100vw"
              style={{
                objectFit: 'cover',
                objectPosition: 'center center',
              }}
            />
          </div>
        )}

        <div className="global-content-wrapper w-full h-full ">
          <HeroDetails>{title && <h1 dangerouslySetInnerHTML={{ __html: title }} />}</HeroDetails>
        </div>
      </HeroBannerContainer>
    );
  }
  return null;
}

HeroBanner.propTypes = { fields: PropTypes.object };
