import PropTypes from 'prop-types';

import OrderRow from './OrderRow';
import Loader from '../../common/Loader/Loader';

export default function OrderRows({ page, awardsTypes, ordersContent, isLoading, tableHeader, languageCode }) {
  if (isLoading) return <Loader />;

  return (
    <>
      {page &&
        page?.items &&
        page?.items.map((item) => (
          <OrderRow
            key={item.orderNumber}
            item={item}
            award={
              awardsTypes &&
              awardsTypes.filter((award) => award && award?.code.toLowerCase() === item?.items[0]?.awardType.toLowerCase())
            }
            itemLabel={item?.items[0].quantity > 1 ? ordersContent?.itemsLabel : ordersContent?.itemLabel}
            tableHeader={tableHeader}
            orderDetailsLink={ordersContent.orderDetailsLink.href}
            languageCode={languageCode}
          />
        ))}
    </>
  );
}
OrderRows.propTypes = {
  page: PropTypes.object,
  awardsTypes: PropTypes.array,
  ordersContent: PropTypes.object,
  isLoading: PropTypes.bool,
  tableHeader: PropTypes.array,
  languageCode: PropTypes.string,
};
