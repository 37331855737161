import styled from 'styled-components';

export const HeroBannerContainer = styled.div`
  background-color: ${(props) => props.theme.uiBlack};
  display: flex;
  justify-content: flex-end;

  .gumbo-image {
    top: 0;
    bottom: 0;
    right: 0;
    left: 50%;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      left: 60%;
    }
  }
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 768px) {
    min-height: 17.5rem;
  }
`;

export const HeroDetails = styled.div`
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    max-width: 48%;
    &.gumbo {
      max-width: 58%;
    }
  }
  @media screen and (min-width: 1024px) {
    max-width: 48%;
  }

  h1 {
    color: ${(props) => props.theme.whiteColor};
    font-family: 'Bebas Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 0.96;
    text-transform: uppercase;
    margin: 4.375rem 0;

    &.gumbo {
      margin: 6rem 0 3rem 0;
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        margin: 2.5rem 0 1.5rem 0;
      }
      @media screen and (max-width: 767px) {
        margin: 1.5rem 0 0.75rem 0;
      }
    }

    @media screen and (max-width: 767px) {
      font-size: 2.5rem;
      margin: 1.75rem 0;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      font-size: 3.5rem;
    }

    @media screen and (min-width: 1024px) {
      font-size: 4.5rem;
    }
  }

  p {
    color: ${(props) => props.theme.whiteColor};
    font-size: ${(props) => props.theme.introduction};
    line-height: ${(props) => props.theme.lineHeight3};
    margin-bottom: 2.5rem;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      font-size: ${(props) => props.theme.fontBody2};
      margin-bottom: 1.75rem;
    }
    @media screen and (max-width: 767px) {
      font-size: ${(props) => props.theme.fontBody2};
      margin-bottom: 1.5rem;
    }
  }

  .hero-cta {
    margin-bottom: 6rem;
    margin-left: 0;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      margin-bottom: 2.5rem;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 1.5rem;
    }
  }
`;
