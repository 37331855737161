import styled, { css } from 'styled-components';

export const SectionTitle = styled.h2`
  color: ${(props) => props.theme.brandBlack};
  font-weight: bold;
  line-height: ${(props) => props.theme.lineHeight3};

  span {
    color: ${(props) => props.theme.lightGrayColor4};
    font-weight: normal;
  }
`;
export const SectionSubtitle = styled.div`
  p {
    line-height: ${(props) => props.theme.lineHeight3};
  }
`;
export const MoreOffersWrapper = styled.div`
  display: grid;
  grid-gap: 1.875rem;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    + div > button.view-more {
      display: none;
    }
  }

  @media screen and (min-width: 640px) and (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`;
export const OfferItemWrapper = styled.div`
  height: 122px;
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
  background-color: ${(props) => props.theme.whiteColor};
  border: 1px solid ${(props) => props.theme.lightGrayColor3};
`;
export const IconColumn = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  margin-right: 1rem;
  background-color: ${(props) => props.theme.uiBlack};
  position: relative;
`;
export const PartnerIcon = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.uiGrey01};

  @media screen and (min-width: 375px) {
    width: 55px;
    height: 55px;
  }

  @media screen and (min-width: 641px) {
    width: 60px;
    height: 60px;
  }

  @media screen and (min-width: 768px) {
    width: 7vw;
    height: 7vw;
  }
`;
export const DetailsColumn = styled.div`
  position: relative;
  flex: 1;
`;
export const OfferBrandTitle = styled.p`
  font-size: ${(props) => props.theme.fontLegal};
  font-weight: bold;
  color: ${(props) => props.theme.lightGrayColor4};
  margin-bottom: 0.5rem;
`;
export const OfferBonusPoints = styled.h3`
  font-size: ${(props) => props.theme.fontBody2};
  font-weight: bold;
  margin-bottom: 0.35rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
export const OfferDescription = styled.p`
  font-size: ${(props) => props.theme.fontLegal};
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
export const OfferDistance = styled.span`
  font-size: ${(props) => props.theme.fontLegal};
  color: ${(props) => props.theme.lightGrayColor4};
  position: absolute;
  top: 0;
  right: 0;
`;
export const OfferPartnersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.5rem 0;

  @media screen and (min-width: 641px) {
    flex-direction: row;
    align-items: center;
    padding: 4rem 0;
  }

  .partners-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 1rem;
    background-color: ${(props) => props.theme.whiteColor};

    p {
      @media screen and (min-width: 1024px) {
        margin: 0.75rem 0 2.75rem 0;
      }
      @media screen and (min-width: 641px) and (max-width: 1023px) {
        margin: 0.25rem 0 2.25rem 0;
      }
      @media screen and (min-width: 641px) {
        font-size: ${(props) => props.theme.fontBody2};
        line-height: ${(props) => props.theme.lineHeight3};
        min-width: 17.5rem;
      }
    }
  }
  .partners-link {
    font-weight: bold;

    @media screen and (min-width: 768px) {
      font-size: ${(props) => props.theme.fontBody2};
    }
  }
`;

export const OfferActionsWrapper = styled.section`
  margin-bottom: 2.25rem;
  @media screen and (max-width: 640px) {
    margin-bottom: 1.5rem;
  }

  @media screen and (min-width: 641px) and (max-width: 1023px) {
    margin-bottom: 2rem;

    ${(props) =>
      props.page === 'loadable' &&
      css`
        flex-wrap: wrap;
      `};
  }
`;

export const FilterButtonsWrapper = styled.div`
  display: flex;
  align-items: end;
  ${(props) =>
    props.loadAllOffers === true &&
    css`
      width: 31%;
      justify-content: flex-end;
    `};
  ${(props) =>
    props.loadAllOffers !== true &&
    css`
      width: 100%;
      justify-content: space-between;
    `};

  @media screen and (max-width: 1023px) {
    margin-top: 1.313rem;
    width: 100%;
    justify-content: space-between;
  }
`;

export const NoOffersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4.875rem 1rem;
  width: 100%;
  text-align: center;
  background-color: ${(props) => props.theme.whiteColor};
  border: 1px solid ${(props) => props.theme.lightGrayColor3};
  margin-top: 0.25rem;
  @media screen and (max-width: 640px) {
    padding: 2.875rem 1rem;
    margin-top: 0;
  }
  @media screen and (min-width: 641px) and (max-width: 1023px) {
    margin-top: 0.5rem;
  }
  @media screen and (min-width: 991px) {
    width: 36.75rem;
  }

  p {
    font-size: ${(props) => props.theme.fontBody2};
    line-height: ${(props) => props.theme.lineHeight3};
    margin-bottom: 0;
    strong {
      display: block;
      font-size: ${(props) => props.theme.fontBody1};
      margin-bottom: 0.25rem;
    }
    @media screen and (max-width: 640px) {
      font-size: ${(props) => props.theme.fontBody3};
      strong {
        font-size: ${(props) => props.theme.fontBody2};
      }
    }
  }
`;
