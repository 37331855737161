import PropTypes from 'prop-types';

import { Accordion } from 'react-accessible-accordion';

import { AccordionStyle } from './AccordionStyles';
import AccordionList from './AccordionList';

export default function Content({ accordionList, firstItemExpanded = [], page = '', languageCode }) {
  return (
    <AccordionStyle>
      <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={firstItemExpanded}>
        <AccordionList accordionList={accordionList} page={page} languageCode={languageCode} />
      </Accordion>
    </AccordionStyle>
  );
}

Content.propTypes = {
  accordionList: PropTypes.array,
  firstItemExpanded: PropTypes.array,
  page: PropTypes.string,
  languageCode: PropTypes.string,
};
