import Image from 'next/image';
import PropTypes from 'prop-types';

import { DatePickerNav } from './DatePickerNavigationStyles';

export default function DatePickerNavigation({ handleClick, direction, ariaLabelNextMonth, ariaLabelPreviousMonth }) {
  return (
    <DatePickerNav onClick={handleClick} aria-label={direction === 'right' ? ariaLabelNextMonth : ariaLabelPreviousMonth}>
      {direction === 'right' ? (
        <Image
          src="/assets/carousel-arrow-right.svg"
          alt={ariaLabelNextMonth}
          width={15}
          height={15}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      ) : (
        <Image
          src="/assets/carousel-arrow-left.svg"
          alt={ariaLabelPreviousMonth}
          width={15}
          height={15}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      )}
    </DatePickerNav>
  );
}

DatePickerNavigation.propTypes = {
  handleClick: PropTypes.func.isRequired,
  direction: PropTypes.string.isRequired,
  ariaLabelNextMonth: PropTypes.string,
  ariaLabelPreviousMonth: PropTypes.string,
};
