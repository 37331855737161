import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateOffers } from '../../../store/actions';

// Components
import WrapperLayout from '../../layouts/WrapperLayout';
import Loader from '../Loader/Loader';
import { OffersForYou } from '../../Offers/Sections';

// Hooks
import useOffers from '../../hooks/offers/useOffers';
import useSortedOffersList from '../../hooks/offers/useSortedOffersList';
import useOffersCleanup from '../../hooks/offers/useOffersCleanup';

// Helpers
import { handleGaListByType } from '../../../helpers/handleGoogleAnalyticsHelper';
import { deriveOffersFrom } from '../../../helpers/offersHelpers';
import { checkArrayForItems } from '../../../helpers/utils';

const HomepageOffers = ({ title, customData, slides, languageCode }) => {
  const {
    actions,
    state: {
      session: { isLoggedIn },
      offers,
      location,
    },
  } = useStateMachine({ updateOffers });

  const reqBody = {
    category: ['ALL'],
    tag: ['limitedtime'],
    sort: 'newest',
    page: 1,
    limit: slides || 8,
  };

  useOffersCleanup();
  const { offers: offersData, isLoading, error } = useOffers(languageCode, reqBody);

  const sortedList = useSortedOffersList(offers?.limitedTimeOffersList, {
    value: 'newest',
  });

  useEffect(() => {
    if (isLoggedIn && !isLoading && offersData) {
      actions.updateOffers({ ...deriveOffersFrom(offersData), isLoading });
    }
  }, [offersData]);

  useEffect(() => {
    if (!isLoading && sortedList && sortedList.length) {
      handleGaListByType('offer_view_item_list', sortedList, '', '', title, 'All', 'offers', location?.province);
    }
  }, [sortedList]);

  if (isLoggedIn) {
    if (offers?.isLoading || !offersData) {
      return (
        <WrapperLayout className="global-content-wrapper">
          <Loader />
        </WrapperLayout>
      );
    }

    if (checkArrayForItems(sortedList)) {
      return (
        <OffersForYou
          customData={customData.pageContent}
          offersForYouList={sortedList}
          offersForYouLabel={title}
          isSlider
          customStyles="offersHomePage"
          listName={title}
        />
      );
    }

    return null;
  }
  return null;
};

export default HomepageOffers;

HomepageOffers.propTypes = {
  title: PropTypes.string,
  customData: PropTypes.object,
  slides: PropTypes.number,
  languageCode: PropTypes.string,
};
