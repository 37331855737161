import PropTypes from 'prop-types';
import Image from 'next/image';

import { StyledBannerWrapper, BgImageWrapper, StyledBanner, Item, Indicator } from './EarnRewardsStyles';

export default function EarnRewards({ fields }) {
  const stepsList = [
    {
      step: 1,
      heading: fields.step1Header,
      description: fields.step1Message,
    },
    {
      step: 2,
      heading: fields.step2Header,
      description: fields.step2Message,
    },
    {
      step: 3,
      heading: fields.step3Header,
      description: fields.step3Message,
    },
  ];

  if (fields) {
    return (
      <section>
        <StyledBannerWrapper>
          <BgImageWrapper>
            <Image
              alt=""
              src={fields.image.url}
              fill
              sizes="100vw"
              style={{
                objectFit: 'cover',
              }}
            />
          </BgImageWrapper>
          <div className="global-content-wrapper">
            <StyledBanner>
              <h2 className="headline-medium block uppercase">{fields.title}</h2>
              <div className="flex w-full justify-between items-stretch flex-col md:flex-row md:gap-x-8 lg:gap-x-16">
                {stepsList &&
                  stepsList.map((step) => (
                    <Item key={step.step}>
                      <Indicator>{step.step}</Indicator>
                      <h3>{step.heading}</h3>
                      <p>{step.description}</p>
                    </Item>
                  ))}
              </div>
            </StyledBanner>
          </div>
        </StyledBannerWrapper>
      </section>
    );
  }
  return null;
}

EarnRewards.propTypes = { fields: PropTypes.object };
