import { useInfiniteQuery } from 'react-query';

import { getOrders } from '../../../pages/api/services';
import { getTimeZone } from '../../../helpers/dateHelpers';

export default function useOrders(accessToken, fromDate, toDate, limit) {
  const { data, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isFetching, refetch } = useInfiniteQuery(
    ['fetchOrders', fromDate, toDate],
    async ({ pageParam = 1 }) => {
      const res = await getOrders(
        accessToken,
        `${fromDate}T00:00:00${getTimeZone()}`,
        `${toDate}T00:00:00${getTimeZone()}`,
        pageParam,
        limit
      );

      return JSON.parse(res.data.data);
    },
    {
      getNextPageParam: (page) => (page && page?.itemsCount > 18 ? null : page?.pageNumber + 1),
    }
  );

  return {
    data,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    refetch,
  };
}
