// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import FeatureBanner from '../common/Banners/FeatureBanner';
import WrapperLayout from '../layouts/WrapperLayout';
import { FeatureCarouselTitle } from '../common/Banners/BannerStyles';

// Helpers
import { getGASlot } from '../../helpers/bannersHelper';

const FeatureBannersCarousel = ({ module, languageCode, page }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  const { banners, title } = module.fields;

  const slot = getGASlot(page?.zones, module.contentID);

  if (isLoggedIn && banners) {
    return (
      <WrapperLayout
        className={`global-content-wrapper ${title ? 'pb-36' : ''} `}
        customStyles={title ? 'false' : 'defaultPadding'}
      >
        {title && <FeatureCarouselTitle>{title}</FeatureCarouselTitle>}

        <FeatureBanner
          banners={banners}
          languageCode={languageCode}
          customData={module.fields}
          bannerType="FeatureBanner"
          pageName={page.name}
          slot={slot}
          sectionTitle={title}
        />
      </WrapperLayout>
    );
  }
  return null;
};

export default FeatureBannersCarousel;
