/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { useStateMachine } from 'little-state-machine';
import OffersCardModal from '../common/Modals/OffersCardModal';
import OfferNotFoundModal from '../common/Modals/OfferNotFoundModal';
import Portal from '../common/Portal/Portal';
import ModalLayout from '../layouts/ModalLayout';
import Loader from '../common/Loader/Loader';

// Helpers
import { removeURLParameter, updateURI } from '../../helpers/navigationHelpers';

export default function OffersModal({
  isShowing,
  currentItemId,
  offersList,
  offer,
  toggle,
  languageCode,
  customData,
  sectionTitle,
  listName,
  isLoading,
}) {
  const {
    state: {
      user: { onBoardMessageActive },
    },
  } = useStateMachine();

  const handleToggleModal = () => {
    const url = window?.location?.href;

    if (url.includes('#')) {
      const cleanHashURI = `${url.split('#')[0]}?category=featured`;
      updateURI(removeURLParameter(cleanHashURI, 'offer'));
    } else {
      updateURI(removeURLParameter(url, 'offer'));
    }

    toggle();
  };

  useEffect(() => {
    if (offer === null) {
      handleToggleModal();
    }
  }, [offer]);

  if (isShowing && offersList) {
    // TODO - we need a helper for modals that will manage the fields to compare and the value to compare since object structure in response may be dynamic

    return (
      <>
        <span className="overlay" />
        {offersList.map(
          (item, index) =>
            currentItemId === item.offerId && (
              <ModalLayout key={index} toggle={handleToggleModal} type="modal-info">
                <OffersCardModal
                  offer={item}
                  languageCode={languageCode}
                  customData={customData}
                  sectionTitle={sectionTitle}
                  listName={listName}
                  id={`card-${index}`}
                />
              </ModalLayout>
            )
        )}
      </>
    );
  }

  if (isShowing && !onBoardMessageActive) {
    return (
      <Portal>
        <span className="overlay" />
        <ModalLayout
          toggle={handleToggleModal}
          type={!isLoading && !offer ? 'modal-confirmation' : 'modal-info'}
          hideCloseIcon={!isLoading && !offer}
        >
          {isLoading ? (
            <Loader id="modal-label" />
          ) : offer ? (
            <OffersCardModal offer={offer} languageCode={languageCode} customData={customData} sectionTitle={sectionTitle} />
          ) : (
            <OfferNotFoundModal customData={customData} languageCode={languageCode} toggle={handleToggleModal} />
          )}
        </ModalLayout>
      </Portal>
    );
  }

  return null;
}
OffersModal.propTypes = {
  isShowing: PropTypes.bool,
  currentItemId: PropTypes.string,
  offersList: PropTypes.array,
  offer: PropTypes.object,
  toggle: PropTypes.func,
  languageCode: PropTypes.string,
  customData: PropTypes.object,
  sectionTitle: PropTypes.string,
  listName: PropTypes.string,
  isLoading: PropTypes.bool,
};
