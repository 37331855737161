import { useEffect } from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/router';

export default function useReactGA() {
  const { asPath } = useRouter();

  // initialize Tag manager on Mount
  useEffect(() => {
    // Tag manager
    if (!window?.GA_TAG_MANAGER_INITIALIZED) {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GA_TAGMANAGER,
        auth: process.env.NEXT_PUBLIC_GTM_AUTH_KEY,
        preview: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT,
      });
      window.GA_TAG_MANAGER_INITIALIZED = true;
    }
  }, []);

  useEffect(() => {
    // Double check that ReactGA loaded before emitting event
    if (!window?.GA_INITIALIZED) {
      ReactGA.initialize(process.env.NEXT_PUBLIC_GA_UNIVERSAL);
      window.GA_INITIALIZED = true;
    }

    if (window?.GA_TAG_MANAGER_INITIALIZED && window?.GA_INITIALIZED) {
      ReactGA.set({ page: asPath });
      ReactGA.pageview(asPath);
    }
  }, [asPath]);
}
