import { useQuery } from 'react-query';
import { getGenders } from '../../../pages/api/services';

export default function useGenders(languageCode) {
  const { isLoading, error, data } = useQuery('fetchGenders', async () => {
    try {
      if (languageCode) {
        const res = await getGenders(languageCode);
        return res.data.data;
      }
    } catch (error) {
      console.error(error);
    }
  });

  return { isLoading, error, genders: data };
}
