/* eslint-disable no-unused-vars */
import Script from 'next/script';
import PropTypes from 'prop-types';

import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useStateMachine } from 'little-state-machine';
import { updateUser } from '../../../store/actions';

import Loader from '../../common/Loader/Loader';
import FormInput from './FormInput';
import FormPostalInput from './FormPostalInput';
import FormSelectProvince from './FormSelectProvince';

import { PostalCodeContainer } from '../FormStyles';
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';
import { camelCaseToSentence } from '../../../helpers/stringHelpers';
import { populateAddressFromCanadaPost } from '../../../helpers/formHelpers';

export default function CanadaPostSearch(props) {
  const {
    addressLookupPlaceholder,
    cantFindAddressLabel,
    addressLookupLabel,
    addressLine1,
    addressLine2,
    addressLine1Label,
    addressLine2Label,
    provinceLabel,
    provinceCode,
    provinceDefaultOption,
    provinces,
    cityLabel,
    city,
    postalCode,
    postalCodeLabel,
    errorsList,
    addManuallyLabel,
    isEditAddress,
    isAddAddress,
    addressModal,
    gaFlow,
  } = props;

  const [isReady, setIsReady] = useState(false);
  const [enterAddressManually, setEnterAddressManually] = useState(false);
  const { state, actions } = useStateMachine({ updateUser });

  const formContext = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { setValue, getValues } = formContext;

  useEffect(() => {
    // Canada Post Functionality
    if (!window.pca) return;

    const fields = [
      {
        element: 'initialAddress',
        field: 'Address',
        mode: window.pca.fieldMode.DEFAULT,
      },
      {
        element: 'addressLine1',
        field: 'Line1',
        mode: window.pca.fieldMode.POPULATE,
      },
      {
        element: 'addressLine2',
        field: 'Line2',
        mode: window.pca.fieldMode.POPULATE,
      },
      { element: 'city', field: 'City', mode: window.pca.fieldMode.POPULATE },
      {
        element: 'provinceCode',
        field: 'ProvinceCode',
        mode: window.pca.fieldMode.POPULATE,
      },
      {
        element: 'postalCode',
        field: 'PostalCode',
        mode: window.pca.fieldMode.POPULATE,
      },
    ];

    const options = {
      key: 'gx93-ct39-nw38-wa66',
      bar: {
        showCountry: false,
        showLogo: true,
      },
    };

    const cpControl = new window.pca.Address(fields, options);

    cpControl.listen('populate', (address) => {
      if (address) {
        const populatedAddress = populateAddressFromCanadaPost(address);

        actions.updateUser({
          initialAddress: populatedAddress.initialAddress,
          addressLine1: populatedAddress.addressLine1,
          addressLine2: populatedAddress.addressLine2,
          city: populatedAddress.city,
          provinceCode: populatedAddress.provinceCode,
          postalCode: populatedAddress.postalCode,
        });

        setValue('initialAddress', populatedAddress.initialAddress);
        setValue('addressLine1', populatedAddress.addressLine1);
        setValue('addressLine2', populatedAddress.addressLine2);
        setValue('city', populatedAddress.city);
        setValue('provinceCode', populatedAddress.provinceCode);
        setValue('postalCode', populatedAddress.postalCode);
      }
    });
  }, [window.pca, isReady]);

  const setManual = () => {
    setEnterAddressManually(true);
    actions.updateUser({
      initialAddress: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      provinceCode: '',
      postalCode: '',
    });

    setValue('initialAddress', '');
    setValue('addressLine1', '');
    setValue('addressLine2', '');
    setValue('city', '');
    setValue('provinceCode', '');
    setValue('postalCode', '');
    if (gaFlow === 'register' || gaFlow === 'registerGK') {
      const dataLayer = {
        event: 'sign_up_cant_find_address_click',
        category: 'Registration Events',
        action: 'Cant Find Address Click',
        label: 'Registration - Cant Find Address Click',
        sign_up_step: `Step 2${gaFlow === 'registerGK' ? ' GK' : ''}`,
      };
      handleGAEventsBase(dataLayer);
    }
  };

  const error =
    formContext.formState.errors.addressLine1 ||
    formContext.formState.errors.provinceCode ||
    formContext.formState.errors.city ||
    formContext.formState.errors.postalCode;

  const isError =
    (error && formContext.formState.isSubmitted) ||
    (error && formContext.formState.touchedFields.addressLine1) ||
    (error && formContext.formState.touchedFields.provinceCode) ||
    (error && formContext.formState.touchedFields.city) ||
    (error && formContext.formState.touchedFields.postalCode);

  const provinceStartList = provinces || provinces.data.data || provinces.data;
  const provinceDefaultLabel =
    provinces && provinces.filter((prov) => prov?.code === provinceCode).map((a) => ({ code: a?.code, name: a?.name }));
  const defaultProvince = {
    value: provinceDefaultLabel[0]?.code,
    label: provinceDefaultLabel[0]?.name,
  };
  const provinceOptions = provinceStartList.map((a) => ({
    value: a?.code,
    label: a?.name,
  }));

  const gaEvent = (e) => {
    if (gaFlow === 'register' || gaFlow === 'registerGK') {
      const dataLayer = {
        event: 'sign_up_form_field',
        category: 'Registration Events',
        action: 'Form Field Click',
        label: camelCaseToSentence(e.target.name),
        sign_up_step: `Step 2${state?.user?.isGhostKitActivationEnabled ? ' GK' : ''}`,
        sign_up_form_field: camelCaseToSentence(e.target.name),
      };
      handleGAEventsBase(dataLayer);
    }
  };

  // Handle closing menu items with keypress
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      setManual();
    }
  };

  return (
    <>
      <Script
        src="https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js?key=gx93-ct39-nw38-wa66"
        onReady={() => setIsReady(true)}
      />

      {!window.pca && !isReady ? (
        <Loader />
      ) : (
        <>
          <div className="cp-search-wrapper">
            <FormInput
              onFocus={gaEvent}
              id="initialAddress"
              fieldType="text"
              name="initialAddress"
              label={addressLookupLabel}
              placeholder={addressLookupPlaceholder}
              defaultVal=""
              addressModal={addressModal}
              rules={{
                required: {
                  message: errorsList.filter((item) => item?.code === 'Address.Address1.Required').map((item) => item.message)[0],
                  value: !(isEditAddress || enterAddressManually),
                },
              }}
            />
          </div>
          {!isError && !enterAddressManually && !isEditAddress ? (
            <span
              role="button"
              tabIndex="0"
              className="address-lookup cursor-pointer mt-1 manual-address-option"
              onClick={() => setManual()}
              onKeyDown={handleKeyDown}
            >
              {cantFindAddressLabel}
            </span>
          ) : (
            <div className="input-header address-header">{addManuallyLabel}</div>
          )}
          <div className={isError || isEditAddress || enterAddressManually ? 'block' : 'hidden'}>
            <FormInput
              onFocus={gaEvent}
              id="addressLine1"
              fieldType="text"
              addressModal={addressModal}
              name="addressLine1"
              aria-invalid={formContext.formState.errors.addressLine1 ? 'true' : 'false'}
              label={addressLine1Label}
              defaultVal={addressLine1 || (isAddAddress ? '' : state.user.addressLine1)}
              rules={{
                required: {
                  message: errorsList.filter((item) => item?.code === 'Address.Address1.Required').map((item) => item.message)[0],
                  value: true,
                },
                maxLength: {
                  message: errorsList.filter((item) => item?.code === 'Address.Address1.Length').map((item) => item.message)[0],
                  value: 128,
                },
              }}
            />
          </div>
          <FormInput
            onFocus={gaEvent}
            id="addressLine2"
            fieldType="text"
            name="addressLine2"
            addressModal={addressModal}
            label={addressLine2Label}
            defaultVal={addressLine2 || (isAddAddress ? '' : state.user.addressLine2)}
            rules={{
              required: {
                message: '',
                value: false,
              },
              maxLength: {
                message: errorsList.filter((item) => item?.code === 'Address.Address2.Length').map((item) => item.message)[0],
                value: 128,
              },
            }}
          />
          <div className={isError || isEditAddress || enterAddressManually ? 'block mb-4 relative z-10' : 'hidden'}>
            <FormSelectProvince
              gaFlow={gaFlow}
              name="provinceCode"
              label={provinceLabel}
              options={provinceOptions}
              addressModal={addressModal}
              defaultVal={provinceCode}
              defaultOption={provinceCode ? defaultProvince : null}
              placeholder={provinceDefaultOption}
              isEditAddress={isEditAddress}
              rules={{
                required: {
                  message: errorsList
                    .filter((item) => item?.code === 'Address.ProvinceCode.Required')
                    .map((item) => item.message)[0],
                  value: true,
                },
              }}
            />

            <PostalCodeContainer className="field-flex">
              <div>
                <FormInput
                  onFocus={gaEvent}
                  addressModal={addressModal}
                  id="city"
                  fieldType="text"
                  name="city"
                  aria-invalid={formContext.formState.errors.city ? 'true' : 'false'}
                  label={cityLabel}
                  defaultVal={city || (isAddAddress ? '' : state.user.city)}
                  rules={{
                    required: {
                      message: errorsList.filter((item) => item?.code === 'Address.City.Required').map((item) => item.message)[0],
                      value: true,
                    },
                  }}
                />
              </div>

              <div>
                <FormPostalInput
                  onFocus={gaEvent}
                  addressModal={addressModal}
                  id="postalCode"
                  fieldType="text"
                  name="postalCode"
                  aria-invalid={formContext.formState.errors.postalCode ? 'true' : 'false'}
                  label={postalCodeLabel}
                  maxLength={7}
                  errorsList={errorsList}
                  defaultVal={postalCode || (isAddAddress ? '' : state.user.postalCode && state.user.postalCode)}
                  rules={{
                    required: {
                      message: errorsList
                        .filter((item) => item?.code === 'Address.PostalCode.Required')
                        .map((item) => item.message)[0],
                      value: true,
                    },
                  }}
                />
              </div>
            </PostalCodeContainer>
          </div>
        </>
      )}
    </>
  );
}

CanadaPostSearch.defaultProps = {
  gaFlow: '',
  addressModal: false,
};

CanadaPostSearch.propTypes = {
  addressModal: PropTypes.bool,
  errorsList: PropTypes.array,
  gaFlow: PropTypes.string,
  addressLookupPlaceholder: PropTypes.string,
  cantFindAddressLabel: PropTypes.string,
  addressLookupLabel: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  addressLine1Label: PropTypes.string,
  addressLine2Label: PropTypes.string,
  provinceLabel: PropTypes.string,
  provinceCode: PropTypes.string,
  provinceDefaultOption: PropTypes.string,
  provinces: PropTypes.array,
  cityLabel: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
  postalCodeLabel: PropTypes.string,
  addManuallyLabel: PropTypes.string,
  isEditAddress: PropTypes.bool,
  isAddAddress: PropTypes.bool,
};
