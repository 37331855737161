import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';

// Hooks
import { deriveErrorMessageList } from '../../helpers/agilityHelpers';
import useCards from '../hooks/points/useCards';

// Components
import PointsShop from '../Points/Shop/v2/PointsShop';
import NoCardApply from '../Points/NoCardApply';
import WrapperLayout from '../layouts/WrapperLayout';
import TabbedComponent from '../common/Tabs/TabbedComponent';
import Breadcrumbs from '../common/Breadcrumbs/Breadcrumbs';

// Helpers
import { spendPointsForCreditEcomm } from '../../helpers/handleGoogleAnalyticsHelper';

const PFC = ({ customData, languageCode }) => {
  const { termsLabel, termsMessage } = customData.pageContent;
  const {
    state: {
      user: { customer },
      session: { isLoggedIn },
      points: { step },
    },
  } = useStateMachine();

  useCards();

  const cardHolder = customer && customer.data && !customer?.data?.isCreditHolder && !customer?.data?.isDebitHolder;

  const tabsList = [
    {
      tabTitle: termsLabel,
      tabContent: termsMessage,
    },
  ];

  useEffect(() => {
    spendPointsForCreditEcomm(
      'p4c_view_item',
      'Points For Credit',
      0,
      '',
      '',
      'points_credit_detail_view',
      'Points For Credit Detail View'
    );
  }, []);

  if (isLoggedIn) {
    return (
      <>
        {step === 1 && (
          <WrapperLayout className="w-full" isGray="true" mobileIsWhite="true">
            <div className="global-content-wrapper">{customData.sitemap && <Breadcrumbs customData={customData.sitemap} />}</div>
          </WrapperLayout>
        )}
        {cardHolder ? (
          <NoCardApply customData={customData.pageContent} languageCode={languageCode} />
        ) : (
          <PointsShop page="pfc" customData={customData.pageContent} languageCode={languageCode} />
        )}

        {step === 1 && (
          <WrapperLayout className="global-content-wrapper pb-8 md:pb-0" isWhite>
            <TabbedComponent tabs={tabsList} />
          </WrapperLayout>
        )}
      </>
    );
  }
  return <div className="flex justify-center">{/* No account for now */}</div>;
};

PFC.getCustomInitialProps = async ({ agility, channelName, languageCode }) => {
  // set up api
  const api = agility;
  try {
    // get sitemap...
    const sitemap = await api.getSitemap({
      channelName,
      languageCode,
    });
    const contentItemList = await api.getContentList({
      referenceName: 'pfcv2content',
      languageCode,
    });

    // get ErrorMessages...
    const rawErrorMessages = await api.getContentList({
      referenceName: 'errormessages',
      languageCode,
    });

    const errorCodes = ['General', 'message'];
    const contentItem = contentItemList && contentItemList[0];

    return {
      pageContent: contentItem.fields,
      errorsList: deriveErrorMessageList(rawErrorMessages, errorCodes),
      sitemap,
      languageCode,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

PFC.propTypes = {
  languageCode: PropTypes.string,
  customData: PropTypes.object,
};

export default PFC;
