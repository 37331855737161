export function generateErrorMessage(messages = [], errorsList = []) {
  const filteredMessages = messages.length > 1 ? messages.filter((m) => m !== 'General.BadRequest') : messages;

  const messageString = filteredMessages.map((code) => {
    const c = code.trim();
    const rawMessage = errorsList.filter((item) => item.code === c).map((item) => item.message)[0];
    const message = rawMessage;

    return message;
  });

  // just output as string
  return messageString.join('<br/>');
}
