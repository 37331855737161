import PropTypes from 'prop-types';
import Image from 'next/image';
import { useWindowWidth } from '@react-hook/window-size';
import { StyledList, ImageContainer, ImageAppContainer } from './SpendPointsListStyles';

function SpendPointsList({ list }) {
  const viewPortWidth = useWindowWidth();
  const handleClick = (e, anchorName) => {
    e.preventDefault();
    const element = document.getElementById(anchorName);
    if (document.getElementById(anchorName)) {
      const offset = viewPortWidth > 1439 ? 130 : 90;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleKeyDown = (e, anchorName) => {
    if (e.keyCode === 13) {
      handleClick(e, anchorName);
    }
  };
  if (list && list.length) {
    return (
      <StyledList className="grid grid-cols-1 gap-0">
        {list.map((item, index) => (
          <li
            key={index}
            className="list-none flex text-left items-stretch justify-between"
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            style={{ cursor: 'pointer' }}
            onClick={(e) => handleClick(e, `Partners-${item.anchorName}`)}
            tabIndex="0"
            onKeyDown={(e) => handleKeyDown(e, `Partners-${item.anchorName}`)}
          >
            <div>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>

            <ImageContainer aria-hidden="true">
              <Image
                src={item.image.url}
                className="object-contain object-right-bottom"
                alt=""
                role="presentation"
                fill
                sizes="100vw"
              />
            </ImageContainer>
            <ImageAppContainer aria-hidden="true">
              <Image
                src={item.appImage.url}
                className="object-contain object-right-bottom"
                alt=""
                role="presentation"
                fill
                sizes="100vw"
              />
            </ImageAppContainer>
          </li>
        ))}
      </StyledList>
    );
  }

  return null;
}

SpendPointsList.propTypes = { list: PropTypes.array };

export default SpendPointsList;
