import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../../layouts/WrapperLayout';
import { OfferActions, MoreOffers } from '../Sections';
import OffersList from '../OffersList';
import NoOffers from '../NoOffers';

// Hooks
import useSortedOffersList from '../../hooks/offers/useSortedOffersList';

// Helpers
import { checkArrayForItems } from '../../../helpers/utils';

function GeneralOffersTab({ customData, languageCode, offerOptions }) {
  const {
    state: {
      offers: { offersList, moreOffersList, sortBy, selectedFilters },
    },
  } = useStateMachine();

  const { moreOffersLabel, moreOffersMessage, loadOfferLabel, offerLoadedLabel } = customData.pageContent;

  const sortedList = useSortedOffersList(offersList, sortBy);

  if (checkArrayForItems(sortedList) && checkArrayForItems(moreOffersList)) {
    return (
      <div id="offers-tabs" role="tabpanel" tabIndex="0" aria-labelledby={selectedFilters}>
        <WrapperLayout isGray customStyles="offersGeneralOffers">
          {offerOptions && (
            <WrapperLayout className="global-content-wrapper">
              <OfferActions
                customData={customData}
                offerOptions={offerOptions}
                showFilter={selectedFilters === 'grocery'}
                page={selectedFilters}
                disable={!sortedList?.length}
              />

              <OffersList
                customData={customData.pageContent}
                languageCode={languageCode}
                filteredList={sortedList}
                loadOfferLabel={loadOfferLabel}
                offerLoadedLabel={offerLoadedLabel}
              />
            </WrapperLayout>
          )}
        </WrapperLayout>
        <WrapperLayout isWhite mobileIsWhite customStyles="offersGeneralMoreOffers">
          <MoreOffers
            title={moreOffersLabel}
            filteredList={moreOffersList}
            message={moreOffersMessage}
            isWhite
            customData={customData.pageContent}
          />
        </WrapperLayout>
      </div>
    );
  }

  if (checkArrayForItems(sortedList) || checkArrayForItems(moreOffersList)) {
    return (
      <div id="offers-tabs" role="tabpanel" tabIndex="0" aria-labelledby={selectedFilters}>
        <WrapperLayout
          className={`${checkArrayForItems(sortedList) || checkArrayForItems(moreOffersList) ? 'min-half-screen' : ''}`}
          isGray
          customStyles="offersGeneralOffers"
        >
          {checkArrayForItems(sortedList) && offerOptions && (
            <WrapperLayout className="global-content-wrapper">
              <OfferActions
                customData={customData}
                offerOptions={offerOptions}
                showFilter={selectedFilters === 'grocery'}
                page={selectedFilters}
                disable={!sortedList?.length}
              />
              <OffersList
                customData={customData.pageContent}
                languageCode={languageCode}
                filteredList={sortedList}
                loadOfferLabel={loadOfferLabel}
                offerLoadedLabel={offerLoadedLabel}
              />
            </WrapperLayout>
          )}

          {checkArrayForItems(moreOffersList) && (
            <MoreOffers
              title={moreOffersLabel}
              filteredList={moreOffersList}
              message={moreOffersMessage}
              customData={customData.pageContent}
              isGray
            />
          )}
        </WrapperLayout>
      </div>
    );
  }
  return (
    <div id="offers-tabs" role="tabpanel" tabIndex="0" aria-labelledby={selectedFilters}>
      <WrapperLayout isGray customStyles="offersGeneralOffers" className="min-half-screen">
        <WrapperLayout className="global-content-wrapper">
          <OfferActions
            customData={customData}
            offerOptions={offerOptions}
            showFilter={selectedFilters === 'grocery'}
            page={selectedFilters}
            disable={!sortedList?.length}
          />
          <NoOffers customData={customData} />
        </WrapperLayout>
      </WrapperLayout>
    </div>
  );
}

GeneralOffersTab.propTypes = {
  customData: PropTypes.object.isRequired,
  languageCode: PropTypes.string.isRequired,
  offerOptions: PropTypes.array,
};

export default GeneralOffersTab;
