/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';

import Link from 'next/link';
import Image from 'next/image';
import { renderHTML } from '@agility/nextjs';
import {
  CardAvatarWrapper,
  CardAvatar,
  CardApplyItem,
  NoCardContents,
  InnerColumnBox,
  BanksList,
  SectionHeaderBYOT,
} from './Shop/PointsShopStyles';

import Title from '../common/Headings/Title';
import { TitleWrapper } from '../common/Headings/TitleStyles';

export default function CardApply({ customData }) {
  const {
    title,
    noCardMessage,
    sectionHeader,
    debitCardTitle,
    debitCardMessage,
    debitCardLinkLabelSR,
    debitCardLink,
    creditCardTitle,
    creditCardMessage,
    creditCardLinkSR,
    creditCardLink,
    debitCardImage,
    creditCardImage,
  } = customData;

  const bankCards = [
    {
      cardTitle: creditCardTitle,
      cardMessage: creditCardMessage,
      cardLinkLabel: creditCardLinkSR,
      cardLink: creditCardLink,
      cardImage: creditCardImage,
    },
    {
      cardTitle: debitCardTitle,
      cardMessage: debitCardMessage,
      cardLinkLabel: debitCardLinkLabelSR,
      cardLink: debitCardLink,
      cardImage: debitCardImage,
    },
  ];

  return (
    <div className="global-content-wrapper">
      {title && (
        <TitleWrapper id="page-header-byot}">
          <Title>{title}</Title>
        </TitleWrapper>
      )}

      {noCardMessage && <p className="md:w-2/5" dangerouslySetInnerHTML={renderHTML(noCardMessage)} />}
      {bankCards && (
        <div className="lg:py-20 md:py-16 py-6">
          {sectionHeader && <SectionHeaderBYOT>{sectionHeader}</SectionHeaderBYOT>}
          <BanksList className="flex justify-between flex-col lg:flex-row">
            {bankCards.map((card, index) => (
              <CardApplyItem key={index} className="flex-1 sm:mb-8">
                <InnerColumnBox className="flex flex-1 md:items-center ">
                  <CardAvatarWrapper>
                    <CardAvatar className="rounded-full">
                      <Image
                        src={`${card.cardImage ? card.cardImage.url : '/assets/placeholder.svg'}`}
                        alt={card.cardTitle || ''}
                        loading="eager"
                        fill
                        sizes="100vw"
                        style={{
                          objectFit: 'contain',
                          objectPosition: 'center top',
                        }}
                      />
                    </CardAvatar>
                  </CardAvatarWrapper>
                  <NoCardContents>
                    {card.cardTitle && <h2 className="font-bold text-sm mb-2">{card.cardTitle}</h2>}
                    {card.cardMessage && <p className="text-sm mb-2">{card.cardMessage}</p>}
                    <Link href={card.cardLink.href} title={card.cardLinkLabel} target={card.cardLink.target}>
                      {card.cardLink.text}
                    </Link>
                  </NoCardContents>
                </InnerColumnBox>
              </CardApplyItem>
            ))}
          </BanksList>
        </div>
      )}
    </div>
  );
}

CardApply.propTypes = {
  customData: PropTypes.object,
};
