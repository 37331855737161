import PropTypes from 'prop-types';
import Image from 'next/image';

// Global State
import { useStateMachine } from 'little-state-machine';

import { handleKeyDown } from '../../../helpers/utils';
import { handleGaEcommImpression, getMoreOffersMappedObject } from '../../../helpers/handleGoogleAnalyticsHelper';

import { OfferBrandTitle, OfferBonusPoints, OfferDescription, OfferItemWrapper, IconColumn, DetailsColumn } from '../OfferStyles';

export default function OfferItem({ content, icons, toggle, listName, id }) {
  const { brand, subTitle, title } = content.cmsContent;

  const {
    state: { location },
  } = useStateMachine();

  const handleTileClick = () => {
    handleGaEcommImpression(
      'offer_select_item',
      getMoreOffersMappedObject(content, parseInt(id?.split('-')[1]) + 1, listName || '', location?.province)
    );
  };

  const handleClick = (e) => {
    e.preventDefault();
    handleTileClick();
    toggle(content.offerId);
  };

  const categoryIcon = icons
    .filter((icon) => icon.fields.code === content.category[0])
    .map((iconImage) => iconImage.fields.image);

  return (
    <div
      role="button"
      style={{ cursor: 'pointer' }}
      onClick={handleClick}
      tabIndex="0"
      onKeyDown={(e) => handleKeyDown(e, handleClick)}
    >
      <OfferItemWrapper>
        <IconColumn>
          {categoryIcon[0] && (
            <Image
              src={categoryIcon[0].url}
              alt={content.category[0]}
              height={58}
              width={58}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          )}
        </IconColumn>
        <DetailsColumn>
          {brand && <OfferBrandTitle>{brand.title}</OfferBrandTitle>}
          {title && <OfferBonusPoints>{title}</OfferBonusPoints>}
          {subTitle && <OfferDescription>{subTitle}</OfferDescription>}
        </DetailsColumn>
      </OfferItemWrapper>
    </div>
  );
}

OfferItem.propTypes = {
  content: PropTypes.object.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  icons: PropTypes.array,
  toggle: PropTypes.func,
  listName: PropTypes.string,
  id: PropTypes.string,
};
