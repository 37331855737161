import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateNotification, updatePoints, updateTransactions } from '../../../../store/actions';

import Title from '../../../common/Headings/Title';
import TermsContent from '../../../common/Terms/TermsContent';
import Button from '../../../common/Button/Button';
import Loader from '../../../common/Loader/Loader';

// Styles
import { DetailsWrapper, SectionDetails, SectionTitle, DetailsInfo, CardOption } from '../PointsShopStyles';

// Service
import { savePFC, saveBYOT } from '../../../../pages/api/services';

// Helpers
import {
  handleGaEcommImpression,
  getConfirmPointsForCreditMapped,
  getBYOTOrderMapping,
  getP4COrderMapping,
  spendPointsForCreditEcomm,
} from '../../../../helpers/handleGoogleAnalyticsHelper';

import {
  amountSelected,
  byotAmountSelected,
  formatPoints,
  calcPointsTotal,
  calcTotalDollar,
  calcPointsNeeded,
} from '../../../../helpers/pointsHelpers';

import { formatToCurrency } from '../../../../helpers/utils';

export default function ConfirmDetails({ customData, page, languageCode }) {
  const {
    confirmHeader,
    pts,
    summaryPointsLabel,
    summaryBalanceLabel,
    accountLabel,
    termsConditionsLabel,
    termsConditionsMessage,
    emailAddressLabel,
    confirmButtonLabel,
    confirmNewBalanceLabel,
    selectedTransactionsLabel,
    pointsSummaryLabel,
    nTNotSelected,
    summaryRedeemedLabel,
    itemsLabel,
    amountLabel,
    creditLabel,
    equalsToLabel,
  } = customData;

  const {
    state: {
      user: { customer, req },
      session: { accessToken },
      language: { locale },
      points: { added },
      transactions: { selectedCard, balance, awards },
      giftCards: { redeem },
    },
    actions,
  } = useStateMachine({ updatePoints, updateTransactions, updateNotification });

  const [submitting, setSubmitting] = useState(false);
  const loading = !customer.data;
  const awardProduct = awards?.awardProduct && awards.awardProduct[0];

  // Points Calculation
  const total = calcPointsTotal(added);
  const pointsNeeded = calcPointsNeeded(added, balance);
  const partialRedemption = balance - pointsNeeded < balance;
  const remainingPoints = partialRedemption ? 0 : balance - total;
  const redemptionAmount = partialRedemption ? balance : total;

  // Total Dollar Value
  const totalDollar =
    partialRedemption && awardProduct
      ? (parseInt(balance) * (awardProduct?.dollarValue / awardProduct?.points)).toFixed(2)
      : calcTotalDollar(added);

  const router = useRouter();
  const formMethods = useForm();

  // Vars for saving
  const AccountUniqueId = selectedCard.id;

  const awardProductCodePFC = added
    ? added
        .filter((award) => award.id === awards.awardProduct?.id)
        .map((items) => items.code)
        .toString()
    : ''; // ensure not array

  const awardProductCodeBYOT = awards
    ? awards.awardProduct.filter((award) => award.id === added.id).map((items) => items.code)
    : '';

  const EarnPointId = added ? added.map((items) => items.id) : '';

  useEffect(() => {
    try {
      if (page === 'byot') {
        handleGaEcommImpression(
          'byot_begin_checkout',
          getConfirmPointsForCreditMapped(
            '',
            'Points For Travel',
            redemptionAmount,
            awardProductCodeBYOT[0],
            `${amountSelected(added)}`,
            total,
            'points_travel_checkout',
            'Points For Travel Checkout',
            totalDollar
          )
        );
      } else if (page === 'pfc') {
        spendPointsForCreditEcomm(
          'p4c_begin_checkout',
          'Points For Credit',
          total,
          `${amountSelected(added)}`,
          awardProductCodePFC,
          'points_credit_checkout',
          'Points For Credit Checkout',
          totalDollar
        );
      }
    } catch {
      //
    }
  }, []);

  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        if (router.asPath.indexOf('travel') !== -1) {
          const amount = byotAmountSelected(added);
          handleGaEcommImpression('remove_from_cart', getBYOTOrderMapping(total, added, amount));
        } else if (router.asPath.indexOf('redeem-points-for-credit') !== -1) {
          const amount = amountSelected(added);
          handleGaEcommImpression('remove_from_cart', getP4COrderMapping(total, redeem, amount));
        }
      }
      return true;
    });
    return () => {
      router.beforePopState(() => true);
    };
  }, [router]);

  const onSubmit = async () => {
    setSubmitting(true);

    const reqBodyPFC = {
      AccountUniqueId,
      AwardProductCode: awardProductCodePFC,
    };
    const reqBodyBYOT = {
      AccountUniqueId,
      AwardCode: awardProductCodeBYOT[0],
      EarnPointId,
      RedemptionPoints: redemptionAmount,
    };

    const headers = {
      'X-Client-UserAgent': req.userAgent,
      'X-Client-IPAddress': req.ip,
    };

    // TODO: MAKE HOOK FOR SAVING DATA TO API
    if (page === 'pfc') {
      savePFC(accessToken, headers, reqBodyPFC)
        .then(async (res) => {
          if (res.data) {
            actions.updatePoints({ step: 3 });
            actions.updateTransactions({
              orderNumber: res.data.data.orderNumber,
              balance: parseInt(res.data.data.portfolioPointBalance),
            });
            formMethods.reset();
          }
        })
        .catch((error) => {
          actions.updateNotification({
            message: error.message,
            status: 'error',
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else if (page === 'byot') {
      saveBYOT(accessToken, headers, reqBodyBYOT)
        .then(async (res) => {
          if (res.data) {
            actions.updatePoints({ step: 3 });
            actions.updateTransactions({
              orderNumber: res.data.data.orderNumber,
              balance: res.data.data.portfolioPointBalance,
            });
            formMethods.reset();
          }
        })
        .catch((error) => {
          actions.updateNotification({
            message: error.message,
            status: 'error',
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const onErrors = (errors) => {
    console.error(errors);
  };

  return (
    <FormProvider {...formMethods}>
      <form className="relative p-0" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
        <>
          {confirmHeader && <Title customStyles="confirmPage">{confirmHeader}</Title>}

          <DetailsWrapper page={page}>
            <SectionDetails className="border-bottom pb-6">
              {page === 'byot' && (
                <SectionTitle className="selected-transactions-heading">
                  {selectedTransactionsLabel || itemsLabel} {`(${added ? added.length : 0})`}
                </SectionTitle>
              )}

              {page === 'pfc' && (
                <SectionTitle className="selected-transactions-heading hidden sm:block">{itemsLabel}</SectionTitle>
              )}

              <DetailsInfo>
                {added.length > 0 ? (
                  added.map((item, index) => (
                    <div key={index}>
                      <p
                        className={`transaction-name${page === 'byot' ? ' !font-bold' : ''}${
                          page === 'pfc' ? ' hidden sm:block' : ''
                        }`}
                      >
                        {item.transactionDescription || item.transactionName}
                      </p>

                      <p
                        className={`amount-points${page === 'pfc' ? ' hidden sm:block' : ''}`}
                      >{`${amountLabel} ${formatToCurrency(
                        item.transactionAmount || item.dollarValue,
                        languageCode
                      )} | ${formatPoints(item.points, locale)} ${pts}`}</p>

                      {page === 'pfc' && (
                        <p className="credit-points flex sm:hidden">
                          <span>{`${formatToCurrency(
                            item.transactionAmount || item.dollarValue,
                            languageCode
                          )} ${creditLabel}`}</span>{' '}
                          <span>{`${formatPoints(item.points, locale)} ${pts}`}</span>
                        </p>
                      )}
                    </div>
                  ))
                ) : (
                  <p className="mb-0">{nTNotSelected}</p>
                )}
              </DetailsInfo>
            </SectionDetails>

            <SectionDetails>
              <SectionTitle>{emailAddressLabel}</SectionTitle>
              <DetailsInfo>
                {loading ? <Loader width="auto" /> : <p className="mb-0">{customer?.data?.contacts[0]?.email}</p>}
              </DetailsInfo>
            </SectionDetails>

            <SectionDetails account>
              <SectionTitle>{accountLabel}</SectionTitle>
              <DetailsInfo>
                {selectedCard && (
                  <CardOption cardImage={selectedCard.cardImage} confirmPage>
                    <p className="mb-0">{selectedCard.cardTitle}</p>
                  </CardOption>
                )}
              </DetailsInfo>
            </SectionDetails>

            {termsConditionsMessage && (
              <SectionDetails termsAndConditions>
                <SectionTitle>{termsConditionsLabel}</SectionTitle>
                <DetailsInfo>
                  <TermsContent termsBlob={termsConditionsMessage} />
                </DetailsInfo>
              </SectionDetails>
            )}

            <SectionDetails className="points-summary">
              <SectionTitle className="hidden sm:block">{pointsSummaryLabel}</SectionTitle>
              <DetailsInfo>
                <div className="flex justify-between">
                  {/* Points Balance */}
                  <p>{summaryPointsLabel}</p>
                  <p>
                    {balance ? formatPoints(balance, locale) : 0} {pts}
                  </p>
                </div>

                {/* Redemption Total */}
                <div className="flex justify-between">
                  <p className="!mb-1">{summaryRedeemedLabel}</p>
                  <p>
                    {redemptionAmount ? formatPoints(redemptionAmount, locale) : 0} {pts}
                  </p>
                </div>

                {/* Equals To */}
                <div className="flex justify-between mb-4">
                  {equalsToLabel && (
                    <p className="!text-xs">
                      {equalsToLabel} <span>{formatToCurrency(totalDollar, locale)}</span>
                    </p>
                  )}
                </div>
                {/* </div> */}

                <div className="flex justify-between">
                  <p className="font-bold">{summaryBalanceLabel || confirmNewBalanceLabel}</p>
                  {remainingPoints >= 0 ? (
                    <p className="font-bold">
                      {formatPoints(remainingPoints, locale)} {pts}
                    </p>
                  ) : (
                    <p className="font-bold">
                      {formatPoints(balance, locale)} {pts}
                    </p>
                  )}
                </div>

                <Button
                  type="submit"
                  className="w-full"
                  submitting={submitting}
                  buttonText={confirmButtonLabel}
                  ariaLabel={confirmButtonLabel}
                  style={{ marginTop: '2rem', width: '100%' }}
                >
                  {confirmButtonLabel}
                </Button>
              </DetailsInfo>
            </SectionDetails>
          </DetailsWrapper>
        </>
      </form>
    </FormProvider>
  );
}

ConfirmDetails.propTypes = { customData: PropTypes.object, page: PropTypes.string, languageCode: PropTypes.string };
