import { handlePreview } from '@agility/nextjs';
import { useRouter } from 'next/router';
import useHasMount from '../../hooks/utils/useHasMount';
import useReactGa from '../../hooks/services/useReactGA';
import { getPageTemplate } from '../../agility-pageTemplates';
import Helmet from '../Helmet';
import Loader from '../Loader/Loader';
import { convertToSlug } from '../../../helpers/utils';
import ErrorController from '../Errors/ErrorController';
import ZendeskScript from './ZendeskScript';
// set up handle preview
const isPreview = handlePreview();

function Layout(props) {
  const { page, sitemapNode, dynamicPageItem, notFound, pageTemplateName, languageCode } = props;

  const router = useRouter();
  const hasMounted = useHasMount();
  useReactGa();

  if (router.isFallback) {
    return <Loader text="Loading Page" />;
  }

  if (notFound === true) {
    return <ErrorController notFound={notFound} />;
  }

  if (!hasMounted) {
    return null;
  }

  const AgilityPageTemplate = getPageTemplate(pageTemplateName);

  if (dynamicPageItem?.seo?.metaDescription) {
    page.seo.metaDescription = dynamicPageItem.seo.metaDescription;
  }

  return (
    <>
      <Helmet
        title={sitemapNode?.title}
        description={page.seo.metaDescription}
        keywords={page.seo.metaKeywords}
        metaHTML={page.seo.metaHTML}
        languageCode={languageCode}
      />
      <div id="site-wrapper">
        {isPreview && <Loader text="Loading Preview Mode" />}
        {!isPreview && (
          <div id="site">
            <div id={convertToSlug(sitemapNode?.title)} className="flex flex-col min-h-screen">
              <AgilityPageTemplate {...props} />
            </div>
          </div>
        )}
      </div>
      <ZendeskScript />
    </>
  );
}

export default Layout;
