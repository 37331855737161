// State
import { useStateMachine } from 'little-state-machine';

import WrapperLayout from '../layouts/WrapperLayout';
import BankCardsSlider from '../common/Banners/BankCardsSlider';
import LinkComponent from '../common/Links/LinkComponent';

const ScotiabankCardsCarousel = ({ module, customData, languageCode }) => {
  // get module fields
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();
  if ((isLoggedIn && fields?.isLoggedIn === 'true') || (!isLoggedIn && fields?.isAnonymous === 'true')) {
    return (
      <section id="ScotiabankCards">
        <WrapperLayout className="global-content-wrapper" customStyles="defaultPadding">
          {fields.title && <h2 className="headline-medium block mb-3 md:mb-6 text-center">{fields.title}</h2>}
          {fields.message && <p className="sub-title text-center">{fields.message}</p>}

          <BankCardsSlider slides={customData.scotiabankcards} />
          {fields.cTALink?.href && fields.cTALinkLabel && (
            <LinkComponent
              href={fields.cTALink.href}
              target={fields.cTALink.target || '_self'}
              style={{ fontSize: '1rem', maxWidth: 'inherit' }}
              buttonLoadingText={fields.cTALinkLabelLoading || fields.cTALinkLabel}
              buttonStyle="primary-button"
              ariaLabel={fields.cTALink.text || fields.cTALinkLabel}
              className="mobile-w-full text-center md:pt-16 pt-10"
              locale={languageCode}
            >
              {fields.cTALinkLabel}
            </LinkComponent>
          )}
        </WrapperLayout>
      </section>
    );
  }
  return null;
};

ScotiabankCardsCarousel.getCustomInitialProps = async ({ agility, languageCode }) => {
  // set up api
  const api = agility;
  try {
    const contentItemList = await api.getContentList({
      referenceName: 'scotiabankcards',
      languageCode,
    });

    contentItemList.sort((a, b) => a.properties.itemOrder - b.properties.itemOrder);

    return {
      scotiabankcards: contentItemList,
    };
  } catch (error) {
    if (console) console.error(error);
  }
};

export default ScotiabankCardsCarousel;
