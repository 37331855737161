import PropTypes from 'prop-types';

// Components
import WrapperLayout from '../../layouts/WrapperLayout';
import OffersList from '../OffersList';

function LimitedTimeOffers({
  customData,
  languageCode,
  limitedTimeOffersLabel,
  limitedTimeOffersList,
  offerLoadedLabel,
  loadOfferLabel,
  isSlider,
}) {
  return (
    <WrapperLayout className="global-content-wrapper" isGray customStyles="offersPage">
      <OffersList
        customData={customData}
        languageCode={languageCode}
        filteredList={limitedTimeOffersList}
        title={limitedTimeOffersLabel}
        loadOfferLabel={loadOfferLabel}
        offerLoadedLabel={offerLoadedLabel}
        isSlider={isSlider}
        isLimitedTimeOffer
        listName="Limited time offers"
        sectionTitle="Limited time offers"
      />
    </WrapperLayout>
  );
}

LimitedTimeOffers.propTypes = {
  customData: PropTypes.object,
  languageCode: PropTypes.string,
  limitedTimeOffersLabel: PropTypes.string,
  limitedTimeOffersList: PropTypes.array,
  offerLoadedLabel: PropTypes.string,
  loadOfferLabel: PropTypes.string,
  isSlider: PropTypes.bool,
};

export default LimitedTimeOffers;
