import PropTypes from 'prop-types';
import Image from 'next/image';
import router from 'next/router';

import Button from '../Button/Button';

import { ErrorContentsWrapper, ErrorCTA, ErrorPageDescription, ErrorPageHeading, ErrorPageIcon } from './ErrorPageStyles';

export default function Error404({ fields }) {
  const { title, message, homeLinkLabel, homeLink, fAQLinkLabel, fAQLink, image } = fields;

  return (
    <ErrorContentsWrapper>
      {image && (
        <ErrorPageIcon>
          <Image
            src={image.url}
            alt={image.label || ''}
            width={182}
            height={122}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </ErrorPageIcon>
      )}

      {title && <ErrorPageHeading>{title}</ErrorPageHeading>}
      {message && <ErrorPageDescription>{message}</ErrorPageDescription>}

      <ErrorCTA>
        {fAQLink && (
          <Button type="button" className=" error-page-btn error-faq-btn" onClick={() => router.push(fAQLink.href)}>
            {fAQLinkLabel}
          </Button>
        )}
        {homeLink && (
          <Button
            type="button"
            buttonStyle="secondary-button-outline-purple"
            className="error-page-btn transparent-bg error-home-btn"
            onClick={() => router.push(homeLink.href)}
          >
            {homeLinkLabel}
          </Button>
        )}
      </ErrorCTA>
    </ErrorContentsWrapper>
  );
}

Error404.propTypes = { fields: PropTypes.object };
