/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-nested-ternary */

import PropTypes from 'prop-types';
import Link from 'next/link';

import { HeaderColumn } from '../HeaderStyles';

import { handleKeyDown } from '../../../../../helpers/utils';
import { enableScrolling } from '../../../../../helpers/behavourHelper';

export default function HeaderNavigation({ isLoggedIn, mainLinks, sitemapNode, anonymousMainLinks, languageCode }) {
  const handleClick = () => {
    if (!isLoggedIn) {
      document.body.classList.remove('prevent-mobile-scroll');
    }

    enableScrolling();
  };

  return (
    <HeaderColumn className="flex-grow-0 tabletHide">
      {isLoggedIn
        ? mainLinks.map((navitem, index) => (
            <Link
              href={navitem.fields.page.href}
              as={navitem.fields.page.href}
              key={index}
              locale={languageCode}
              className={`${navitem.fields.page.href === sitemapNode.path ? 'active relative' : 'relative'}`}
              aria-label={navitem.fields.title}
              role="link"
              onClick={handleClick}
              onKeyDown={(e) => {
                handleKeyDown(e, handleClick);
              }}
            >
              <>
                {navitem.fields.title}
                {navitem.fields.page.href === '/rewards' && <div id="portal-rewards1" />}
                {navitem.fields.page.href.includes('/offers') && <div id="portal-offers1" />}
              </>
            </Link>
          ))
        : anonymousMainLinks.map((navitem, index) => (
            <Link
              href={navitem.fields.page.href}
              as={navitem.fields.page.href}
              key={index}
              locale={languageCode}
              className={`${navitem.fields.page.href === sitemapNode.path ? 'active' : ''}`}
              aria-label={navitem.fields.title}
              onClick={handleClick}
              role="link"
              onKeyDown={(e) => {
                handleKeyDown(e, handleClick);
              }}
            >
              {navitem.fields.title}
            </Link>
          ))}
    </HeaderColumn>
  );
}

HeaderNavigation.propTypes = {
  isLoggedIn: PropTypes.bool,
  mainLinks: PropTypes.array,
  sitemapNode: PropTypes.object,
  anonymousMainLinks: PropTypes.array,
  languageCode: PropTypes.string,
};
