/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import Image from 'next/image';
import { renderHTML } from '@agility/nextjs';

// Styles
import { ContentCol, BackgroundHeroPFC } from '../../PointsShopStyles';

function HowItWorksPFC({ howItWorksMessage, howItWorksTitle, page, image, step }) {
  if (page !== 'pfc' || !howItWorksTitle || !howItWorksMessage) return null;

  return (
    <ContentCol className="sm:pr-8 relative">
      <div>
        {howItWorksTitle && <h2>{howItWorksTitle}</h2>}
        {howItWorksMessage && <div dangerouslySetInnerHTML={renderHTML(howItWorksMessage)} />}
      </div>
      {step === 1 && (
        <BackgroundHeroPFC className="hidden sm:block w-full">
          <div className="img-container">
            <Image
              src={`${image ? image.url : '/assets/placeholder.svg'}`}
              alt=""
              loading="eager"
              role="presentation"
              fill
              sizes="100vw"
              style={{
                objectFit: 'cover',
                objectPosition: 'bottom',
              }}
            />
          </div>
        </BackgroundHeroPFC>
      )}
    </ContentCol>
  );
}

HowItWorksPFC.propTypes = {
  howItWorksMessage: PropTypes.string,
  howItWorksTitle: PropTypes.string,
  page: PropTypes.string,
  image: PropTypes.object,
  step: PropTypes.number,
};

export default HowItWorksPFC;
