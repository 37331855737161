import styled, { css } from 'styled-components';

export const CarouselWrapper = styled.div`
  position: relative;
  ${(props) =>
    props.offersNum <= 2 &&
    props.isGiftCards &&
    css`
      @media screen and (max-width: 375px) {
        margin: 0 -1.7rem 0 -0.3rem;
      }
    `}
  .slides {
    position: inherit;

    ${(props) =>
      props.offersNum <= 2 &&
      css`
        @media screen and (max-width: 640px) {
          .card-item {
            padding-right: 0;
          }
        }
      `}

    ${(props) =>
      props.offersNum <= 5 &&
      props.isGiftCards &&
      css`
        @media screen and (min-width: 991px) {
          display: flex;
        }
      `}



    ${(props) =>
      props.offersNum <= 2 &&
      props.isGiftCards &&
      css`
        @media screen and (max-width: 640px) {
          display: flex;
        }
      `}
  }

  @media screen and (min-width: 992px) {
    .flex-slides {
      display: flex;
    }
  }
  @media screen and (min-width: 641px) {
    .flex-slides-tablet {
      display: flex;
    }
  }

  .slick-list {
    box-sizing: initial;

    height: auto !important;
    ${(props) =>
      props.offersNum > 0 &&
      css`
        overflow: inherit;
      `};

    .slick-track {
      margin: inherit;
    }

    @media screen and (min-width: 991px) {
      ${(props) =>
        props.offersNum > 3 &&
        !props.isGiftCards &&
        css`
          overflow: hidden;
          max-width: 85%;
          margin-right: auto;
          margin-left: auto;
        `};

      ${(props) =>
        props.offersNum > 5 &&
        props.isGiftCards &&
        css`
          overflow: hidden;
          max-width: 85%;
          margin-right: auto;
          margin-left: auto;
        `};
    }

    @media screen and (max-width: 640px) {
      ${(props) =>
        !props.isGiftCards &&
        css`
          .slick-slide {
            width: 100%;
          }
        `}
  }
`;

export const GridWrapper = styled.div`
  box-sizing: initial;
  .grid-row {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.75rem;
    @media screen and (min-width: 641px) and (max-width: 1023px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (min-width: 1024px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-gap: 1.875rem;
    }
  }

  .grid-item {
    -ms-flex: auto;
    position: relative;
    box-sizing: border-box;
    flex-basis: 100%;
    @media screen and (min-width: 641px) {
    }

    a {
      margin: 0;
    }
  }

  .gc-grid-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    @media screen and (min-width: 641px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 2rem;
      .gc-grid-row .gc-grid-item {
        flex-basis: 25%;
      }
    }
    @media screen and (min-width: 991px) {
      grid-gap: 2.5rem;
    }
  }
  .gc-grid-row .gc-grid-item {
    -ms-flex: auto;
    position: relative;
    box-sizing: border-box;
    flex-basis: 50%;
    a {
      margin: 0;
    }
  }
`;
export const FeatureCarouselWrapper = styled.div`
  overflow: hidden;

  .slick-list {
    box-sizing: initial;
    height: auto !important;

    @media screen and (min-width: 768px) {
      ${(props) =>
        props.bannersNum > 2 &&
        css`
          overflow: inherit;
        `};
    }
  }

  @media screen and (min-width: 768px) {
    .slick-slide {
      &:first-of-type {
        ${(props) =>
          props.bannersNum > 2 &&
          css`
            width: 100% !important;
          `};
      }
      &:nth-of-type(2),
      &:nth-of-type(3) {
        ${(props) =>
          props.bannersNum > 2 &&
          css`
            width: 50% !important;
          `};
      }
    }
  }
`;

export const FeatureCarouselTitle = styled.h2`
  margin-top: 2rem;
  margin-bottom: 2rem;

  span {
    color: ${(props) => props.theme.lightGrayColor4};
    font-weight: normal;
  }

  ${(props) =>
    props.isLightBlack &&
    css`
      color: ${props.theme.whiteColor};
    `}
`;
export const Section = styled.div`
  ol,
  ul {
    padding-left: 1rem;

    li {
      font-size: ${(props) => props.theme.headingMedium};
      line-height: 1.35;
    }
  }
`;
export const SectionTitle = styled.h2`
  margin-bottom: 1.5rem;
  line-height: ${(props) => props.theme.lineHeight3};
  span {
    color: ${(props) => props.theme.lightGrayColor4};
    font-weight: normal;
  }
  ${(props) =>
    props.isSlider &&
    css`
      margin-bottom: 2rem;
    `}
  ${(props) =>
    props.isGrid &&
    css`
      margin-top: 4rem;
      margin-bottom: 2.5rem;
      @media screen and (min-width: 641px) and (max-width: 1023px) {
        margin-top: 4rem;
        margin-bottom: 1.875rem;
      }
      @media screen and (max-width: 640px) {
        margin-top: 3.5rem;
        margin-bottom: 1.313rem;
      }
    `}

  ${(props) =>
    props.isLightBlack &&
    css`
      color: ${props.theme.whiteColor};
    `}
`;

export const EventSection = styled.div`
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media screen and (min-width: 641px) and (max-width: 1023px) {
    padding-top: 2.5rem;
    padding-bottom: 3.5rem;
  }
  @media screen and (max-width: 640px) {
    padding-top: 2rem;
    padding-bottom: 2.5rem;
  }
`;

export const EventSectionTitle = styled.h2`
  margin-bottom: 1.5rem;
  @media screen and (max-width: 1023px) {
    margin-bottom: 2rem;
  }
  span {
    color: ${(props) => props.theme.lightGrayColor4};
    font-weight: normal;
  }
`;

export const BannerItem = styled.div`
  ${(props) =>
    props.bannersNum === 2 &&
    css`
      &:first-of-type {
        @media screen and (max-width: 640px) {
          margin-bottom: 1.5rem;
        }
      }
    `};
  a {
    text-decoration: none;
  }
  a:focus .banner-content-wrapper {
    outline: 2px solid ${(props) => props.theme.uiPurple};
    outline-offset: 2px;
  }
  .banner-image {
    flex: 0 0 58.3333%;
    width: 100%;
    height: 175px;

    @media screen and (min-width: 641px) {
      ${(props) =>
        props.bannersNum !== 2 &&
        css`
          flex: 0 0 44.44%;
        `};
      ${(props) =>
        props.bannersNum !== 2 &&
        css`
          height: 300px;
        `};
    }

    @media screen and (min-width: 768px) {
      flex: auto;
      ${(props) =>
        props.bannersNum > 1 &&
        css`
          height: 258px;
        `};
    }
  }

  .banner-content-wrapper {
    position: relative;
    width: 100%;

    @media screen and (max-width: 640px) {
      flex-direction: column;
      ${(props) => props.bannersNum === 1 && 'height: 380px'};
    }

    @media screen and (min-width: 600px) {
      width: auto;
    }
  }

  &#banner-0 {
    .banner-content-wrapper {
      margin-left: 3px !important;
    }

    .banner-image {
      @media screen and (min-width: 768px) {
        ${(props) =>
          props.bannersNum > 2 &&
          css`
            height: 321px;
          `};
      }
    }
  }

  &#banner-1,
  &#banner-2 {
    @media screen and (min-width: 768px) {
      ${(props) =>
        props.bannersNum === 3 &&
        css`
          flex: 0 0 50%;
        `};
      ${(props) =>
        props.bannersNum === 3 &&
        css`
          margin-bottom: 0;
        `};
    }
  }

  &#banner-0,
  &#banner-2 {
    @media screen and (min-width: 768px) {
      .banner-content-wrapper {
        ${(props) =>
          props.bannersNum === 3 &&
          css`
            margin-right: 3px !important;
          `};
      }
    }
  }

  &#banner-1 {
    .banner-content-wrapper {
      ${(props) =>
        props.bannersNum > 1 &&
        props.bannersNum <= 3 &&
        css`
          margin-left: 3px !important;
        `};

      @media screen and (min-width: 641px) {
        ${(props) =>
          props.bannersNum === 2 &&
          css`
            margin: 3px 3px 3px 12px !important;
          `};
      }
    }
  }

  .banner-content {
    width: 100%;
    height: 100%;

    @media screen and (min-width: 768px) {
      flex: 0 0 40%;
      padding: ${(props) => (props.bannersNum === 2 ? '1rem' : '1.25rem 2rem')};
    }

    .primary-button {
      font-weight: bold;
    }

    .banner-content-inner {
      padding: 1rem 20% 1rem 1rem;

      @media screen and (min-width: 768px) {
        padding: ${(props) => (props.bannersNum === 2 ? '0' : '1rem')};
        ${(props) => (props.bannersNum > 1 ? 'height: 175px' : '')};
      }
    }

    .eyebrow {
      font-size: 0.6rem;
      margin-bottom: 0.5rem;

      @media screen and (min-width: 768px) {
        font-size: ${(props) => props.theme.fontBody3};
      }
    }

    .banner-learnmore {
      text-decoration: underline;
      font-weight: bold;
    }

    .desktop-learnmore {
      @media screen and (max-width: 640px) {
        display: none;
      }
      @media screen and (min-width: 641px) {
        display: ${(props) => (props.bannersNum === 1 ? 'block' : 'none')};
      }
    }

    .mobile-learnmore {
      display: none;
      position: absolute;
      left: 1rem;
      bottom: 0.8rem;
      z-index: 1;
      text-decoration: underline;
      @media screen and (max-width: 640px) {
        display: block;
        font-size: ${(props) => props.theme.fontBody3};
      }
      @media screen and (min-width: 641px) {
        display: ${(props) => (props.bannersNum === 1 ? 'none' : 'block')};
      }
    }

    h3 {
      margin-bottom: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-right: 1rem;
    }

    p {
      color: ${(props) => props.theme.whiteColor};
      font-size: ${(props) => props.theme.fontBody3};
      margin-bottom: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-right: 1rem;
    }

    .cta-text {
      font-size: ${(props) => props.theme.fontLegal};
      overflow: hidden;
      _white-space: nowrap;
      text-overflow: ellipsis;
      @media screen and (min-width: 768px) {
        font-size: ${(props) => props.theme.fontBody2};
      }
    }
  }
`;
export const BannerContents = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.uiBlack};
  color: ${(props) => props.theme.whiteColor};
  overflow: hidden;
  justify-content: flex-start;
  ${(props) => (props.bannersNum > 1 ? 'margin: 3px 12px' : 'margin: 3px')};
  ${(props) =>
    props.bannersNum > 1 &&
    props.bannersNum < 3 &&
    css`
      flex-direction: column;
    `};
  ${(props) =>
    props.bannersNum > 1 &&
    props.bannersNum < 3 &&
    css`
      @media screen and (max-width: 767px) {
        height: 450px;
      }
    `};

  @media screen and (max-width: 640px) {
    ${(props) =>
      props.bannersNum === 2 &&
      css`
        margin-right: 3px;
      `};
    ${(props) =>
      props.bannersNum === 2 &&
      css`
        margin-left: 3px;
      `};
  }

  @media screen and (min-width: 641px) {
    ${(props) =>
      props.bannersNum === 2 &&
      css`
        margin-bottom: 0;
      `};
  }

  @media screen and (min-width: 768px) {
    ${(props) =>
      props.bannersNum === 2
        ? css`
        420px;
      `
        : css`auto`};
  }

  .mobile-learnmore,
  .load-text {
    position: relative;

    &:hover {
      &:after {
        width: 0;
      }
    }

    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid ${(props) => props.theme.whiteColor};
      -webkit-transition: width 0.3s;
      transition: width 0.3s;
    }
  }
`;
export const StyledArrow = styled.div`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.lightGrayColor4};
  width: 44px;
  visibility: hidden !important;
  height: 44px;
  transition: all 0.2s ease-in;

  &:before,
  &:after {
    content: '';
  }

  &:hover {
    background-color: ${(props) => props.theme.lightGrayColor3};
  }

  &.next-arrow,
  &.prev-arrow {
    position: absolute;
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (min-width: 991px) {
      visibility: visible !important;
    }
  }

  &.next-arrow {
    background-image: url(/assets/carousel-arrow-right.svg);
    right: 0;
  }
  &.prev-arrow {
    background-image: url(/assets/carousel-arrow-left.svg);
    left: 0;
  }
`;

export const CardCarouselWrapper = styled.div`
  position: relative;

  ${(props) =>
    props.offersNum <= 3 &&
    css`
      @media screen and (min-width: 991px) {
        .cards-slides {
          display: flex;
          .card-item {
            margin-right: 1.875rem;
            display: inline-block;
          }
        }
      }
    `}

  ${(props) =>
    props.offersNum <= 2 &&
    css`
      @media screen and (min-width: 641px) {
        .cards-slides {
          display: flex;
        }
      }
      @media screen and (max-width: 640px) {
        .cards-slides {
          display: block;
          .card-content-wrapper {
            margin-right: 0;
          }
          .card-contents {
            width: 100%;
            max-width: 19.5rem;
            margin-right: auto;
            margin-left: auto;
          }
        }
      }
    `}

    .slides {
    position: inherit;
  }

  .slick-list {
    box-sizing: initial;

    height: auto !important;
    ${(props) =>
      props.offersNum > 0 &&
      css`
        overflow: inherit;
      `};

    .slick-track {
      margin: inherit;
      display: flex;
      .slick-slide {
        margin-left: -0.25rem;
      }
    }

    @media screen and (min-width: 991px) {
      ${(props) =>
        props.offersNum > 3 &&
        css`
          overflow: hidden;
          max-width: 85%;
          margin-right: auto;
          margin-left: auto;
        `};
    }
  }
`;
