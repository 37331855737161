import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Global state
import { useStateMachine } from 'little-state-machine';

// Components
import RegisterStep1FormLayoutGK from '../../layouts/RegisterStep1FormLayoutGK';
import FormPasswordInput from '../fields/FormPasswordInput';
import FormEmailInput from '../fields/FormEmailInput';
import ConfirmInput from '../fields/ConfirmInput';
import FormCheckbox from '../fields/FormCheckbox';
import GKActivationCheckboxForm from './GKActivationCheckboxForm';
import CaptchaDisclaimer from '../reCaptcha/CaptchaDisclaimer';

// Helpers
import { displayFieldErrorMessage } from '../../../helpers/formHelpers';
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';
import { camelCaseToSentence } from '../../../helpers/stringHelpers';

// Styles
import { AgreeField } from '../FormStyles';

const Step1 = (props) => {
  const {
    confirmPasswordLabel,
    passwordValidationHeader,
    emailLabel,
    passwordLabel,
    passwordRules,
    errorsList,
    termsCheckboxLinks,
    hideLabel,
    showLabel,
  } = props;

  const { state } = useStateMachine();
  const [showPasswordRules, setShowPasswordRules] = useState(false);

  const gaEvent = (e) => {
    const dataLayer = {
      event: 'sign_up_form_field',
      category: 'Registration Events',
      action: 'Form Field Click',
      label: camelCaseToSentence(e.target.name),
      sign_up_step: 'Step 1',
      sign_up_form_field: camelCaseToSentence(e.target.name),
    };
    handleGAEventsBase(dataLayer);
  };

  const onHandleEvents = (e) => {
    if (e.target.name === 'password' || e.target.name === 'confirmPassword') {
      setShowPasswordRules(true);
    } else {
      setShowPasswordRules(false);
    }
  };
  const onFocus = (e) => {
    onHandleEvents(e);
    gaEvent(e);
  };
  useEffect(() => {
    setShowPasswordRules(false);
  }, [setShowPasswordRules]);

  const passWordErrorMessage = passwordRules
    ?.filter((item) => item.validationCode === 'IncludeChars')
    ?.map((item) => item.validationLabel);

  return (
    <RegisterStep1FormLayoutGK {...props}>
      <FormEmailInput
        onFocus={onFocus}
        onClick={onHandleEvents}
        onKeyDown={onHandleEvents}
        id="email"
        fieldType="email"
        name="email"
        label={emailLabel}
        defaultVal={state.user.email}
        maxLength={128}
        errorMessage={displayFieldErrorMessage(errorsList, 'Contact.Email.Invalid')}
        rules={{
          required: {
            message: displayFieldErrorMessage(errorsList, 'Contact.Email.Required'),
            value: true,
          },
        }}
      />

      <div className="mb-8" />

      <FormPasswordInput
        onFocus={onFocus}
        onClick={onHandleEvents}
        onKeyDown={onHandleEvents}
        id="password"
        pwHeader={passwordValidationHeader}
        passwordRules={passwordRules}
        showPasswordRules={showPasswordRules}
        fieldType="password"
        name="password"
        label={passwordLabel}
        hideLabel={hideLabel}
        showLabel={showLabel}
        defaultVal={state.user.password}
        errorMessage={displayFieldErrorMessage(errorsList, 'Customer.Password.Invalid')}
        maxLength={64}
        rules={{
          required: {
            message: displayFieldErrorMessage(errorsList, 'Customer.Password.Required'),
            value: true,
          },
          minLength: {
            message: passwordRules.filter((item) => item.validationCode === 'IncludeChars').map((item) => item.validationLabel),
            value: 8,
          },
        }}
      />
      <div className="mb-8" />
      <ConfirmInput
        onFocus={onFocus}
        onClick={onHandleEvents}
        onKeyDown={onHandleEvents}
        id="confirmPassword"
        fieldType="password"
        name="confirmPassword"
        label={confirmPasswordLabel}
        hideLabel={hideLabel}
        showLabel={showLabel}
        fieldWatch="password"
        defaultVal={state.user.confirmPassword}
        maxLength={64}
        errorMessage={displayFieldErrorMessage(errorsList, 'Customer.Password.ConfirmNotMatch')}
        rules={{
          required: {
            message: displayFieldErrorMessage(errorsList, 'Customer.Password.ConfirmRequired'),
            value: true,
          },
          minLength: {
            message: passWordErrorMessage,
            value: 8,
          },
        }}
      />

      <div className="mb-8" />

      <AgreeField>
        <FormCheckbox
          id="termsCheckbox"
          onClick={gaEvent}
          fieldType="checkbox"
          label=""
          extraText={termsCheckboxLinks}
          name="termsCheckbox"
          rules={{
            required: {
              message: displayFieldErrorMessage(errorsList, 'Customer.Terms.Required'),
              value: true,
            },
          }}
        />

        <GKActivationCheckboxForm {...props} gaFlow="registerGK" />

        <div className="mt-8">
          <CaptchaDisclaimer />
        </div>
      </AgreeField>
    </RegisterStep1FormLayoutGK>
  );
};

export default Step1;

Step1.propTypes = {
  confirmPasswordLabel: PropTypes.string,
  passwordValidationHeader: PropTypes.string,
  emailLabel: PropTypes.string,
  passwordLabel: PropTypes.string,
  passwordRules: PropTypes.array,
  errorsList: PropTypes.array,
  termsCheckboxLabel: PropTypes.string,
  termsCheckboxLinks: PropTypes.string,
  hideLabel: PropTypes.string,
  showLabel: PropTypes.string,
};
