import Image from 'next/image';
import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

import { ListWrapper, TileContainer } from './PartnerTileStyles';

// Helpers
import { deriveListByProvince } from '../../../helpers/utils';

function PartnerTileList({ data }) {
  const {
    state: { location },
  } = useStateMachine();
  //  console.log(data);
  if (data.length && location?.province) {
    const rawPartners = data.map((m) => ({
      code: m.fields.code,
      name: m.fields.title,
      image: m.fields.logoSolidBG,
      provinces: m.fields.provinces_TextField || '',
    }));
    //  console.log(rawPartners);
    const partners = deriveListByProvince(rawPartners, location?.province);

    if (partners && partners.length) {
      return (
        <ListWrapper>
          {partners.map((item, index) => (
            <TileContainer key={index}>
              <Image
                className="rounded-full overflow-hidden"
                src={item.image?.url}
                alt={item.name}
                loading="lazy"
                fill
                sizes="100vw"
                style={{
                  objectFit: 'contain',
                  objectPosition: '50% 50%',
                }}
              />
            </TileContainer>
          ))}
        </ListWrapper>
      );
    }
  }
  return null;
}
PartnerTileList.propTypes = { data: PropTypes.array };

export default PartnerTileList;
