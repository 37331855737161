import PropTypes from 'prop-types';

import { useRouter } from 'next/router';
import { useStateMachine } from 'little-state-machine';
import Image from 'next/image';

import { updateOrder } from '../../../store/actions';

import { TableRowItem, CellInline, Row } from '../../common/Table/TableStyles';
import { OrderButton } from '../OrderStyles';
import { formatDate } from '../../../helpers/dateHelpers';

export default function OrderRow({ award, item, tableHeader, orderDetailsLink, languageCode }) {
  const router = useRouter();
  const { merchant, items, orderDate, orderNumber } = item;

  const { actions } = useStateMachine({ updateOrder });

  const handleClick = () => {
    if (item) {
      actions.updateOrder({ selectedOrder: item });
    }

    router.push(orderDetailsLink);
  };

  if (items) {
    return (
      <OrderButton
        role="row"
        type="button"
        onClick={handleClick}
        aria-label={`${(orderDate && formatDate(orderDate, 'MMM, DD', languageCode)) || ''}, ${merchant && merchant}, ${
          tableHeader[2]
        }: ${orderNumber}, ${items ? tableHeader[3] : ''}: ${items?.length};`}
      >
        <Row className="row-list">
          <TableRowItem role="cell">
            <p>{(orderDate && formatDate(orderDate, 'MMM, DD', languageCode)) || null}</p>
          </TableRowItem>
          <TableRowItem role="cell" width45>
            <CellInline>
              {award && award.length && (
                <>
                  <figure className="flex-none">
                    <Image
                      src={award[0]?.image?.url}
                      alt=""
                      height={24}
                      width={24}
                      aria-hidden="true"
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                  </figure>
                  <p>{(award[0]?.name && award[0]?.name) || null}</p>
                </>
              )}
            </CellInline>
          </TableRowItem>

          <TableRowItem role="cell" width20 center>
            <p>{orderNumber || null}</p>
          </TableRowItem>

          <TableRowItem role="cell" width15 center>
            <p>{(items && items?.length) || null}</p>
          </TableRowItem>
        </Row>
      </OrderButton>
    );
  }
  return null;
}
OrderRow.propTypes = {
  award: PropTypes.array,
  item: PropTypes.object,
  tableHeader: PropTypes.array,
  orderDetailsLink: PropTypes.string,
  languageCode: PropTypes.string,
};
