import Image from 'next/image';

import { StyledListNOC, ImageCircleNOC } from './ContentGridStyles';

export default function ContentGrid4Columns({ data }) {
  if (data) {
    return (
      <StyledListNOC>
        {data.map((item, index) => (
          <li key={index} className="list-none  text-center">
            <ImageCircleNOC className="mx-auto category-circle-tile">
              <Image
                className="w-full h-full object-cover"
                src={item.fields.image.url}
                alt=""
                width={80}
                height={80}
                quality={75}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </ImageCircleNOC>
            <h3 className="headline-extra-small">{item.fields.title}</h3>
          </li>
        ))}
      </StyledListNOC>
    );
  }
  return null;
}
