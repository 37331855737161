import { useQuery } from 'react-query';
import { getBYOTTransactions } from '../../../../pages/api/services';
import { getTimeZone } from '../../../../helpers/dateHelpers';

export default function useBYOT(accessToken, accountuniqueId, fromDate, toDate) {
  const { isLoading, error, data } = useQuery(['fetchBYOT', accessToken, accountuniqueId, fromDate, toDate], async () => {
    const res = await getBYOTTransactions(
      accessToken,
      accountuniqueId,
      `${fromDate}T00:00:00${getTimeZone()}`,
      `${toDate}T00:00:00${getTimeZone()}`
    );

    return res.data.data;
  });

  return { isLoading, error, data };
}
