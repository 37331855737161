import PropTypes from 'prop-types';

import Image from 'next/image';

function BrandLogo({ url, title }) {
  return (
    <Image
      className="rounded-full overflow-hidden"
      src={url}
      alt={title}
      loading="lazy"
      fill
      sizes="100vw"
      style={{
        objectFit: 'contain',
        objectPosition: '50% 50%',
      }}
    />
  );
}

BrandLogo.propTypes = { url: PropTypes.string, title: PropTypes.string };

export default BrandLogo;
