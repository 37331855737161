/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

// LSM
import { useStateMachine } from 'little-state-machine';
import updatePoints from '../../../../../store/actions/updatePointsAction';

// Helpers
import { calcPointsNeeded, calcPointsTotal, formatPoints } from '../../../../../helpers/pointsHelpers';
import { formatToCurrency } from '../../../../../helpers/utils';

// Components
import { Row, TableRowItem, TableRowPointsItem } from '../../../../common/Table/TableStyles';
import PointsRowWarning from './PointsRowWarning'; // V2

// Styles
import { Wrapper, MobileTransactionsList } from '../../PointsShopStyles';

function MobilePointsListRow({
  name,
  fieldType,
  rules,
  isDisabled,
  transaction,
  languageCode,
  customData: { partialRedemptionLabel },
  warning,
  ignoreError,
}) {
  const {
    state: {
      points: { added, lastAdded },
      transactions: { balance },
      language: { locale },
    },
  } = useStateMachine();

  const { id, transactionDescription, transactionAmount, points } = transaction;
  const transactionName = transaction.name;
  const { actions } = useStateMachine({ updatePoints });
  const [checkbox, setCheckbox] = useState(false);

  const total = calcPointsTotal(added);
  const remainingPoints = balance - total;
  const notEnoughPoints = remainingPoints < 0;

  const { control, setValue } = useFormContext();

  useEffect(() => {
    setValue(name, checkbox, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [checkbox]);

  const checkboxData = {
    id,
    name,
    transactionName,
    transactionAmount,
    points,
  };

  const disableInput = (checked) => {
    const pointsNeeded = calcPointsNeeded(added, balance);
    const partialRedemption = balance - pointsNeeded < balance;

    if (isDisabled || balance === 0 || (partialRedemption && !checked) || (balance - total === 0 && !checked)) return true;

    return false;
  };

  const handleChange = (e) => {
    setCheckbox(e.target.checked);
    setValue(name, e.target.checked);

    if (e.target.checked) {
      actions.updatePoints({ added: [...added, checkboxData], lastAdded: id });
    } else {
      let hardCopy = [...added];
      hardCopy = hardCopy.filter((cartItem) => cartItem.name !== name);

      actions.updatePoints({ added: hardCopy });
    }
  };

  return (
    <>
      <Wrapper role="rowgroup">
        <Controller
          control={control}
          name={name}
          rules={{
            ...rules,
            validate: async (value) => {
              if (notEnoughPoints && value) {
                return partialRedemptionLabel;
              }
            },
          }}
          // eslint-disable-next-line no-shadow
          render={({ field: { onBlur, name, value }, fieldState: { error } }) => (
            <MobileTransactionsList>
              <Row
                id={`transaction-${id}`}
                role="row"
                className="row-list"
                error={!ignoreError && !warning && error}
                warning={!ignoreError && warning && error}
              >
                <TableRowItem center role="cell" className="table-checkbox">
                  <input
                    name={name}
                    className="points-checkbox"
                    id={name}
                    type={fieldType}
                    aria-invalid={!!error}
                    role="checkbox"
                    aria-checked={!!value}
                    aria-labelledby={`option-${name}`}
                    aria-disabled={disableInput(!!value)}
                    disabled={disableInput(!!value)}
                    onBlur={onBlur}
                    checked={!!value}
                    onChange={(e) => handleChange(e)}
                  />
                </TableRowItem>
                <TableRowItem className="transaction-cell" role="cell" disable={disableInput(!!value)}>
                  <label
                    id={`option-${name}`}
                    htmlFor={name}
                    style={{ margin: 0 }}
                    className="pfc-transac-name font-bold block"
                    aria-disabled={disableInput(!!value)}
                  >
                    {transactionDescription}
                  </label>
                  <p>{formatToCurrency(transactionAmount, languageCode)}</p>
                </TableRowItem>
                <TableRowPointsItem role="cell" className="mobile-tranac-amount">
                  {formatPoints(points, locale)}
                </TableRowPointsItem>
              </Row>

              {error && id === lastAdded && <PointsRowWarning warningLabel={partialRedemptionLabel} error={error} />}
            </MobileTransactionsList>
          )}
        />
      </Wrapper>
    </>
  );
}

export default MobilePointsListRow;

MobilePointsListRow.propTypes = {
  name: PropTypes.string,
  fieldType: PropTypes.string,
  rules: PropTypes.object,
  isDisabled: PropTypes.bool,
  transaction: PropTypes.object,
  languageCode: PropTypes.string,
  warning: PropTypes.bool,
  ignoreError: PropTypes.bool,
  customData: PropTypes.object,
};
