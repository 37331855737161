import Image from 'next/image';
import useDragDetection from '../../hooks/utils/useDragDetection';
import { CardItem, Brand, CardHeader, CardImage, LogoBadge, CardBody, CardContents, CardTitle, CardSubitle } from './CardStyles';

import { handleGAEvents, getRewardTileClickMapping, handleGaEcommImpression } from '../../../helpers/handleGoogleAnalyticsHelper';

export default function RewardCard({ id, content, offersNum, toggle, sectionTitle, isGrid }) {
  const { handleMouseDown, dragging } = useDragDetection();

  const handleChildClick = (e) => {
    if (dragging) {
      e.preventDefault();
      return;
    }
    toggle(content?.code);
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      toggle(content?.code);
    }
  };

  const handleCardItemClick = () => {
    handleGAEvents(content?.brandInfo?.fields?.title, 'Reward', 'Reward Impression Interaction', 'redeem_home_reward_click');
    handleGaEcommImpression('rewards_select_item', getRewardTileClickMapping(content, id, sectionTitle));
  };

  if (!content) return null;

  return (
    <CardItem
      id={id}
      offersNum={offersNum}
      className="flex card-item card-content-wrapper"
      onClick={() => {
        handleCardItemClick();
      }}
      isGrid={isGrid}
    >
      <CardContents
        tabIndex="0"
        role="link"
        aria-label={content?.brandInfo?.fields?.title}
        offersNum={offersNum}
        onMouseDownCapture={handleMouseDown}
        onClickCapture={handleChildClick}
        onKeyDown={handleKeyDown}
        className="card-contents cursor-pointer"
      >
        <CardHeader offersNum={offersNum} className="w-full">
          <LogoBadge className="rounded-full overflow-hidden">
            <Image
              key={content?.brandInfo?.fields?.code}
              src={content?.brandInfo?.fields?.logoTransparentBG?.url || '/assets/no-image.png'}
              alt={content?.brandInfo?.fields?.title}
              title={content?.brandInfo?.fields?.title}
              quality={25}
              fill
              sizes="100vw"
              style={{
                objectFit: 'contain',
              }}
            />
          </LogoBadge>
          <CardImage className="relative w-full h-full">
            <Image
              src={content?.webImage?.url || '/assets/no-image.png'}
              alt=""
              loading="eager"
              fill
              sizes="(max-width: 1400px) 800px"
              style={{
                objectFit: 'cover',
                objectPosition: '50% 50%',
              }}
            />
          </CardImage>
        </CardHeader>
        <CardBody>
          <Brand className="font-bold">{content?.brandInfo?.fields?.title}</Brand>

          {content?.title && <CardTitle className="font-bold">{content?.title}</CardTitle>}
          {content?.pointsConversionLabel && <CardSubitle>{content?.pointsConversionLabel}</CardSubitle>}
          {content?.whereToRedeem && (
            <p className="text-xs m-0 card-details">
              {content?.redeemLabel} &nbsp;
              {content?.whereToRedeem && content?.whereToRedeem.map((location) => location?.fields?.name).join(' & ')}
            </p>
          )}
        </CardBody>
      </CardContents>
    </CardItem>
  );
}
