// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import FeatureBanner from '../common/Banners/FeatureBanner';
import WrapperLayout from '../layouts/WrapperLayout';
import { checkArrayForItems } from '../../helpers/utils';

// Helpers
import { getGASlot } from '../../helpers/bannersHelper';

const GuestFeatureBannersCarousel = ({ module, languageCode, page }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  const { banners, title, headerBackgroundColor } = module.fields;

  const slot = getGASlot(page?.zones, module.contentID);

  if (!isLoggedIn && checkArrayForItems(banners)) {
    return (
      <WrapperLayout
        className="global-content-wrapper"
        customStyles="spotlights"
        isGray={headerBackgroundColor === 'isGray'}
        isLightBlack={headerBackgroundColor === 'isLightBlack'}
      >
        {title && (
          <h2
            className={`headline-medium block mb-8 md:mb-10 lg:mb-14 text-center ${
              headerBackgroundColor === 'isLightBlack' ? 'text-white' : ''
            }`}
          >
            {title}
          </h2>
        )}

        <FeatureBanner
          banners={banners}
          languageCode={languageCode}
          customData={module.fields}
          isLightBlack={headerBackgroundColor === 'isLightBlack'}
          bannerType="FeatureBanner"
          pageName={page.name}
          slot={slot}
          sectionTitle={title}
        />
      </WrapperLayout>
    );
  }
  return null;
};

export default GuestFeatureBannersCarousel;
