// Global State
import { useStateMachine } from 'little-state-machine';

// Hooks
import SpotlightAnnouncementBanner from '../common/Banners/SpotlightAnnouncementBanner';

const Spotlights = ({ module, languageCode }) => {
  const { title, expireLabel } = module.fields;
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if (isLoggedIn) {
    return (
      <SpotlightAnnouncementBanner
        languageCode={languageCode}
        title={title}
        expireLabel={expireLabel}
        customData={module.fields}
        maximumSlides={2}
        isLightBlack
      />
    );
  }
  return null;
};

export default Spotlights;
