import PropTypes from 'prop-types';
import Image from 'next/image';

import LinkComponent from '../Links/LinkComponent';

import { StyledBanner, BannerImage, TwoColumnText, TwoColumnTitle } from './TwoColumnContentStyles';
import { isEmpty } from '../../../helpers/utils';

export default function TwoColumnContent({ fields, languageCode }) {
  if (!isEmpty(fields)) {
    const { title, message, cTALink, cTALabel, cTALabelLoading, imagePosition, imagePositionMobile, webImage, appImage } = fields;

    return (
      <StyledBanner
        className={`md:space-x-8 flex-col ${
          imagePosition && imagePosition === 'right' ? ' md:flex-row-reverse md:space-x-reverse' : 'md:flex-row'
        } `}
      >
        <BannerImage>
          {webImage?.url && (
            <Image
              src={webImage?.url}
              alt=""
              loading="eager"
              role="presentation"
              fill
              sizes="(max-width: 1400px) 500px"
              style={{
                objectFit: 'contain',
                objectPosition: '50% 50%',
              }}
            />
          )}
        </BannerImage>
        {(!imagePositionMobile || imagePositionMobile === 'above-text') && appImage?.url && (
          <BannerImage className="app-image">
            <Image
              src={appImage?.url}
              alt=""
              loading="eager"
              role="presentation"
              fill
              sizes="(max-width: 1400px) 500px"
              style={{
                objectFit: 'contain',
                objectPosition: '50% 50%',
              }}
            />
          </BannerImage>
        )}

        <div className="w-full md:w-2/4 lg:py-11 md:py-7">
          {title && <TwoColumnTitle className="headline-medium md:mb-3 lg:mb-6">{title}</TwoColumnTitle>}
          {message && <TwoColumnText className="mb-3 md:mb-6">{message}</TwoColumnText>}
          {cTALink?.href && cTALabel && (
            <LinkComponent
              href={cTALink?.href}
              target={cTALink?.target || '_self'}
              style={{ fontSize: '1rem', maxWidth: 'inherit' }}
              buttonLoadingText={cTALabelLoading || cTALabel}
              buttonStyle="primary-button"
              ariaLabel={cTALink?.text || cTALabel}
              className="mobile-w-full"
              locale={languageCode}
            >
              {cTALabel}
            </LinkComponent>
          )}
        </div>
        {appImage?.url && imagePositionMobile && imagePositionMobile === 'below-text' && (
          <BannerImage className="app-image">
            <Image
              src={appImage?.url}
              alt=""
              loading="eager"
              role="presentation"
              fill
              sizes="(max-width: 1400px) 500px"
              style={{
                objectFit: 'contain',
                objectPosition: '50% 50%',
              }}
            />
          </BannerImage>
        )}
      </StyledBanner>
    );
  }
  return null;
}

TwoColumnContent.propTypes = { fields: PropTypes.object, languageCode: PropTypes.string };
