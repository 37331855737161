import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';

import TransactionError from '../../common/Errors/TransactionError';
import Button from '../../common/Button/Button';

// Helpers
import { formatDynamicLabel } from '../../../helpers/stringHelpers';
import { formatPoints, calcPointsNeeded, calcPointsTotal, amountSelected } from '../../../helpers/pointsHelpers';
import { handleGaEcommImpression, handleGAEvents, stringConcatHelper } from '../../../helpers/handleGoogleAnalyticsHelper';

function GiftCardRedeem({ customData }) {
  const { notEnoughPointsLabel, needMorePointsButtonLabel, continueButtonLabel } = customData;
  const {
    state: {
      points: { added },
      giftCards: { redeem, selectedGiftCardIndex },
      transactions: { balance },
      language: { locale },
    },
  } = useStateMachine();

  const total = calcPointsTotal(added) * redeem.quantity;
  const pointsNeeded = calcPointsNeeded(added, balance, redeem.quantity);
  const remainingPoints = balance - total;
  const notEnoughPoints = remainingPoints < 0;
  const gaLabel = stringConcatHelper(total, ' | Continue Click');
  const RedemptionPoints = total;

  return (
    <div className="flex-1 w-full p-0">
      <Button
        type="submit"
        buttonText={continueButtonLabel}
        ariaLabel={continueButtonLabel}
        style={{ width: '100%', maxWidth: '100%' }}
        disabled={notEnoughPoints || !added.length || !redeem.selectedFormat}
        onClick={() => {
          handleGAEvents(gaLabel, 'Redeem', 'Continue Points For Credit Click', 'redeem_credit_continue_click');
          const amount = amountSelected(added);
          handleGaEcommImpression('gc_add_to_cart', {
            alt_event: 'reward_giftcard_add_to_cart',
            eventCategory: 'Gift Cards',
            eventAction: 'Gift Card Add To Cart',
            eventLabel: `${redeem?.giftCardDetails.brandName} GC | ${total} | ${redeem?.selectedFormat}`,
            reward_name: redeem?.giftCardDetails.brandName,
            partner: redeem?.giftCardDetails.brandName,
            reward_sku: redeem?.giftCardDetails?.digital[0]?.externalSku,
            points_redeem: total,
            reward_type: amount,
            giftcard_type: redeem?.selectedFormat,
            currency: 'CAD',
            value: RedemptionPoints,

            items: [
              {
                item_id: redeem?.giftCardDetails.brandId,
                item_name: `${redeem?.giftCardDetails.brandName} GC`,
                affiliation: redeem?.giftCardDetails.brandName,
                discount: 0,
                index: selectedGiftCardIndex > 0 ? selectedGiftCardIndex : '',
                item_brand: redeem?.giftCardDetails.brandName,
                item_category: 'Gift Cards',
                item_list_name: '',
                item_variant: amount,
                price: total,
                quantity: redeem?.quantity,
              },
            ],
          });
        }}
      >
        {`${
          notEnoughPoints
            ? formatDynamicLabel(needMorePointsButtonLabel, '##POINTSVALUE##', formatPoints(pointsNeeded, locale))
            : continueButtonLabel
        }`}
      </Button>

      {notEnoughPoints && <TransactionError error={{ message: notEnoughPointsLabel }} simplified />}
    </div>
  );
}

GiftCardRedeem.propTypes = {
  customData: PropTypes.object,
};

export default GiftCardRedeem;
