import PropTypes from 'prop-types';
// Components
import ShippingAddressSelect from './ShippingAddressSelect';

import { checkArrayForItems } from '../../../../helpers/utils';
import Loader from '../../../common/Loader/Loader';

export default function ShippingAddressSwitcher({ errorsList, setAddressList, shippingList, profileContent }) {
  if (checkArrayForItems(shippingList)) {
    return (
      <ShippingAddressSelect
        setAddressList={setAddressList}
        shippingList={shippingList}
        errorsList={errorsList}
        profileContent={profileContent}
      />
    );
  }
  return <Loader />;
}

ShippingAddressSwitcher.propTypes = {
  shippingList: PropTypes.array,
  setAddressList: PropTypes.array,
  errorsList: PropTypes.array,
  profileContent: PropTypes.object,
};
