import styled from 'styled-components';

export const StyledDisclaimer = styled.div`
  margin-top: 3.75rem;
  margin-bottom: 7.5rem;
  @media screen and (max-width: 640px) {
    margin-top: 2.5rem;
    margin-bottom: 5rem;
  }

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  p {
    color: ${(props) => props.theme.lightGrayColor4};
    font-size: ${(props) => props.theme.fontBody3};
    line-height: ${(props) => props.theme.lineHeight3};
    margin-bottom: 0;
  }

  button.primary-button {
    margin: 0;
    font-size: ${(props) => props.theme.fontBody2};
    white-space: nowrap;
    img {
      padding-right: 0.5rem;
      display: inline-block;
    }
  }
  .disclaimer-content {
    padding-right: 2.5rem;
  }
  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: start;
    .disclaimer-content {
      padding-right: 0;
    }

    button.primary-button {
      width: auto !important;
      margin-top: 1rem;
    }
  }
`;
