/* eslint-disable react/no-danger */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';
import { useStateMachine } from 'little-state-machine';

import Button from '../../common/Button/Button';

// Styles
import { FormInputLabel } from '../FormStyles';
import { RequestCardWrapper, FakeInput } from './RequestCardStyles';
import { generateProfileAddressStringFrom, formatDynamicLabel } from '../../../helpers/stringHelpers';

// Global State
import { updateNotification } from '../../../store/actions';

// Services
import { sendPhysicalCard } from '../../../pages/api/services';
// Hooks
import useModal from '../../hooks/utils/useModal';
import SuccessModal from '../../common/Modals/SuccessModal';
import PortalModal from '../../common/Modals/PortalModal';

// Helpers
import { generateErrorMessage } from '../../../helpers/errorsHelper';
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';

export default function PhysicalCardSection({ customData, customerData }) {
  const [submitting, setSubmitting] = useState(false);
  const [cardsLeft, setCardsLeft] = useState(null);
  const [cardRequested, setCardRequested] = useState(false);
  const {
    state: {
      session: { accessToken },
    },
    actions,
  } = useStateMachine({ updateNotification });
  const { isShowing, toggle } = useModal();

  const profileAddress = generateProfileAddressStringFrom(customerData?.customer?.data?.addresses[0]);
  const {
    physicalCardHeader,
    physicalCardMessage,
    addressLabel,
    buttonLabel,
    buttonLabelLoading,
    successDoneMailLabel,
    successHeaderMail,
    successMessageMail,
  } = customData.MyCardForm;

  const messageMail = formatDynamicLabel(successMessageMail, '##PROFILEADDRESS##', `<strong> ${profileAddress} </strong>`);
  const dynamicData = {
    buttonLabel: successDoneMailLabel,
    successHeader: successHeaderMail,
    successMessage: messageMail,
    hasReplaceContent: true,
    replaceRegex: '##CARDSLEFT##/##TOTALCARDS##',
    replaceValue: `<strong class='no-wrap'> ${cardsLeft} </strong>`,
  };

  function deriveCardsLeftMsg(issued, max) {
    const triesLeft = max - issued;

    return `${triesLeft}/${max}`;
  }

  const handleClick = () => {
    setSubmitting(true);
    setCardRequested(true);
    const dataLayer = {
      event: 'request_card_send_click',
      eventCategory: 'account',
    };

    handleGAEventsBase(dataLayer);
    sendPhysicalCard(accessToken)
      .then((res) => {
        if (res?.data?.selfServeCardsIssued && res?.data?.maximumSelfServeCardsThreshold) {
          setCardsLeft(deriveCardsLeftMsg(res.data.selfServeCardsIssued, res.data.maximumSelfServeCardsThreshold));
          const dataLayerSuccess = {
            event: 'request_card_success',
            eventCategory: 'account',
          };

          handleGAEventsBase(dataLayerSuccess);
          toggle();
        }

        if (res?.response?.data?.validationErrors) {
          actions.updateNotification({
            message: generateErrorMessage(
              res.response.data.validationErrors !== null
                ? Object.keys(res.response.data.validationErrors)
                : ['General.BadRequest'],
              customData?.errorsList
            ),
            status: 'error',
          });

          const dataLayerError = {
            event: 'send_my_card_error',
            error_type: Object.keys(res?.response?.data?.validationErrors).toString() || 'NotAvailable',
          };

          handleGAEventsBase(dataLayerError);
        }
      })
      .catch((error) => {
        if (error?.response !== null && error?.response?.data !== null) {
          actions.updateNotification({
            message: generateErrorMessage(
              error?.response?.data?.validationErrors
                ? Object.keys(error.response.data.validationErrors)
                : ['General.BadRequest'],
              customData?.errorsList
            ),
            status: 'error',
          });
        }
        console.error(error);
        const dataLayerError = {
          event: 'send_my_card_error',
          error_type: Object.keys(error?.response?.data?.validationErrors).toString() || 'NotAvailable',
        };

        handleGAEventsBase(dataLayerError);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <RequestCardWrapper>
        <h2>{physicalCardHeader}</h2>
        <div className="subheading" id="physicalCardMessage" dangerouslySetInnerHTML={renderHTML(physicalCardMessage)} />
        {Boolean(addressLabel) && (
          <FormInputLabel className="mt-3" id="label-address" htmlFor="address">
            {addressLabel}
          </FormInputLabel>
        )}

        <FakeInput dangerouslySetInnerHTML={renderHTML(profileAddress)} />
        <div className="btn-wrapper">
          <Button type="button" onClick={handleClick} ariaLabel={buttonLabel} disabled={cardRequested}>
            {submitting ? buttonLabelLoading : buttonLabel}
          </Button>
        </div>
      </RequestCardWrapper>
      <PortalModal isShowing={isShowing} toggle={toggle} type="modal-confirmation">
        <SuccessModal toggle={toggle} dynamicData={dynamicData} />
      </PortalModal>
    </>
  );
}
PhysicalCardSection.propTypes = {
  customData: PropTypes.object.isRequired,
  customerData: PropTypes.object,
};
