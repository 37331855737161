import Image from 'next/image';
import Link from 'next/link';

import { HeaderColumn, HeaderColumnWrapper } from '../HeaderStyles';

export default function SiteHeaderLogo({ siteLogo }) {
  if (siteLogo) {
    return (
      <HeaderColumnWrapper className="flex-grow-0 header-logo relative">
        <Link href="/">
          <HeaderColumn>
            <Image
              src={siteLogo.url}
              alt={siteLogo.label}
              loading="eager"
              quality={75}
              fill
              sizes="100vw"
              style={{
                objectFit: 'contain',
                objectPosition: 'bottom left',
              }}
            />
          </HeaderColumn>
        </Link>

        <div id="portal-home1" />
      </HeaderColumnWrapper>
    );
  }
  return null;
}
