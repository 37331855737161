/* eslint-disable no-shadow */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';
import { Controller, useFormContext } from 'react-hook-form';

import updatePoints from '../../../store/actions/updatePointsAction';

// Helpers
import { formatPoints, calcPointsNeeded } from '../../../helpers/pointsHelpers';
import { formatDynamicLabel } from '../../../helpers/stringHelpers';
import { formatToWholeNumberCurrency } from '../../../helpers/utils';
import { handleCreditRedeemEvent } from '../../../helpers/handleGoogleAnalyticsHelper';

import { Wrapper, StyledRadio, PointButton } from '../Shop/PointsShopStyles';

export default function PointsRadioList({
  name,
  rules,
  fieldType,
  fieldId,
  transaction,
  position,
  customData: { notEnoughPointsLabel, pts },
}) {
  const {
    state: {
      points: { added },
      transactions: { balance },
      language: { locale },
    },
  } = useStateMachine();

  const { code, dollarValue, points } = transaction;
  const transactionName = transaction.name;
  const { actions } = useStateMachine({ updatePoints });
  const [radio, setRadio] = useState(false);
  const radioData = {
    name,
    code,
    transactionName,
    radio,
    dollarValue,
    points,
    position,
  };

  const formContext = useFormContext();
  const { control, setValue } = formContext;

  const handleChange = (e) => {
    setRadio(!radio);
    setValue(name, e.target.value);
    handleCreditRedeemEvent(
      `${transactionName} | ${points}pts`,
      'Points For Credit',
      'Points For Credit Select Amount',
      'select_content',
      code,
      'Points For Credit'
    );

    if (e.target.value) {
      actions.updatePoints({ added: [radioData] });
    }
  };

  return (
    <Wrapper role="radiogroup">
      <Controller
        control={control}
        name={name}
        rules={{
          ...rules,
          validate: async (value) => {
            const pointsNeeded = (await value) ? calcPointsNeeded(added, balance) : 0;

            if (pointsNeeded > 0) {
              return formatDynamicLabel(notEnoughPointsLabel, '##POINTS##', pointsNeeded);
            }
          },
        }}
        render={({ field: { onBlur, name }, fieldState: { error } }) => (
          <>
            <PointButton error={error}>
              <StyledRadio
                name={name}
                id={`rb-${fieldId}`}
                className="points-radio"
                type={fieldType}
                aria-invalid={!!error}
                aria-labelledby={`label-${fieldId}`}
                onBlur={onBlur}
                value={name}
                tabIndex="0"
                onChange={(e) => handleChange(e)}
              />
              <div className="amount-points">
                <p className="amount">{formatToWholeNumberCurrency(dollarValue, locale)}</p>
                <label
                  id={`label-${fieldId}`}
                  htmlFor={`${name}-${position}`}
                  className="points"
                  aria-label={`${dollarValue}$: (${points} points)`}
                >
                  {points ? formatPoints(points, locale) : 0} {pts}
                </label>
              </div>
            </PointButton>
          </>
        )}
      />
    </Wrapper>
  );
}

PointsRadioList.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
  fieldType: PropTypes.string,
  fieldId: PropTypes.string,
  transaction: PropTypes.object,
  position: PropTypes.number,
  customData: PropTypes.object,
};
