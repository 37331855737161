import PropTypes from 'prop-types';
import Image from 'next/image';

// Styles
import { EmptyTransactions, EmptyTransactionsContent, EmptyTransactionHeading } from '../PointsShopStyles';

export default function NoTransactions({ customData }) {
  const { nTMessage, nTTitle, nTImage } = customData;

  return (
    <EmptyTransactions>
      <div className="no-transactions-image">
        {nTImage && (
          <Image
            src={`${nTImage ? nTImage.url : '/assets/photo.svg'}`}
            alt={`${nTImage.alt || ''}`}
            width={200}
            height={135}
            quality={25}
            priority
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        )}
      </div>
      <EmptyTransactionsContent>
        {nTTitle && <EmptyTransactionHeading className="font-bold mt-2 mb-3">{nTTitle}</EmptyTransactionHeading>}
        {nTMessage && <p>{nTMessage}</p>}
      </EmptyTransactionsContent>
    </EmptyTransactions>
  );
}

NoTransactions.propTypes = {
  customData: PropTypes.object,
};
