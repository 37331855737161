import Image from 'next/image';

import useDragDetection from '../../hooks/utils/useDragDetection';
import { CardItem, Brand, CardHeader, CardImage, LogoBadge, CardBody, CardContents, CardTitle, CardSubitle } from './CardStyles';

// Helpers
import { handleGAEvents, handleGaEcommImpression, getEventTileClickMapping } from '../../../helpers/handleGoogleAnalyticsHelper';
import { formatDate } from '../../../helpers/dateHelpers';

export default function EventCard({ id, content, offersNum, toggle, openDetailsModalLabel, languageCode, sectionTitle, isGrid }) {
  const { handleMouseDown, dragging } = useDragDetection();

  const getCardId = (cardId) =>
    // Returns cardId with adjusted indices (i.e card-0 to Card 1).
    cardId.split('-')[0].concat(Number(cardId.split('-')[1]) + 1);

  const handleChildClick = (e) => {
    if (dragging) {
      e.preventDefault();
      return;
    }
    toggle(content?.code);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      toggle(content?.code);
    }
  };

  const handleCardItemClick = () => {
    handleGAEvents(content?.brandInfo?.fields?.title, 'Event', 'Event Impression Interaction', 'event_click');
    handleGaEcommImpression('event_select_item', getEventTileClickMapping(content, id, sectionTitle));
  };

  if (!content) return null;

  return (
    <CardItem
      id={id}
      offersNum={offersNum}
      className="flex card-item card-content-wrapper"
      onClick={() => {
        handleCardItemClick();
      }}
      isGrid={isGrid}
    >
      <CardContents
        tabIndex="0"
        role="link"
        aria-label={`${getCardId(id)}: ${openDetailsModalLabel}: ${content?.brand?.title || ''}, ${content?.title} - ${
          content?.description || ''
        } : ${
          content?.startDate && content?.endDate ? `${formatDate(content?.startDate)} : ${formatDate(content?.endDate)}` : ''
        }`}
        className="card-contents cursor-pointer"
        offersNum={offersNum}
        onMouseDownCapture={handleMouseDown}
        onClickCapture={handleChildClick}
        onKeyDown={handleKeyDown}
      >
        <CardHeader offersNum={offersNum} className="w-full">
          {content?.brand?.logoTransparentBG?.url && (
            <LogoBadge className="rounded-full overflow-hidden">
              <Image
                key={content?.code}
                src={content?.brand?.logoTransparentBG?.url}
                alt={content?.brand?.title}
                title={content?.brand?.title}
                quality={25}
                fill
                sizes="100vw"
                style={{
                  objectFit: 'contain',
                }}
              />
            </LogoBadge>
          )}
          {content?.image?.url && (
            <CardImage className="relative w-full h-full">
              <Image
                src={content?.image?.url}
                alt=""
                loading="eager"
                fill
                sizes="(max-width: 1400px) 800px"
                style={{
                  objectFit: 'cover',
                  objectPosition: '50% 50%',
                }}
              />
            </CardImage>
          )}
        </CardHeader>
        <CardBody>
          <Brand className="text-sm mb-2 font-bold">{content?.brand?.title}</Brand>

          {content?.title && (
            <CardTitle className="text-md mb-2 font-bold" rewardCard>
              {content?.title}
            </CardTitle>
          )}
          {content?.description && <CardSubitle className="mb-2">{content?.description}</CardSubitle>}

          <p className="text-xs m-0 card-details">
            {content?.dateTimeLocation
              ? `${content?.dateTimeLocation}`
              : `${content?.startDate && formatDate(content?.startDate, 'MMM DD', languageCode)}
             ${content?.startDate && content?.endDate && ' - '}
             ${content?.endDate && formatDate(content?.endDate, 'MMM DD', languageCode)}`}
          </p>
        </CardBody>
      </CardContents>
    </CardItem>
  );
}
