import PropTypes from 'prop-types';
import Image from 'next/image';

export default function PrimaryAddress({ address, primaryShippingAddressLabel, profileAddressLabel, shippingAddressLabel }) {
  return (
    <div className="address-icon w-10 flex mt-1 justify-start content-center">
      {address?.primary ? (
        <Image
          src="/assets/star-icon.svg"
          alt={primaryShippingAddressLabel}
          title={primaryShippingAddressLabel}
          height={18}
          width={18}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      ) : (
        <Image
          src="/assets/circle-icon.svg"
          alt={address?.addressType === 'Scene' ? profileAddressLabel : shippingAddressLabel}
          title={address?.addressType === 'Scene' ? profileAddressLabel : shippingAddressLabel}
          height={18}
          width={18}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      )}
    </div>
  );
}

PrimaryAddress.propTypes = {
  address: PropTypes.object.isRequired,
  primaryShippingAddressLabel: PropTypes.string,
  profileAddressLabel: PropTypes.string,
  shippingAddressLabel: PropTypes.string,
};
