// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import OfferDetailsModal from '../common/Modals/OfferDetailsModal';

// http://localhost:3000/en-ca/offers#/OFR_BNS_Featured_40_305_48f?q=6oOHHx55Yvs2j9f50rNKaxtOuwgzyHvFhK6ZlKgg5lHtWQ1mD%2flfpjixadYc%2bXNdUa2q1Pgw%2bZw%3d&oid=3548

// http://localhost:3000/fr-ca/offers#/OFR_BNS_Featured_40_305_48f?q=6oOHHx55Yvs2j9f50rNKaxtOuwgzyHvFhK6ZlKgg5lHtWQ1mD%2flfpjixadYc%2bXNdUa2q1Pgw%2bZw%3d&oid=3548

export default function GuestOfferDetails({ module, languageCode }) {
  // get module fields
  const { fields } = module;
  const {
    state: {
      session: { isLoggedIn },
      navigation: { isReady },
    },
  } = useStateMachine();

  if (isReady && isLoggedIn) {
    return null;
  }

  return <OfferDetailsModal fields={fields} languageCode={languageCode} />;
}
