/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import Link from 'next/link';
import Image from 'next/image';

import SliderTileContents from './SliderTileContents';

import { BannerItem, BannerContents } from './BannerStyles';

import { formatDate } from '../../../helpers/dateHelpers';
import { getFeatureBannerMapping, handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';
import { handleKeyDown } from '../../../helpers/utils';

export default function SliderTile({
  customData,
  content,
  id,
  bannersNum,
  expireLabel,
  index,
  sectionTitle,
  bannerType,
  pageName,
  slot,
}) {
  const { webImage, title, description, cTALink, ctaLink, eyebrow, heading, subCopy } = content.fields || content.cmsContent;
  const { learnMoreLabel } = customData;
  const { endDate } = content;

  const handleClick = (e) => {
    try {
      const ecommerce = getFeatureBannerMapping(
        'feature_banner_click',
        'Feature Banner Click',
        content,
        bannerType,
        pageName,
        id,
        index,
        sectionTitle,
        slot
      );

      const dataLayer = {
        event: 'select_promotion',
        ecommerce,
      };

      handleGAEventsBase(dataLayer);
    } catch {
      //
    }
  };

  useEffect(() => {
    const ecommerce = getFeatureBannerMapping(
      'feature_banner_impression',
      'Feature Banner Impressions',
      content,
      bannerType,
      pageName,
      id,
      index,
      sectionTitle,
      slot
    );

    const dataLayer = {
      event: 'view_promotion',
      ecommerce,
    };

    handleGAEventsBase(dataLayer);
  }, []);

  return (
    <BannerItem id={id} bannersNum={bannersNum}>
      <Link
        href={cTALink?.href || ctaLink?.href}
        tabIndex="0"
        aria-label={`${eyebrow || formatDate(endDate, 'MMM DD')}: ${ctaLink?.text || cTALink?.text} ${
          subCopy ? subCopy || description : ''
        }`}
        target={ctaLink?.target || cTALink?.target}
        role="link"
        onClick={() => {
          handleClick();
        }}
        onKeyDown={(e) => handleKeyDown(e, handleClick)}
      >
        <BannerContents className="banner-content-wrapper flex justify-center items-center" bannersNum={bannersNum}>
          {webImage && (
            <div className="relative banner-image">
              <Image
                src={webImage.url}
                alt=""
                loading="eager"
                fill
                sizes="(max-width: 1400px) 800px"
                style={{
                  objectFit: 'cover',
                  objectPosition: '50% 50%',
                }}
              />
            </div>
          )}
          <div className="banner-content">
            <div className="banner-content-inner">
              <SliderTileContents
                eyebrow={eyebrow}
                heading={heading || title}
                subCopy={subCopy || description}
                expireLabel={expireLabel}
              />

              <span className="banner-learnmore desktop-learnmore">{learnMoreLabel || content.fields.learnMoreLabel}</span>
            </div>

            <span className="banner-learnmore mobile-learnmore">{learnMoreLabel || content.fields.learnMoreLabel}</span>
          </div>

          <i className="cta-arrow-link" aria-hidden />
        </BannerContents>
      </Link>
    </BannerItem>
  );
}

SliderTile.propTypes = {
  customData: PropTypes.object,
  content: PropTypes.object,
  id: PropTypes.string,
  bannersNum: PropTypes.number,
  expireLabel: PropTypes.string,
  index: PropTypes.string,
  sectionTitle: PropTypes.string,
  bannerType: PropTypes.string,
  pageName: PropTypes.string,
  slot: PropTypes.number,
};
