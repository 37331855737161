import PropTypes from 'prop-types';

// Hellpers
import { formatDate } from '../../../helpers/dateHelpers';
import useDragDetection from '../../hooks/utils/useDragDetection';

export default function OffersSpotlightCard({ id, code, customData, content, bannersNum, languageCode, toggle, isStandalone }) {
  const { ctaLink, title, description, subTitle, eyebrow } = content.cmsContent;
  const { learnMoreLabel, expireLabel } = customData;

  const { dragging } = useDragDetection();

  const handleChildClick = (e) => {
    if (dragging) {
      e.preventDefault();
      return;
    }
    if (!isStandalone) {
      toggle(code);
    }
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      toggle(code);
    }
  };

  return (
    <div id={id} className="banner-content">
      <div className="banner-content-inner">
        {eyebrow ? (
          <p className="eyebrow text-sm">{eyebrow}</p>
        ) : (
          content.endDate && (
            <p className="eyebrow text-sm">
              {expireLabel} {formatDate(content.endDate, 'MMM DD', languageCode)}
            </p>
          )
        )}
        {title && <h2 className="mb-2">{title}</h2>}
        {subTitle || (description && <p className="cta-text">{subTitle || description}</p>)}

        {bannersNum === 1 && (
          <div className="mt-4">
            <span className="load-text">{isStandalone && ctaLink ? ctaLink?.text : learnMoreLabel}</span>
          </div>
        )}
      </div>

      {bannersNum !== 1 && (
        <div className="mt-4 spotlight-banner-learnmore-container">
          <span className="load-text spotlight-banner-learnmore">{isStandalone && ctaLink ? ctaLink?.text : learnMoreLabel}</span>
        </div>
      )}

      <span
        className="cta-arrow-link"
        aria-label={isStandalone && ctaLink ? ctaLink?.text : learnMoreLabel}
        role="button"
        onClick={handleChildClick}
        onKeyDown={handleKeyDown}
        aria-hidden
        tabIndex={-1}
      />
    </div>
  );
}

OffersSpotlightCard.propTypes = {
  id: PropTypes.string,
  code: PropTypes.string,
  customData: PropTypes.object,
  content: PropTypes.object,
  bannersNum: PropTypes.number,
  languageCode: PropTypes.string,
  toggle: PropTypes.func,
  isStandalone: PropTypes.bool,
};
