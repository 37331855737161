import { useEffect } from 'react';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateLabels } from '../../../store/actions';

export default function useGlobalLabels(customLabels = [], languageCode) {
  const {
    actions,
    state: { labels },
  } = useStateMachine({ updateLabels });

  useEffect(() => {
    if (!labels && customLabels) {
      actions.updateLabels(customLabels);
    }
  }, [labels]);

  useEffect(() => {
    if (languageCode && customLabels) {
      actions.updateLabels(customLabels);
    }
  }, [languageCode]);
}
