import styled from 'styled-components';

export const MyCardWrapper = styled.div`
  width: 100%;
  max-width: 36rem;
  min-width: 17.25rem;
  margin: 0 auto;
`;
export const CardItem = styled.div`
  flex-direction: column;
  height: 279px;
  margin: 2.5rem auto 7rem;
  overflow: hidden;
  background-color: ${(props) => props.theme.whiteColor};
  border-radius: 6px;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.11);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.11);

  @media screen and (max-width: 1023px) {
    margin: 2rem auto 5rem;
  }
`;

export const CardHeader = styled.div`
  position: relative;
  flex: 0 0 100%;
  @media screen and (max-width: 512px) {
  }

  @media screen and (max-width: 375px) {
  }
`;

export const CardImage = styled.div`
  height: 153px;
`;

export const CardBody = styled.div`
  background-color: ${(props) => props.theme.whiteColor};
  position: relative;
  flex: 0 0 100%;
  padding: 1rem;
  height: 126px;
`;

export const CardCustomerName = styled.h2`
  font-size: ${(props) => props.theme.fontLegal};
  text-overflow: ellipsis;
  float: right;
  position: relative;
  height: 24px;
  z-index: 1 !important;
  margin: 19px 24px 0px 0px;
  color: ${(props) => props.theme.whiteColor};
`;

export const PointsContainer = styled.div`
  margin: auto 0;
  height: 3rem;
  width: 9.563rem;
  @media screen and (max-width: 512px) {
    height: 2.688rem;
    width: 7.625rem;
  }
`;

export const Points = styled.p`
  font-weight: bold;
  font-size: ${(props) => props.theme.headingMedium};
  line-height: 2.563rem;
  position: absolute;
  top: 82px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  z-index: 1;
  width: 100%;
  color: ${(props) => props.theme.whiteColor};
  @media screen and (max-width: 512px) {
    line-height: 1.875rem;
    font-size: ${(props) => props.theme.headingSmall};
  }
`;

export const PointsLabel = styled.span`
  margin-left: 0.5rem;
  font-size: ${(props) => props.theme.fontBody3};
  vertical-align: sub;
  font-weight: normal;
`;
export const Barcode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const CardLogo = styled.div`
  position: absolute;
  width: 77px;
  height: 23px;
  z-index: 1 !important;
  left: 12px;
  top: 14px;
`;

export const CardContents = styled.div``;
