/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { renderHTML } from '@agility/nextjs';
import Image from 'next/image';

import { useStateMachine } from 'little-state-machine';
import { updatePoints, updateTransactions } from '../../../store/actions';

import { ContentCol, StyledTransactions, StyledAwardsList, BackgroundHeroPFC, BYOTMessageContainer } from './PointsShopStyles';

// Components
import Summary from './Summary';
import TransactionList from './TransactionList';
import CardFilter from '../Filter/CardFilter';
import CardDropdown from '../Filter/CardDropdown';
import FilterByDate from '../Filter/FilterByDate';
import Loader from '../../common/Loader/Loader';
import Title from '../../common/Headings/Title';
import { TitleWrapper } from '../../common/Headings/TitleStyles';

// Hooks
import useModal from '../../hooks/utils/useModal';

export default function PointsTransactions({ page, customData, step, languageCode }) {
  const { title, bYOTMessage, howItWorksTitle, howItWorksMessage, periodLabel, cardLabel, selectCardLabel, image } = customData;
  const {
    actions,
    state: {
      transactions: { isLoading, selectedCard },
    },
  } = useStateMachine({ updatePoints, updateTransactions });

  const { isShowing, toggle, currentItemId } = useModal();

  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const onSubmit = async () => {
    actions.updatePoints({ step: 2 });
  };

  const onErrors = (errors) => {
    console.error(errors);
  };

  return (
    <FormProvider {...formMethods}>
      <>
        {title && (
          <TitleWrapper id={`page-header-${page.name}`}>
            <Title>{title}</Title>
          </TitleWrapper>
        )}

        {page === 'byot' && (
          <BYOTMessageContainer>
            {bYOTMessage && <p>{bYOTMessage}</p>}
            {howItWorksTitle && <h2>{howItWorksTitle}</h2>}
            {howItWorksMessage && <div dangerouslySetInnerHTML={renderHTML(howItWorksMessage)} />}
          </BYOTMessageContainer>
        )}
      </>

      <div>
        <div
          className={`inner-columns-wrapper page-bottom-spacing ${page === 'pfc' ? ' sm:flex justify-between md:pt-8 pt-4' : ''}`}
        >
          {page === 'pfc' && howItWorksTitle && howItWorksMessage && (
            <ContentCol className="sm:pr-8 relative">
              <div>
                {howItWorksTitle && <h2>{howItWorksTitle}</h2>}
                {howItWorksMessage && <div dangerouslySetInnerHTML={renderHTML(howItWorksMessage)} />}
              </div>
              {step === 1 && (
                <BackgroundHeroPFC className="hidden sm:block w-full">
                  <div className="img-container">
                    <Image
                      src={`${image ? image.url : '/assets/placeholder.svg'}`}
                      alt=""
                      loading="eager"
                      role="presentation"
                      fill
                      sizes="100vw"
                      style={{
                        objectFit: 'cover',
                        objectPosition: 'bottom',
                      }}
                    />
                  </div>
                </BackgroundHeroPFC>
              )}
            </ContentCol>
          )}

          {isLoading && !selectedCard.id ? (
            <Loader />
          ) : (
            <StyledAwardsList className={` ${page === 'pfc' ? 'pfc-awards-list' : 'byot-transactions'}`}>
              <form className="relative p-0 lg:w-full" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
                <div className={`${page === 'byot' ? 'lg:flex' : ''}`}>
                  <StyledTransactions className="flex-1 mb-4 md:mb-0" page={page}>
                    <CardFilter page={page}>
                      <div className={`flex-1 mb-4 md:mb-0${page === 'byot' ? ' card-column' : ''}`}>
                        <CardDropdown toggle={toggle} name="card-filter" label={cardLabel || selectCardLabel} />
                      </div>

                      {page === 'byot' && selectedCard.id && (
                        <div className="flex-1 period-column">
                          <FilterByDate name="period-filter" label={periodLabel} />
                        </div>
                      )}
                    </CardFilter>

                    <TransactionList
                      page={page}
                      customData={customData}
                      isShowing={isShowing}
                      toggle={toggle}
                      currentItemId={currentItemId}
                      languageCode={languageCode}
                    />
                  </StyledTransactions>

                  <Summary page={page} customData={customData} />
                </div>
              </form>
            </StyledAwardsList>
          )}
        </div>
      </div>
    </FormProvider>
  );
}

PointsTransactions.propTypes = {
  page: PropTypes.string,
  customData: PropTypes.object,
  step: PropTypes.number,
  languageCode: PropTypes.string,
};
