import PropTypes from 'prop-types';

import Slider from 'react-slick';

import useModal from '../../hooks/utils/useModal';

// Components
import SliderTile from './SliderTile';

// Styles
import { FeatureCarouselWrapper, SectionTitle } from './BannerStyles';

// Helpers
import { bannerSettings } from '../../../helpers/bannersHelper';
import OffersSliderTile from './OffersSliderTile';
import SpotlightModal from '../Modals/SpotlightModal';

export default function FeatureBanner({
  customData,
  page,
  banners,
  title,
  expireLabel,
  languageCode,
  isLightBlack,
  bannerType,
  pageName = '',
  slot,
  sectionTitle,
}) {
  const settings = bannerSettings(banners, banners.length);

  const { isShowing, toggle, currentItemId } = useModal();

  if (banners) {
    return (
      <FeatureCarouselWrapper bannersNum={banners.length}>
        {title && <SectionTitle isLightBlack={isLightBlack}>{title}</SectionTitle>}

        <Slider {...settings}>
          {banners.map((item, index) =>
            page === 'Offers' ? (
              <OffersSliderTile
                id={`banner-${item.contentID || item.spotlightId || index}`}
                index={`spotlight-${index}`}
                content={item}
                key={index}
                bannersNum={banners.length}
                languageCode={languageCode}
                customData={customData}
                isShowing={isShowing}
                toggle={toggle}
                sectionTitle={title}
                bannerType={bannerType}
              />
            ) : (
              <SliderTile
                id={`banner-${item.contentID || item.spotlightId || index}`}
                index={`banner-${index}`}
                content={item}
                key={index}
                bannersNum={banners.length}
                expireLabel={expireLabel}
                languageCode={languageCode}
                customData={customData}
                sectionTitle={sectionTitle}
                bannerType={bannerType}
                pageName={pageName}
                slot={slot}
              />
            )
          )}
        </Slider>

        <SpotlightModal
          isShowing={isShowing}
          toggle={toggle}
          currentItemId={currentItemId}
          list={banners}
          customData={customData}
          languageCode={languageCode}
        />
      </FeatureCarouselWrapper>
    );
  }
  return null;
}

FeatureBanner.defaultProps = {
  pageName: '',
};

FeatureBanner.propTypes = {
  customData: PropTypes.object,
  page: PropTypes.string,
  banners: PropTypes.array,
  title: PropTypes.string,
  expireLabel: PropTypes.string,
  languageCode: PropTypes.string,
  isLightBlack: PropTypes.bool,
  bannerType: PropTypes.string,
  pageName: PropTypes.string,
  slot: PropTypes.number,
  sectionTitle: PropTypes.string,
};
