/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';

// Global State
import { useStateMachine } from 'little-state-machine';

// Components
import WrapperLayout from '../layouts/WrapperLayout';

const PinPage = ({ module: { fields } }) => {
  const {
    state: {
      session: { isLoggedIn },
    },
  } = useStateMachine();

  if (isLoggedIn) {
    return <WrapperLayout className="global-content-wrapper" />;
  }

  return null;
};

PinPage.propTypes = {
  module: PropTypes.object.isRequired,
};
export default PinPage;
