import styled, { css } from 'styled-components';

export const CardItem = styled.div`
  flex-direction: column;

  ${(props) =>
    !props.isGrid &&
    css`
      margin-right: 1.875rem;
      .card-contents {
        width: 17.5rem;
        display: block;
      }
    `};

  @media screen and (max-width: 640px) {
    ${(props) =>
      props.offersNum === 2 &&
      css`
        margin-bottom: 1.5rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      `}
  }

  a {
    text-decoration: none;
  }
`;
export const CardContents = styled.div`
  background-color: ${(props) => props.theme.whiteColor};
  border: 1px solid ${(props) => props.theme.lightGrayColor3};
  overflow: hidden;
  margin: 0 0.25rem;
  &:hover,
  &:focus {
    outline: none;
    transition: all 0.1s linear;
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.15);
  }
`;
export const CardHeader = styled.div`
  position: relative;
  flex: 0 0 100%;
  height: 10.625rem;
`;

export const LogoBadge = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  z-index: 1;
  padding: 0;
  width: 3rem;
  height: 3rem;
`;

export const CardImage = styled.div`
  height: 100%;
`;

export const CouponBanner = styled.div`
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 1.875rem;
  text-align: center;
  background-color: ${(props) => props.theme.uiCharcoal};
  color: ${(props) => props.theme.uiWhite};
  line-height: 1.875rem;
  font-size: ${(props) => props.theme.fontBody3};
  font-weight: 700;
  overflow: hidden;
`;

export const CardBody = styled.div`
  position: relative;
  flex: 0 0 100%;
  height: 11.875rem;
  padding: 1rem;
  overflow: hidden;

  .card-details {
    color: ${(props) => props.theme.lightGrayColor4};
    line-height: ${(props) => props.theme.lineHeight3};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;
export const Brand = styled.p`
  color: ${(props) => props.theme.lightGrayColor4};
  font-weight: bold;
  font-size: ${(props) => props.theme.fontLegal};
  line-height: ${(props) => props.theme.lineHeight3};
  margin-bottom: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
export const CardTitle = styled.h3`
  font-size: ${(props) => props.theme.fontBody1};
  line-height: 1.56;
  margin-bottom: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
export const CardSubitle = styled.p`
  font-weight: normal;
  line-height: 1.43;
  margin-bottom: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
export const CardFooter = styled.div`
  font-weight: bold;
  width: 100%;
  display: flex;
  height: 2.75rem;
  justify-content: flex-end;
  border-top: 1px solid ${(props) => props.theme.lightGrayColor3};

  .load-text {
    display: flex;
    align-items: center;
    position: relative;
    font-size: ${(props) => props.theme.fontBody3};
    margin-bottom: 1px;
    margin-right: 1px;
    padding-left: 10px;

    &:focus {
      outline: 1px solid #9b43ff;
    }
  }
`;
export const LoadOfferIcon = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.75rem;
  margin-left: 1rem;
  background-color: ${(props) => (props.loaded ? props.theme.whiteColor : props.theme.uiPurple)};
  border-left: 1px solid ${(props) => props.theme.lightGrayColor3};

  &.loaded-offer {
    background-color: ${(props) => props.theme.whiteColor};
    border-left: 1px solid ${(props) => props.theme.lightGrayColor3};
  }
`;
export const ModalLoadOfferIcon = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.75rem;
`;
