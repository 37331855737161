export default function SliderTileContents({ eyebrow, heading, subCopy, expireLabel }) {
  return (
    <>
      {eyebrow && (
        <p className="eyebrow text-sm">
          {expireLabel} {eyebrow}
        </p>
      )}
      {heading && <h2 className="mb-2">{heading}</h2>}
      {subCopy && <p className="cta-text">{subCopy}</p>}
    </>
  );
}
