import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

// Global state
import { useStateMachine } from 'little-state-machine';
import { updateUser, updateNotification } from '../../store/actions';
import updateStepAction from '../../store/actions/updateStepAction';

import { validateEmail } from '../../pages/api/services';
import { generateErrorMessage } from '../../helpers/errorsHelper';

// Components
import FormFooter from '../forms/registration-gk/FormFooter';
import FormHeader from '../forms/registration-gk/FormHeader';

// Hooks
import useReCaptcha from '../hooks/services/useReCaptcha';

// Helpers
import { handleGAEvents, handleGaSignupPageError, handleGAEventsBase } from '../../helpers/handleGoogleAnalyticsHelper';

export default function RegisterStep1FormLayoutGK({ children, ...props }) {
  const { title, nextLabel, nextLabelLoading, signInLabel, errorsList, languageCode } = props;
  const [submitting, setSubmitting] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });

  const { actions, state } = useStateMachine({
    updateUser,
    updateStepAction,
    updateNotification,
  });

  // useRegisterGAFormEvents(formMethods.formState?.touchedFields, 'Step 1');

  const { getCaptchaToken } = useReCaptcha('register_user_step_1');

  const handleValidEmailResponse = async (res, formData) => {
    if (res.data) {
      // only proceed to Ghost Kit Flow after email validation
      if (formData.activateGK[0] === 'yes') {
        actions.updateUser({ ...formData, ...{ step: 2, isGhostKitActivationEnabled: true } });
      } else {
        actions.updateUser({ ...formData, ...{ step: 3, isGhostKitActivationEnabled: false } });
      }

      actions.updateNotification({
        message: '',
        status: '',
      });
      window.scrollTo(0, 0);
      handleGAEvents('Next', 'Registration Events', 'Step 2', 'sign_up_button_next');
    }
  };

  const handleErrorResponse = (error) => {
    const duration = 2 * 60 * 1000; // 2 mins in milliseconds

    if (error.response !== null && error.response.data !== null) {
      const statusType = 'error';
      const validationError =
        error.response.data.validationErrors && Object.keys(error.response.data.validationErrors).toString();

      actions.updateNotification({
        message: generateErrorMessage(
          error?.response?.data?.validationErrors ? validationError.split(',') : ['General.BadRequest'],
          errorsList
        ),
        status: statusType,
        duration,
      });

      const dataLayer = {
        event: 'sign_up_error_email_validation',
        category: 'Registration Events',
        action: 'Error',
        label: validationError || 'NotAvailable',
        error_type: validationError || 'General.ValidationErrors.NotAvailable',
      };
      handleGAEventsBase(dataLayer);
    }

    setSubmitting(false);
  };

  const validateForm = (reqBody, formData, token, ip) => {
    // eslint-disable-next-line prettier/prettier
    validateEmail(reqBody, { token, ip })
      .then((res) => handleValidEmailResponse(res, formData))
      .catch(handleErrorResponse);
  };

  const onSubmit = async (data) => {
    let result;

    setSubmitting(true);

    if (process.env.NEXT_PUBLIC_CAPTCHA_DISABLED === 'false') {
      result = await getCaptchaToken();
    }

    // only run request if the captcha passes
    if (result?.token || process.env.NEXT_PUBLIC_CAPTCHA_DISABLED === 'true') {
      const reqBody = {
        email: data.email,
      };

      validateForm(reqBody, data, result?.token, result?.ip);
    } else {
      // TODO: right now do nothing if captcha fails
      setSubmitting(false);
      console.error('ReCaptcha failed');
    }
  };

  const onErrors = (errors) => {
    console.error(errors);
    handleGaSignupPageError(errors);
  };

  return (
    <FormProvider {...formMethods}>
      <form className="relative p-0" onSubmit={formMethods.handleSubmit(onSubmit, onErrors)}>
        <div className="px-4 sm:py-12 mb-10 md:mb-4 sm:px-14">
          <FormHeader stepLabel="" title={title} />
          {children}
        </div>

        <FormFooter
          buttonText={nextLabel}
          buttonLoadingText={nextLabelLoading}
          linkText={signInLabel}
          submitting={submitting}
          languageCode={languageCode}
        />
      </form>
    </FormProvider>
  );
}

RegisterStep1FormLayoutGK.propTypes = {
  children: PropTypes.array,
  props: PropTypes.object,
  title: PropTypes.string,
  nextLabel: PropTypes.string,
  nextLabelLoading: PropTypes.string,
  signInLabel: PropTypes.string,
  errorsList: PropTypes.array,
  languageCode: PropTypes.string,
};
