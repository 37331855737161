import { useStateMachine } from 'little-state-machine';
import Image from 'next/image';

// Components
import StepIndicator from './StepIndicator';

import { FormHeaderWrapper, FormRegisterTitle } from '../FormStyles';

export default function FormHeader({ stepLabel, title, currentStep, totalSteps, subtitle }) {
  const { state } = useStateMachine();

  return (
    <FormHeaderWrapper>
      {state.user.step > 0 && stepLabel && currentStep && totalSteps && (
        <StepIndicator stepLabel={stepLabel} currentStep={currentStep} totalSteps={totalSteps} />
      )}
      {currentStep > 1 && currentStep === totalSteps && (
        <div className="mb-6 mx-auto">
          <Image
            src="/assets/checkmark-green.svg"
            alt=""
            height={54}
            width={54}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </div>
      )}
      {title && <FormRegisterTitle>{title}</FormRegisterTitle>}
      {subtitle && <div className="mt-1">{subtitle}</div>}
    </FormHeaderWrapper>
  );
}
