import PropTypes from 'prop-types';
import Image from 'next/image';

import useDragDetection from '../../hooks/utils/useDragDetection';
import { CardItem, CardHeader, CardImage, CardBody, Brand, CardContents, RewardTitle, RewardBody } from './BankCardStyles';

export default function BankCard({ id, content, offersNum }) {
  const { pointRewards, image, title, body } = content;

  const { handleMouseDown } = useDragDetection();

  return (
    <CardItem id={id} offersNum={offersNum} className="flex card-item card-content-wrapper">
      <CardContents className="card-contents" offersNum={offersNum} onMouseDownCapture={handleMouseDown}>
        <CardHeader offersNum={offersNum} className="w-full">
          <CardImage className="relative w-full h-full">
            <Image
              src={image.url}
              alt=""
              loading="eager"
              aria-hidden
              fill
              sizes="(max-width: 1400px) 800px"
              style={{
                objectFit: 'cover',
                objectPosition: '50% 50%',
              }}
            />
          </CardImage>
        </CardHeader>
        <CardBody>
          <Brand className="mb-2"> {pointRewards}</Brand>
          <RewardTitle>{title}</RewardTitle>
          <RewardBody>{body}</RewardBody>
        </CardBody>
      </CardContents>
    </CardItem>
  );
}

BankCard.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  offersNum: PropTypes.number.isRequired,
};
