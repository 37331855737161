import PropTypes from 'prop-types';

import Image from 'next/image';
import { useRouter } from 'next/router';

// Components
import Button from '../../common/Button/Button';

import { EmptyPointsStyle, EmptyTransacImage } from './PointsHistoryStyles';

export default function EmptyPointsList({ customData }) {
  const { image, noTransactionsTitle, noTransactionsMessage, goToOffersLink } = customData;
  const router = useRouter();

  return (
    <EmptyPointsStyle>
      <div>
        {image && (
          <EmptyTransacImage>
            <Image
              src={image?.url}
              width={200}
              height={135}
              role="presentation"
              alt={image?.label || ''}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </EmptyTransacImage>
        )}
        {noTransactionsTitle && <p className="empty-heading-text font-bold my-2 mt-6">{noTransactionsTitle}</p>}
        {noTransactionsMessage && <p className="empty-sub-text mb-6 mx-auto">{noTransactionsMessage}</p>}
      </div>

      {goToOffersLink && (
        <Button onClick={() => router.push(goToOffersLink.href)} className="empty-transac-button">
          {goToOffersLink.text}
        </Button>
      )}
    </EmptyPointsStyle>
  );
}

EmptyPointsList.propTypes = {
  customData: PropTypes.object,
};
