import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';

import { useStateMachine } from 'little-state-machine';
import { useFormContext } from 'react-hook-form';
import { updateUser } from '../../../store/actions';
import ErrorMessage from '../../common/Errors/ErrorMessage';
import FieldLayout from '../../layouts/FieldLayout';

import { validateFieldError } from '../../../helpers/formHelpers';

import { ShowPasswordWrapper, StyledPasswordInput } from '../FormStyles';

function ConfirmInput({
  name,
  label,
  fieldType,
  minLength,
  maxLength,
  rules,
  fieldWatch,
  errorMessage,
  showLabel,
  hideLabel,
  onClick,
  onFocus,
}) {
  const { actions, state } = useStateMachine({ updateUser });
  // eslint-disable-next-line no-unused-vars
  const [inputVal, setInputVal] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const formContext = useFormContext({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { register, formState, setValue, watch } = formContext;
  const fieldToWatch = watch(fieldWatch);
  const error = formState?.errors?.[name];
  const isError = validateFieldError(formState, name);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      setShowPassword(!showPassword);
    }
  };

  useEffect(() => {
    setValue(name, inputVal, {
      shouldValidate: true,
      shouldDirty: true,
    });
    actions.updateUser({ [name]: inputVal });
  }, [inputVal]);

  return (
    <FieldLayout>
      {Boolean(label) && (
        <label id={`form-${name}`} htmlFor={name}>
          {label}
        </label>
      )}
      <div className="relative">
        <StyledPasswordInput
          onFocus={onFocus}
          onClick={onClick}
          name={name}
          id={name}
          type={showPassword ? 'text' : fieldType}
          aria-invalid={!!isError}
          aria-labelledby={`form-${name}`}
          aria-required
          minLength={minLength || ''}
          maxLength={maxLength || ''}
          defaultValue={state.user?.[name]}
          className={`${(error && formState.touchedFields?.[name]) || (error && formState.isSubmitted) ? 'field-has-error' : ''}`}
          {...register(name, {
            ...rules,
            validate: (value) => value === fieldToWatch || errorMessage,
          })}
        />
        {isError && <ErrorMessage error={error} />}

        <ShowPasswordWrapper role="button" onClick={() => setShowPassword(!showPassword)} onKeyDown={handleKeyDown} tabIndex="0">
          {showPassword ? (
            <Image
              src="/assets/eye-icon-cross.svg"
              width={30}
              height={30}
              alt={hideLabel}
              style={{
                maxWidth: '100%',
                height: 'auto',
                margin: 'auto 0.5rem',
              }}
            />
          ) : (
            <Image
              src="/assets/eye-icon.svg"
              width={30}
              height={20}
              alt={showLabel}
              style={{
                maxWidth: '100%',
                height: 'auto',
                margin: 'auto 0.5rem',
              }}
            />
          )}
        </ShowPasswordWrapper>
      </div>
    </FieldLayout>
  );
}

ConfirmInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  fieldType: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  rules: PropTypes.object,
  fieldWatch: PropTypes.string,
  errorMessage: PropTypes.string,
  showLabel: PropTypes.string,
  hideLabel: PropTypes.string,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
};

export default ConfirmInput;
