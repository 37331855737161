import LinkComponent from '../Links/LinkComponent';
import { isEmpty } from '../../../helpers/utils';
import { StyledBanner } from './JoinSceneStyles';

export default function JoinScene({ fields, languageCode }) {
  if (!isEmpty(fields)) {
    const data = [
      {
        title: fields.joinTitle,
        message: fields.joinMessage,
        link: fields.joinLink,
        label: fields.joinLinkLabel,
        loading: fields.joinLinkLabelLoading,
      },
      {
        title: fields.questionsTitle,
        message: fields.questionsMessage,
        link: fields.questionsLink,
        label: fields.questionsLinkLabel,
        loading: fields.questionsLinkLabelLoading,
      },
    ];
    return (
      <StyledBanner>
        {data &&
          data.map((item, index) => (
            <div key={index} className="w-full md:w-2/4 md:px-3 py-2">
              <div
                className="w-full py-6 px-5 lg:px-8 md:py-10 lg:px-10 lg:py-12 h-full
                              border border-solid border-black border-opacity-20"
              >
                <h2 className="headline-medium mb-3 md:mb-6">{item.title}</h2>
                <p className="mb-3 md:mb-6">{item.message}</p>
                <LinkComponent
                  href={item.link.href}
                  target={item.link.target}
                  style={{ fontSize: '1rem', maxWidth: 'inherit' }}
                  buttonLoadingText={item.loading}
                  buttonStyle="primary-button"
                  ariaLabel={item.link.text}
                  className="mobile-w-full"
                  locale={languageCode}
                >
                  {item.label}
                </LinkComponent>
              </div>
            </div>
          ))}
      </StyledBanner>
    );
  }

  return null;
}
