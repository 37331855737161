import { useState, useEffect } from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import Link from 'next/link';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateOffers, updateNotification } from '../../../store/actions';

// Components
import LinkComponent from '../Links/LinkComponent';
import TermsComponent from '../Terms/TermsComponent';
import LoadOfferButton from '../Button/LoadOfferButton';

// Helpers
import { formatDate } from '../../../helpers/dateHelpers';
import { updateOptedInStatusFrom } from '../../../helpers/offersHelpers';
import {
  handleGAEvents,
  handleGaEcommImpression,
  getOffersModalMappedObject,
} from '../../../helpers/handleGoogleAnalyticsHelper';
import { handleKeyDown } from '../../../helpers/utils';

// Services
import { loadOffers } from '../../../pages/api/services';

// Styles
import { ModalPopupContainer, ModalPopupHeader, ModalPopupBody } from './ModalStylesV2';
import { ModalLoadOfferIcon } from '../Cards/CardStyles';

export default function OffersCardModal({ offer, customData, languageCode, sectionTitle, listName, id, isSpotlight }) {
  const [loading, setLoading] = useState(false);

  const {
    loadingLabel,
    loadOfferLabel,
    messageOfferLoaded,
    messageOfferNotLoaded,
    messageOfferUnloaded,
    unloadingLabel,
    offerLoadedLabel,
    offerPreloadedLabel,
    getCouponLabel,
  } = customData;

  const { brand, title, details, cta, termsAndConditions, isCouponBased, getCouponLink } = offer?.cmsContent;

  const {
    actions,
    state: {
      session: { accessToken },
      offers: {
        offersList,
        selectedFilters,
        limitedTimeOffersList,
        offersForYouList,
        moreOffersList,
        moreWaysToEarn,
        featuredOffer,
      },
      labels,
      location,
    },
  } = useStateMachine({ updateOffers, updateNotification });

  const status = offer && offer.isOptedIn;
  const isLoadable = offer?.tags && offer.tags.includes('loadable');
  const isEvergreen = offer?.tags && offer.tags.includes('evergreen');

  useEffect(() => {
    if (!isSpotlight) {
      handleGaEcommImpression(
        'offer_view_item',
        getOffersModalMappedObject(
          offer,
          parseInt(id?.split('-')[1]) + 1,
          listName || '',
          'Offers Detail View',
          'offers_detail_view',
          location?.province
        )
      );
    }
  }, []);

  const handleOfferLoadUnload = async (e) => {
    e.persist();
    try {
      if (status) {
        handleGaEcommImpression(
          'offer_remove_from_cart',
          getOffersModalMappedObject(
            offer,
            parseInt(id?.split('-')[1]) + 1,
            sectionTitle,
            'Offer unloaded',
            'offers_unload_offer_tile',
            location?.province
          )
        );
      } else {
        handleGaEcommImpression(
          'offer_add_to_cart',
          getOffersModalMappedObject(
            offer,
            parseInt(id?.split('-')[1]) + 1,
            sectionTitle,
            'Offer Loaded',
            'offers_load_on_tile',
            location?.province
          )
        );
      }
    } catch (err) {
      console.error(err);
    }

    setLoading(true);
    const reqBody = [{ offerId: offer.offerId, optIn: !offer.isOptedIn }];

    const response = await loadOffers(reqBody, accessToken);
    if (response?.data?.data === true) {
      const offerClicked = { ...offer };
      // find all instances where the loaded offer would be located.
      if (selectedFilters === 'featured') {
        // TODO: optimize this with a conditional helper that will cherry pick the arrays based on the tags/categories of the offer (See derive offers from helper)
        actions.updateOffers({
          limitedTimeOffersList: updateOptedInStatusFrom(limitedTimeOffersList, offerClicked),
          offersForYouList: updateOptedInStatusFrom(offersForYouList, offerClicked),
          moreOffersList: updateOptedInStatusFrom(moreOffersList, offerClicked),
          moreWaysToEarn: updateOptedInStatusFrom(moreWaysToEarn, offerClicked),
          featuredOffer: updateOptedInStatusFrom(featuredOffer, offerClicked),
        });
      } else {
        actions.updateOffers({ offersList: updateOptedInStatusFrom(offersList, offerClicked) });
      }

      actions.updateNotification({
        message: offer.isOptedIn ? messageOfferLoaded : messageOfferUnloaded,
        status: offer.isOptedIn ? 'success' : 'warning',
      });
    } else {
      actions.updateNotification({
        message: messageOfferNotLoaded,
        status: 'error',
      });
      handleGAEvents(`${offer.cmsContent.brand.title} | ${title}`, 'Partner Offers', 'Offer Loaded Fail', 'offers_loaded_fail');
    }
    setLoading(false);
  };
  const handleCouponBasedOfferClick = (e) => {
    handleGaEcommImpression(
      'offer_add_to_cart',
      getOffersModalMappedObject(
        offer,
        parseInt(id?.split('-')[1]) + 1,
        sectionTitle,
        'Offer Loaded',
        'offers_load_on_tile',
        location?.province
      )
    );
  };
  function setLoadMessage() {
    if (isEvergreen) return offerPreloadedLabel;
    // if not loadable, we can just show a standard label
    if (!isLoadable) return offerLoadedLabel;

    // Manage the labels based on the button state
    if (loading) {
      if (status) {
        return unloadingLabel;
      }
      return loadingLabel;
    }

    if (status) {
      return offerLoadedLabel;
    }

    return loadOfferLabel;
  }

  return (
    <ModalPopupContainer>
      <ModalPopupHeader>
        {brand && (
          <h1 className="brand-title" id="modal-label">
            {brand.title}
          </h1>
        )}
        {title && <h2 className="card-title">{title}</h2>}
      </ModalPopupHeader>
      <ModalPopupBody>
        {details && (
          <p>
            <strong>{labels?.detailsLabel} </strong> {details}
          </p>
        )}

        {offer.endDate && (
          <p className="expiry-date">
            {labels?.expiresLabel} {formatDate(offer.endDate, 'MMMM DD, YYYY', languageCode)}
          </p>
        )}

        {!isCouponBased && (
          <LoadOfferButton
            type="submit"
            buttonLoadingText={loadingLabel}
            aria-label={status ? offerLoadedLabel : loadOfferLabel}
            className="my-8"
            onClick={handleOfferLoadUnload}
            isLoadable={isLoadable}
            loaded={status}
          >
            {setLoadMessage()}

            <ModalLoadOfferIcon className="offer-load-icon loadable-offer" loaded={status || isEvergreen}>
              <Image
                src={status || !isLoadable || isEvergreen ? `/assets/checkmark-sharp.svg` : `/assets/cross-white-icon.svg`}
                alt={status ? offerLoadedLabel : loadOfferLabel}
                width={18}
                height={18}
                q={25}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </ModalLoadOfferIcon>
          </LoadOfferButton>
        )}

        {isCouponBased && (
          <Link
            href={getCouponLink?.href}
            tabIndex="0"
            role="link"
            style={{ textDecoration: 'none' }}
            target={getCouponLink?.target}
            onClick={handleCouponBasedOfferClick}
            onKeyDown={(e) => handleKeyDown(e, handleCouponBasedOfferClick)}
          >
            <LoadOfferButton
              type="link"
              buttonLoadingText={loadingLabel}
              aria-label={getCouponLabel}
              className="my-8"
              isCouponBased={isCouponBased}
            >
              {getCouponLabel}

              <ModalLoadOfferIcon className="offer-load-icon loadable-offer" loaded={status || isEvergreen}>
                <Image
                  src="/assets/arrow-right-white.svg"
                  alt={getCouponLabel}
                  width={18}
                  height={18}
                  q={25}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </ModalLoadOfferIcon>
            </LoadOfferButton>
          </Link>
        )}

        {cta?.href && cta?.text && (
          <div className="my-8">
            <LinkComponent
              href={cta.href}
              target={cta.target || '_blank'}
              className="color-black font-bold mb-4"
              style={{ fontSize: '0.875rem', maxWidth: 'inherit' }}
              buttonLoadingText={loadingLabel}
              locale={languageCode}
            >
              {cta.text}
            </LinkComponent>
          </div>
        )}
      </ModalPopupBody>
      {termsAndConditions && (
        <TermsComponent termsBlob={termsAndConditions} scrollable termsAndConditionsLabel={labels?.termsAndConditionsLabel} />
      )}
    </ModalPopupContainer>
  );
}

OffersCardModal.propTypes = {
  offer: PropTypes.object,
  customData: PropTypes.object,
  languageCode: PropTypes.string,
  sectionTitle: PropTypes.string,
  listName: PropTypes.string,
  id: PropTypes.string,
  isSpotlight: PropTypes.object,
};
