import PropTypes from 'prop-types';

// Components
import WrapperLayout from '../../layouts/WrapperLayout';
import FeatureBanner from '../../common/Banners/FeatureBanner';

// Helpers
import { checkArrayForItems } from '../../../helpers/utils';

export default function OffersSpotlight({ customData, expireLabel, title, languageCode, banners, isLightBlack, bannerType }) {
  if (checkArrayForItems(banners)) {
    return (
      <WrapperLayout className="global-content-wrapper" isLightBlack={isLightBlack} customStyles="offersPage">
        <FeatureBanner
          page="Offers"
          banners={banners}
          languageCode={languageCode}
          title={title}
          expireLabel={expireLabel}
          customData={customData}
          isLightBlack={isLightBlack}
          bannerType={bannerType}
        />
      </WrapperLayout>
    );
  }
  return null;
}

OffersSpotlight.propTypes = {
  customData: PropTypes.object,
  expireLabel: PropTypes.string,
  title: PropTypes.string,
  languageCode: PropTypes.string,
  banners: PropTypes.array,
  isLightBlack: PropTypes.bool,
  bannerType: PropTypes.string,
};
