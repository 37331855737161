import Image from 'next/image';

import { StyledListHome, ImageCircle, RewardTypeTitle } from './ContentGridStyles';

export default function ContentGrid3Columns({ data }) {
  if (data) {
    return (
      <StyledListHome>
        {data.map((item, index) => (
          <li key={index} className="list-none  text-center">
            <ImageCircle className="mx-auto category-circle-tile">
              {' '}
              <Image
                className="w-full h-full object-cover"
                src={item.fields.image.url}
                alt=""
                width={80}
                height={80}
                quality={75}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />{' '}
            </ImageCircle>
            <RewardTypeTitle className="headline-extra-small">{item.fields.title}</RewardTypeTitle>
            <p>{item.fields.description}</p>
          </li>
        ))}
      </StyledListHome>
    );
  }
  return null;
}
