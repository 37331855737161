import Image from 'next/image';

export default function FooterLogo({ isMobile, footer }) {
  const { footerLogo } = footer.fields;

  if (footerLogo) {
    return (
      <div className={`footer-site-logo ${isMobile ? 'mobile-footer-logo' : 'tablet-desktop'}`}>
        <Image
          src={footerLogo.url}
          alt={footerLogo.label}
          width={48}
          height={48}
          quality={75}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      </div>
    );
  }
  return null;
}
