/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';
import { useFormContext } from 'react-hook-form';

// Components
import TransactionError from '../../../common/Errors/TransactionError';
import AddedList from '../AddedList';
import Button from '../../../common/Button/Button';

// Helpers
import { handleGAEvents, stringConcatHelper, spendPointsForCreditEcomm } from '../../../../helpers/handleGoogleAnalyticsHelper';
import { formatToCurrency } from '../../../../helpers/utils';
import {
  formatPoints,
  calcPointsNeeded,
  calcPointsTotal,
  amountSelected,
  calcTotalDollar,
} from '../../../../helpers/pointsHelpers';

import {
  SummaryWrapper,
  AddedItems,
  SummaryRow,
  TotalBalance,
  SummaryInner,
  SummaryHeader,
  SummaryHeading,
} from '../PointsShopStyles';

/**
 *
 * TODO:: balance prop should come from global state, which is going to either be stored on the selectred card or its own key.
 *
 *
 * card is loaded from the cardselect
 * -> we grab the balance and display under remaining points
 * ---> opt 1 --> we track the current balance in local state (global card bal and do math depending on the checkboxes)
 * ---> opt 2 --> rename transactions.balance --> remainingPoints --> and we use that to track what the user has redeemed
 *
 *Criteria--> the remaining points should +/- based on the checked redemtions (total in this component)
 *
 *
 *
 * @param {*} param0
 * @returns
 */

export default function Summary({ customData, page }) {
  const {
    state: {
      points: { added },
      transactions: { balance, awards },
      points: { step },
      language: { locale },
    },
  } = useStateMachine();

  const { clearErrors } = useFormContext();

  const {
    summaryLabel,
    selectedTransactionsLabel,
    continueButtonLabel,
    summaryTotalLabel,
    summaryRemainingPointsLabel,
    notEnoughPointsLabel,
    pts,
    redemptionAmountLabel,
    equalsToLabel,
  } = customData;

  const awardProduct = awards?.awardProduct && awards.awardProduct[0];
  const cartHasItems = added.length;

  // Points Calculations
  const total = calcPointsTotal(added);
  const pointsNeeded = calcPointsNeeded(added, balance);
  const partialRedemption = balance - pointsNeeded < balance;
  const remainingPoints = page === 'byot' && partialRedemption ? 0 : balance - total;
  const disablePFC = awards.pfc?.length ? parseInt(balance) < awards.pfc[0].points : true;

  // Points Validation
  const notEnoughPoints = remainingPoints < 0;

  // Total Dollar Value
  const totalDollar =
    partialRedemption && awardProduct
      ? (parseInt(balance) * (awardProduct?.dollarValue / awardProduct?.points)).toFixed(2)
      : calcTotalDollar(added);

  const gaLabel = stringConcatHelper(total, ' | Continue Click');

  // Disable of button
  let disableButton = false;

  if (pointsNeeded + balance === 0) {
    disableButton = true;
  } else if (total <= 0) {
    disableButton = true;
  } else if (balance === 0) {
    disableButton = true;
  } else if (page === 'pfc' && disablePFC) {
    disableButton = true;
  } else if (page === 'byot' && partialRedemption) {
    disableButton = false;
  } else {
    disableButton = false;
  }

  useEffect(() => {
    if (total && remainingPoints > 0) {
      clearErrors();
    }
  }, [remainingPoints]);

  // Google Analytics only
  const handleChange = () => {
    handleGAEvents(gaLabel, 'Redeem', 'Continue Points For Credit Click', 'redeem_credit_continue_click');

    try {
      if (page === 'pfc') {
        const awardProductCodePFC = added
          ? added
              .filter((award) => award.id === awards.awardProduct.id)
              .map((items) => items.code)
              .toString()
          : '';
        spendPointsForCreditEcomm(
          'p4c_add_to_cart',
          'Points For Credit',
          total,
          `${amountSelected(added)}`,
          awardProductCodePFC,
          'redeem_credit_continue_click',
          'Points For Credit Add To Cart',
          totalDollar
        );
      } else if (page === 'byot') {
        const awardProductCodeBYOT = awards
          ? awards.awardProduct.filter((award) => award.id === added.id).map((items) => items.code)
          : '';
        spendPointsForCreditEcomm(
          'byot_add_to_cart',
          'Points For Travel',
          total,
          total,
          awardProductCodeBYOT[0],
          'redeem_travel_continue_click',
          'Points For Travel Add To Cart',
          totalDollar
        );
      }
    } catch {
      //
    }
  };

  const redemptionAmount = () => {
    if (!balance) return ` ${formatPoints(parseInt(0), locale)} ${pts || 'pts'}`;

    if (page === 'byot') {
      if (partialRedemption) {
        return ` ${formatPoints(parseInt(balance), locale)} ${pts || 'pts'}`;
      }
    }

    return ` ${formatPoints(parseInt(total), locale)} ${pts || 'pts'}`;
  };

  return (
    <SummaryWrapper className="flex-1" page={page} hasBYOTTransactions={awards?.byot?.length > 0}>
      <SummaryInner cartHasItems={cartHasItems}>
        {page === 'byot' && summaryLabel && (
          <SummaryHeader className="hidden md:block" cartHasItems={cartHasItems}>
            {summaryLabel && <SummaryHeading className="font-bold">{summaryLabel}</SummaryHeading>}
            <p className="font-bold selected-transactions">
              {selectedTransactionsLabel.charAt(0).toUpperCase() + selectedTransactionsLabel.slice(1)} ({cartHasItems})
            </p>
          </SummaryHeader>
        )}

        {page === 'byot' && selectedTransactionsLabel && (
          <AddedItems className="hidden md:block">
            <AddedList customData={customData} />
          </AddedItems>
        )}

        <TotalBalance cartHasItems={cartHasItems}>
          <SummaryRow>
            {/* Total points required */}
            <p className={`transition-all font-bold${page === 'pfc' && notEnoughPoints ? ' error-red' : ''}`}>
              {summaryTotalLabel || 'Redeem Total'}
            </p>
            {/* Value for Total points required */}
            <p className={`points-total transition-all${page === 'pfc' && notEnoughPoints ? ' error-red' : ''}`}>
              {total || total === 0 ? `${formatPoints(total, locale)} ${pts || 'pts'}` : 0}
            </p>
          </SummaryRow>

          {page === 'byot' && (
            <>
              <SummaryRow className="!mb-1" style={{ marginBottom: '0 !important' }}>
                {/* Partial Redemption Balance  */}
                {redemptionAmountLabel && (
                  <>
                    <p className="font-bold">{redemptionAmountLabel}</p>
                    <p className="font-bold md:font-normal">{redemptionAmount()}</p>
                  </>
                )}
              </SummaryRow>

              <SummaryRow className="!mt-0">
                {/* Points Dollar Value */}
                {equalsToLabel && (
                  <p className="md:font-normal !text-xs">
                    {equalsToLabel} <span className="font-bold">{formatToCurrency(totalDollar, locale)}</span>
                  </p>
                )}
              </SummaryRow>

              <SummaryRow className="mb-0">
                {/* Points Balance  */}
                {summaryRemainingPointsLabel && <p className="font-bold">{summaryRemainingPointsLabel}</p>}
                {/* Points Balance Value */}
                <p className="font-bold md:font-normal">
                  {balance ? ` ${formatPoints(remainingPoints, locale)} ${pts || 'pts'}` : 0}
                </p>
              </SummaryRow>
            </>
          )}
        </TotalBalance>

        <Button
          type="submit"
          buttonText={continueButtonLabel}
          ariaLabel={continueButtonLabel}
          disabled={Boolean(disableButton)}
          className={`${step > 1 ? 'w-full' : ''}`}
          style={{ marginTop: '1rem', width: '100%' }}
          onClick={handleChange}
        >
          {continueButtonLabel}
        </Button>

        {page === 'pfc' && notEnoughPoints && <TransactionError error={{ message: notEnoughPointsLabel }} simplified />}
      </SummaryInner>
    </SummaryWrapper>
  );
}

Summary.propTypes = {
  page: PropTypes.string,
  customData: PropTypes.object,
};
