import Image from 'next/image';
import Link from 'next/link';

import ButtonIcon from '../../../Button/ButtonIcon';

import { HeaderColumn, Icon, Section } from '../HeaderStyles';

export default function HeaderMoreLink({
  isLoggedIn,
  moreLinkLabel,
  moreLinks,
  anonymousMoreLinks,
  refMore,
  languageCode,
  openMore,
  setOpenMore,
}) {
  const hide = () => setOpenMore && setOpenMore(false);
  const show = () => setOpenMore && setOpenMore(!openMore);

  // Handle closing menu items with keypress
  const handleKeyDown = (e) => {
    if (e.key === 'Tab' || e.key === 'Escape') {
      hide();
    }
  };

  const handleTabKeyDown = (e) => {
    if (e.keyCode === 9) {
      if (e.shiftKey) {
        setOpenMore(false);
      } else {
        setOpenMore(true);
      }
    }
  };

  return (
    <HeaderColumn className="flex-grow tabletHide header-more-link">
      <Section ref={refMore}>
        <ButtonIcon
          type="button"
          className="header-link"
          onClick={show}
          onKeyDown={handleTabKeyDown}
          ariaHaspopup="true"
          ariaControls="menuMore"
          ariaLabel={moreLinkLabel}
          ariaExpanded={openMore}
        >
          {moreLinkLabel}
          <Icon className="ml-2">
            <Image
              src={openMore ? '/assets/chevron-up-white.svg' : '/assets/chevron-down-white.svg'}
              alt=""
              height={15}
              width={15}
              aria-hidden
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Icon>
        </ButtonIcon>

        {openMore && (
          <div
            id="menuMore"
            className="flyout-menu left-0"
            style={{ display: openMore ? 'block' : 'none' }}
            aria-hidden={!openMore}
            aria-expanded={openMore}
          >
            {isLoggedIn
              ? moreLinks.map((navitem, index, { length }) => (
                  <Link
                    href={navitem.fields.page.href}
                    key={index}
                    locale={languageCode}
                    role="link"
                    onClick={hide}
                    onBlur={index === length - 1 ? hide : null}
                    onKeyDown={index === length - 1 ? handleKeyDown : null}
                    tabIndex="0"
                  >
                    {navitem.fields.title}
                  </Link>
                ))
              : anonymousMoreLinks.map((navitem, index, { length }) => (
                  <Link
                    href={navitem.fields.page.href}
                    key={index}
                    locale={languageCode}
                    role="link"
                    onClick={hide}
                    onBlur={index === length - 1 ? hide : null}
                    onKeyDown={index === length - 1 ? handleKeyDown : null}
                    tabIndex="0"
                  >
                    {navitem.fields.title}
                  </Link>
                ))}
          </div>
        )}
      </Section>
    </HeaderColumn>
  );
}
