import Slider from 'react-slick';
import PropTypes from 'prop-types';
// Components
import { Section, CardCarouselWrapper } from './BannerStyles';
import BankCard from '../Cards/BankCard';

// Helpers
import { checkArrayForItems } from '../../../helpers/utils';
import { cardSliderSettings } from '../../../helpers/bannersHelper';

const BankCardsSlider = ({ slides }) => {
  const offersNum = slides.length;

  const settings = cardSliderSettings(offersNum);

  if (checkArrayForItems(slides)) {
    return (
      <Section>
        <CardCarouselWrapper offersNum={offersNum}>
          <Slider {...settings}>
            {slides.map((item, index) => (
              <BankCard id={`card-${index}`} key={index} content={item.fields} offersNum={offersNum} />
            ))}
          </Slider>
        </CardCarouselWrapper>
      </Section>
    );
  }
  return null;
};
BankCardsSlider.propTypes = { slides: PropTypes.array };
export default BankCardsSlider;
