import styled, { css } from 'styled-components';

export const StyledButton = styled.button`
  width: auto;
  overflow: hidden;
  margin-bottom: 2px;
  border-radius: 0;
  position: relative;
  min-width: 200px;
  transition: all 0.6s ease-in;

  span {
    position: relative;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
  }

  &.primary-button {
    background-color: ${(props) => props.theme.uiPurple};
    color: ${(props) => props.theme.whiteColor};
    border-radius: 0;
    border: 1px solid ${(props) => props.theme.uiPurple};
    outline: none;
    margin-bottom: 5px;
    margin-left: 0;

    &:after {
      content: '';
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0px;
      height: 0px;
      background-color: ${(props) => props.theme.uiDarkPurple};
      transform: translateY(-50%);
      transition: all 0.6s ease-in;
    }

    &:hover,
    &:focus {
      box-shadow: none;
    }

    &:hover {
      background-color: ${(props) => props.theme.uiDarkPurple};
      border: 1px solid ${(props) => props.theme.uiDarkPurple};
      outline: none;
    }

    &:focus {
      background-color: ${(props) => props.theme.uiDarkPurple};
      outline: 3px solid ${(props) => props.theme.uiPurple};
      outline-offset: 2px;
      border: 1px solid ${(props) => props.theme.uiPurple};

      &:after {
        width: 1200px;
        height: 1200px;
      }
    }

    &.disabled {
      color: ${(props) => props.theme.uiGrey04};
      background-color: ${(props) => props.theme.uiGrey02};
      border: 1px solid ${(props) => props.theme.uiGrey02};

      &:hover {
        &:after {
          width: 0px;
          height: 0px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 1.5rem;
      &.mt-0 {
        margin-top: 0;
      }
      margin-left: 0;
      padding: 1rem 2rem;
      &.primary-button {
        width: 100%;
        margin-left: 0px;
      }
    }

    &.register-primary-button {
      margin: 0;
      width: unset;
      min-width: 11rem;
      @media screen and (max-width: 640px) {
        min-width: 8rem;
      }
      @media screen and (min-width: 641px) and (max-width: 1023px) {
        min-width: 9.5rem;
      }
    }
  }

  &.secondary-button-black {
    background-color: ${(props) => props.theme.whiteColor};
    color: ${(props) => props.theme.uiCharcoal};
    font-weight: bold;
    width: auto;
    border: 1px solid ${(props) => props.theme.uiCharcoal};

    &:after {
      content: '';
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0px;
      height: 0px;
      color: ${(props) => props.theme.uiCharcoal};
    }

    &:hover {
      color: ${(props) => props.theme.uiDarkPurple};
      background-color: ${(props) => props.theme.whiteColor};
      border: 1px solid ${(props) => props.theme.uiCharcoal};
    }

    &:focus {
      outline: 0;
      box-shadow: none;
      border: 1px solid ${(props) => props.theme.uiDarkPurple};
      outline: 1px solid ${(props) => props.theme.uiDarkPurple};
      color: ${(props) => props.theme.uiDarkPurple};

      &:after {
        width: 1200px;
        height: 1200px;
      }
    }

    &.transparent-bg {
      background-color: transparent;
    }
  }

  &.secondary-button-outline-purple {
    background-color: ${(props) => props.theme.whiteColor};
    color: ${(props) => props.theme.uiPurple};
    font-weight: bold;
    width: auto;
    border: 1px solid ${(props) => props.theme.uiPurple};

    &:after {
      content: '';
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0px;
      height: 0px;
      color: ${(props) => props.theme.uiDarkPurple};
    }

    &:hover {
      color: ${(props) => props.theme.uiDarkPurple};
      background-color: ${(props) => props.theme.whiteColor};
      border: 1px solid ${(props) => props.theme.uiDarkPurple};
    }

    &:focus {
      outline: 0;
      box-shadow: none;
      border: 1px solid ${(props) => props.theme.uiPurple};
      outline: 1px solid ${(props) => props.theme.uiPurple};
      color: ${(props) => props.theme.uiDarkPurple};

      &:after {
        width: 1200px;
        height: 1200px;
      }
    }

    &.transparent-bg {
      background-color: transparent;
    }
  }
  &.secondary-button-black {
    background-color: ${(props) => props.theme.whiteColor};
    color: ${(props) => props.theme.uiCharcoal};
    font-weight: bold;
    width: auto;
    border: 1px solid ${(props) => props.theme.uiCharcoal};

    &:after {
      content: '';
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0px;
      height: 0px;
      color: ${(props) => props.theme.uiCharcoal};
    }

    &:hover {
      color: ${(props) => props.theme.uiPurple};
      background-color: ${(props) => props.theme.whiteColor};
      border: 1px solid ${(props) => props.theme.uiPurple};
    }

    &:focus {
      outline: 0;
      box-shadow: none;
      border: 1px solid ${(props) => props.theme.uiDarkPurple};
      outline: 1px solid ${(props) => props.theme.uiDarkPurple};
      color: ${(props) => props.theme.uiDarkPurple};

      &:after {
        width: 1200px;
        height: 1200px;
      }
    }

    &.transparent-bg {
      background-color: transparent;
    }
  }

  &.secondary-button-outline-purple-dark {
    background-color: ${(props) => props.theme.whiteColor};
    color: ${(props) => props.theme.uiDarkPurple};
    font-weight: bold;
    width: auto;
    border: 1px solid ${(props) => props.theme.uiDarkPurple};

    &:after {
      content: '';
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0px;
      height: 0px;
      color: ${(props) => props.theme.uiDarkPurple};
    }

    &:hover {
      color: ${(props) => props.theme.uiPurple};
      background-color: ${(props) => props.theme.whiteColor};
      border: 1px solid ${(props) => props.theme.uiPurple};
    }

    &:focus {
      outline: 0;
      box-shadow: none;
      border: 1px solid ${(props) => props.theme.uiDarkPurple};
      outline: 1px solid ${(props) => props.theme.uiDarkPurple};
      color: ${(props) => props.theme.uiDarkPurple};

      &:after {
        width: 1200px;
        height: 1200px;
      }
    }

    &.transparent-bg {
      background-color: transparent;
    }
  }

  &.button-large {
    padding: 1rem 2rem;
  }
  &.load-offer-btn {
    padding-right: 30px;

    &:after {
      content: '';
      width: 15px;
      height: 15px;
      background-color: transparent;
      background-image: url('/assets/cross-white-icon.svg');
      background-repeat: no-repeat;
      background-position: right center;
      right: 15%;
      left: auto;
    }
  }

  @media screen and (max-width: 767px) {
    font-size: ${(props) => props.theme.fontBody2};
    padding: 0.5rem 1.5rem;
  }
  ${(props) =>
    props.extraSpacingTop &&
    css`
      @media screen and (max-width: 640px) {
        margin-top: 5.43rem !important;
        margin-bottom: 0rem !important;
      }
    `};
`;
export const StyledButtonIcon = styled.button`
  cursor: pointer;
  width: auto;
  max-width: 14rem;
  margin-bottom: 2px;
  text-align: center;
  background-color: transparent;
  padding: 0.25rem 0;
  border-radius: 0;

  &.header-link {
    color: ${(props) => props.theme.whiteColor};
    font-size: ${(props) => props.theme.introduction};
    font-weight: normal;
    @media screen and (max-width: 767px) {
      font-size: ${(props) => props.theme.fontBody3};
    }
    margin-bottom: 0;
    img {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }
  &.header-link-map {
    color: ${(props) => props.theme.whiteColor};
    font-size: ${(props) => props.theme.introduction};
    font-weight: normal;

    @media screen and (max-width: 1439px) {
      font-size: ${(props) => props.theme.fontBody3};
    }
    margin-bottom: 0;
    img {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }

  &.header-link-signin {
    color: ${(props) => props.theme.whiteColor};
    font-size: ${(props) => props.theme.introduction};
    font-weight: normal;
    margin-bottom: 0;
    img {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }
  &.header-link-bold {
    color: ${(props) => props.theme.whiteColor};
    font-size: ${(props) => props.theme.introduction};
    font-weight: bold;
    @media screen and (max-width: 767px) {
      font-size: ${(props) => props.theme.fontBody3};
    }
    margin-bottom: 0;
    img {
      margin-left: 0.25rem;
    }
  }
`;
export const CustomStyledButtonIcon = styled.button`
  cursor: pointer;
  width: 48px;
  height: 48px;
  max-width: 14rem;
  text-align: center;
  background-color: transparent;
  padding: 0.25rem 0;
  border-radius: 0;

  &.header-link {
    color: ${(props) => props.theme.whiteColor};
    font-size: ${(props) => props.theme.introduction};
    font-weight: normal;
    @media screen and (max-width: 767px) {
      font-size: ${(props) => props.theme.fontBody3};
    }
    margin-bottom: 0;
    img {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }

  &.header-link-bold {
    color: ${(props) => props.theme.whiteColor};
    font-size: ${(props) => props.theme.introduction};
    font-weight: bold;
    @media screen and (max-width: 767px) {
      font-size: ${(props) => props.theme.fontBody3};
    }
    margin-bottom: 0;
    img {
      margin-left: 0.25rem;
    }
  }
`;
export const CustomStyledInner = styled.div`
  ${(props) =>
    props.buttonType === 'close' &&
    css`
      display: flex;
      max-width: 24px;
      margin: 0 auto;
    `};
`;
export const StyledButtonSimple = styled.button`
  position: relative;
  max-width: 14rem;
  width: auto;
  overflow: hidden;
  margin-bottom: 2px;
  text-align: center;
  background-color: transparent;
  padding: 0.25rem 0 0 0;
  color: ${(props) => props.theme.uiCharcoal};
  border-radius: 0;
  line-height: 1;
  letter-spacing: normal;

  &.top-spacing {
    margin-top: 1.25rem;
  }

  @media screen and (max-width: 767px) {
    &.spacing-sm {
      margin: 1rem 0;
    }
  }

  &:after {
    content: '';
    display: inline-block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid ${(props) => props.theme.uiCharcoal};
    transition: width 0.3s;
  }

  &.header-link {
    color: ${(props) => props.theme.whiteColor};
  }

  &.secondary-button {
    color: ${(props) => props.theme.uiPurple};

    &:after {
      border-bottom: 1px solid ${(props) => props.theme.uiPurple};
    }
  }
`;

export const CustomStyledButton = styled.button`
  width: auto;
  overflow: hidden;
  margin-bottom: 2px;
  border-radius: 0;
  position: relative;
  transition: all 0.6s ease-in;

  span {
    position: relative;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
  }

  &.primary-button {
    background-color: ${(props) => props.theme.uiPurple};
    color: ${(props) => props.theme.whiteColor};
    border-radius: 0;
    border: 1px solid ${(props) => props.theme.uiPurple};
    outline: none;
    margin-bottom: 5px;
    margin-left: 0;

    &:after {
      content: '';
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0px;
      height: 0px;
      background-color: ${(props) => props.theme.uiDarkPurple};
      transform: translateY(-50%);
      transition: all 0.6s ease-in;
    }

    &:hover,
    &:focus {
      box-shadow: none;
    }

    &:hover {
      background-color: ${(props) => props.theme.uiDarkPurple};
      border: 1px solid ${(props) => props.theme.uiDarkPurple};
      outline: none;
    }

    &:focus {
      background-color: ${(props) => props.theme.uiDarkPurple};
      outline: 3px solid ${(props) => props.theme.uiPurple};
      outline-offset: 2px;
      border: 1px solid ${(props) => props.theme.uiPurple};

      &:after {
        width: 1200px;
        height: 1200px;
      }
    }

    &.disabled {
      color: ${(props) => props.theme.uiGrey04};
      background-color: ${(props) => props.theme.uiGrey02};
      border: 1px solid ${(props) => props.theme.uiGrey02};

      &:hover {
        &:after {
          width: 0px;
          height: 0px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 1.5rem;
      &.mt-0 {
        margin-top: 0;
      }
      margin-left: 0;
      &.primary-button {
        width: 100%;
        margin-left: 0px;
      }
    }

    &.register-primary-button {
      margin: 0;
      width: unset;
    }
  }

  &.secondary-button {
    background-color: ${(props) => props.theme.whiteColor};
    color: ${(props) => props.theme.uiPurple};
    font-weight: bold;
    width: auto;
    border: 1px solid ${(props) => props.theme.uiPurple};
    transition: all 0.6s ease-in;

    &:after {
      content: '';
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0px;
      height: 0px;
      background-color: ${(props) => props.theme.uiDarkPurple};
      transform: translateY(-50%);
      transition: all 0.6s ease-in;
    }

    &:hover {
      color: ${(props) => props.theme.uiDarkPurple};
      background-color: ${(props) => props.theme.whiteColor};
      border: 1px solid ${(props) => props.theme.uiDarkPurple};
    }

    &:focus {
      outline: 0;
      box-shadow: none;
      outline: 1px solid ${(props) => props.theme.uiDarkPurple};
      color: ${(props) => props.theme.whiteColor};

      &:after {
        width: 1200px;
        height: 1200px;
      }
    }

    &.transparent-bg {
      background-color: transparent;
    }
  }
  &.button-large {
    padding: 1rem 2rem;
  }
  &.load-offer-btn {
    padding-right: 30px;

    &:after {
      content: '';
      width: 15px;
      height: 15px;
      background-color: transparent;
      background-image: url('/assets/cross-white-icon.svg');
      background-repeat: no-repeat;
      background-position: right center;
      right: 15%;
      left: auto;
    }
  }

  @media screen and (max-width: 767px) {
    font-size: ${(props) => props.theme.fontBody2};
  }
  ${(props) =>
    props.extraSpacingTop &&
    css`
      @media screen and (max-width: 640px) {
        margin-top: 5.43rem !important;
        margin-bottom: 0rem !important;
      }
    `};
`;
