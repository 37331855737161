import { useEffect, useState } from 'react';
import Select from 'react-select';

import { useStateMachine } from 'little-state-machine';
import PropTypes from 'prop-types';
import { updateUser, updateNotification } from '../../../../store/actions';

import { updateAddress, getCustomerAddresses } from '../../../../pages/api/services';

// Helpers
import { generateErrorMessage } from '../../../../helpers/errorsHelper';
import ShippingAddressItem from './ShippingAddressItem';
import { shippingSelectStyles } from './shippingSelectStyles';

export default function ShippingAddressSelect({ shippingList, setAddressList, errorsList, profileContent }) {
  const {
    actions,
    state: {
      user: { selectedShippingAddress, shippingAddresses },
      session: { accessToken },
    },
  } = useStateMachine({ updateNotification, updateUser });

  // Setting the default that filters for what is primary key only
  const shippingDefaultOption =
    shippingAddresses?.length &&
    shippingAddresses
      .filter((option) => option.primary === true)
      .map((addressItem) => ({
        value: addressItem.uniqueId,
        label: <ShippingAddressItem address={addressItem} errorsList={errorsList} profileContent={profileContent} />,
        addressData: addressItem,
      }))[0];

  // Making sure to filter out what is selected and not show in the list
  const shippingOptions =
    shippingAddresses?.length &&
    shippingAddresses
      .filter((option) => option.primary === false && option.uniqueId !== selectedShippingAddress?.addressData?.uniqueId)
      .map((addressItem) => ({
        value: addressItem.uniqueId,
        label: <ShippingAddressItem address={addressItem} errorsList={errorsList} profileContent={profileContent} />,
        addressData: addressItem,
      }));

  const [selectedLabel, setSelectedLabel] = useState(shippingDefaultOption);

  useEffect(() => {
    setSelectedLabel({
      value: selectedShippingAddress?.addressData?.uniqueId,
      label: (
        <ShippingAddressItem
          address={selectedShippingAddress?.addressData}
          errorsList={errorsList}
          profileContent={profileContent}
        />
      ),
      addressData: selectedShippingAddress?.addressData,
    });
  }, [selectedShippingAddress]);

  useEffect(() => {
    // this sets the default to be selected as shippingList  is udpated first
    actions.updateUser({
      selectedShippingAddress: {
        addressData: selectedLabel?.addressData,
      },
    });
  }, [shippingList, shippingAddresses]);

  const handleChange = ({ value, label, addressData }) => {
    if (value !== selectedLabel.value) {
      setSelectedLabel({
        value,
        label,
        addressData,
      });

      // when user switches, we pass data to api
      if (addressData) {
        const reqBody = {
          countryCode: addressData?.countryCode,
          provinceCode: addressData?.provinceCode,
          city: addressData?.city,
          postalCode: addressData?.postalCode.split(/\s/).join(''),
          addressLine1: addressData?.addressLine1,
          addressLine2: addressData?.addressLine2,
          firstName: addressData?.firstName,
          lastName: addressData?.lastName,
          primaryPhoneNumber: addressData?.primaryPhoneNumber.replace(/-/g, ''),
          primary: true,
          uniqueId: addressData?.uniqueId,
        };

        actions.updateUser({
          selectedShippingAddress: {
            addressData: reqBody,
          },
        });

        updateAddress(reqBody, accessToken)
          .then(async (res) => {
            if (res.data) {
              await getCustomerAddresses(accessToken)
                // eslint-disable-next-line no-shadow
                .then((res) => {
                  const customerAddresses = res.data.data;
                  setAddressList(shippingList, ...customerAddresses);

                  actions.updateUser({ shippingAddresses: customerAddresses });
                })
                .catch((error) => {
                  if (error.response !== null && error.response.data !== null) {
                    actions.updateNotification({
                      message: generateErrorMessage(
                        error?.response?.data?.validationErrors
                          ? Object.keys(error.response.data.validationErrors)
                          : ['General.BadRequest'],
                        errorsList
                      ),
                      status: 'error',
                    });
                  }
                  console.error(error);
                });
            }
          })
          .catch((error) => {
            if (error.response !== null && error.response.data !== null) {
              actions.updateNotification({
                message: generateErrorMessage(
                  error?.response?.data?.validationErrors
                    ? Object.keys(error.response.data.validationErrors)
                    : ['General.BadRequest'],
                  errorsList
                ),
                status: 'error',
              });
            }
            console.error(error);
          });
      }
    }
  };

  return (
    <div>
      <Select
        name="shipping-address-dropdown"
        aria-required
        isSearchable={false}
        options={shippingOptions}
        value={selectedLabel}
        onChange={handleChange}
        styles={shippingSelectStyles}
      />
    </div>
  );
}

ShippingAddressSelect.propTypes = {
  shippingList: PropTypes.array,
  setAddressList: PropTypes.array,
  errorsList: PropTypes.array,
  profileContent: PropTypes.object,
};
