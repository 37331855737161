/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { renderHTML } from '@agility/nextjs';
import Image from 'next/image';
import { StyledList, ImageContainer } from './EmpireBannersGridStyles';

export default function EmpireBannersGrid({ customData }) {
  if (customData?.empireList?.length) {
    return (
      <StyledList>
        {customData?.empireList.map((item, index) => (
          <li key={index} className="list-none">
            <ImageContainer>
              <Image
                src={item.fields.image?.url}
                alt={item.fields.title}
                width={160}
                height={160}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </ImageContainer>
            <div className="text-container">
              <strong className="mb-2 block">{item.fields.title}</strong>

              <div dangerouslySetInnerHTML={renderHTML(item.fields.description)} />
            </div>
          </li>
        ))}
      </StyledList>
    );
  }
  return null;
}

EmpireBannersGrid.propTypes = { customData: PropTypes.object };
