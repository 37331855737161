import PropTypes from 'prop-types';
import Image from 'next/image';

import { Icon } from '../HeaderStyles';

export default function OpenMobileMenuButton({ setOpenLanguageMobile, setOpenHumburger, openHumburger }) {
  return (
    <button
      onClick={() => {
        setOpenLanguageMobile(false);
        setOpenHumburger(!openHumburger);

        document.body.classList.add('prevent-mobile-scroll');
      }}
      type="button"
      aria-haspopup="true"
      aria-expanded={openHumburger}
      aria-controls="menuHumburger"
      aria-label="Menu"
      className="header-link-humburger"
    >
      <Icon>
        <Image
          src="/assets/menu-humburger.svg"
          alt="Menu Icon"
          height={30}
          width={30}
          aria-hidden
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      </Icon>
    </button>
  );
}

OpenMobileMenuButton.propTypes = {
  setOpenLanguageMobile: PropTypes.func,
  setOpenHumburger: PropTypes.func,
  openHumburger: PropTypes.bool,
};
