import PropTypes from 'prop-types';
import Link from 'next/link';
import Image from 'next/image';

import { Section } from './OpenPDFSectionStyles';

export default function OpenPDFSection({ buttonText, urlLink }) {
  if (buttonText && urlLink) {
    return (
      <Section>
        <Link href={urlLink} as={urlLink} target="_blank" rel="noopener noreferrer">
          <>
            <div className="flex justify-center image-wrapper">
              <Image
                src="/assets/printer.svg"
                role="presentation"
                alt=""
                height={40}
                width={40}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </div>
            <strong>{buttonText}</strong>
          </>
        </Link>
      </Section>
    );
  }

  return null;
}

OpenPDFSection.propTypes = { buttonText: PropTypes.string, urlLink: PropTypes.string };
