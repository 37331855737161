export function disableScrolling() {
  const x = window.scrollX;
  const y = window.scrollY;
  window.onscroll = function () {
    window.scrollTo(x, y);
  };
  document.body.classList.add('portal-prevent-mobile-scroll');
}

export function enableScrolling() {
  window.onscroll = function () {};
  document.body.classList.remove('portal-prevent-mobile-scroll');
}
