import PropTypes from 'prop-types';
import Image from 'next/image';

import Button from '../Button/Button';
import CloseIcon from '../Icons/CloseIcon';

// Styles

import { ModalContainer, ModalIcon, ModalHeader, ModalBody, ButtonContainer, ModalPopupContainer } from './ModalStylesV2';

export default function UnsubscribeSuccessModal({ toggle, dynamicData }) {
  const { buttonLabel, successHeader, successMessage } = dynamicData;

  return (
    <ModalContainer>
      <CloseIcon toggle={toggle} />
      <ModalPopupContainer>
        <ModalHeader>
          <ModalIcon>
            <Image
              src="/assets/checkmark-circle.svg"
              alt=""
              height={80}
              width={80}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </ModalIcon>
          {successHeader && <h2 id="modal-label">{successHeader}</h2>}
        </ModalHeader>
        {successMessage && <ModalBody>{successMessage}</ModalBody>}
        {buttonLabel && (
          <ButtonContainer>
            <Button type="button" ariaLabel={buttonLabel} role="button" onClick={() => toggle()}>
              {buttonLabel}
            </Button>
          </ButtonContainer>
        )}
      </ModalPopupContainer>
    </ModalContainer>
  );
}

UnsubscribeSuccessModal.propTypes = { toggle: PropTypes.func, dynamicData: PropTypes.object };
