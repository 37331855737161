import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { renderHTML } from '@agility/nextjs';

// Components
import ErrorMessage from '../../common/Errors/ErrorMessage';

// Styles
import { CheckboxWrapper, StyledCheckbox, FormCheckboxExtraText } from '../FormStyles';

// Helpers
import { arrayEquals } from '../../../helpers/utils';
import { handleGAEventsBase } from '../../../helpers/handleGoogleAnalyticsHelper';

export default function Checkboxes({
  options,
  control,
  name,
  isDisabled,
  alignStart,
  defaultChecked,
  labels,
  className,
  extraText,
  errorMessage,
  isToggle,
  showInnerErrorMsg,
  skipValidation,
  gaFlow = '',
}) {
  function generateControllerOptions() {
    if (skipValidation) {
      return {
        control,
        name,
      };
    }

    return {
      control,
      name,
      rules: {
        required: {
          message: errorMessage,
          value: true,
        },
      },
    };
  }

  const { field, formState } = useController(generateControllerOptions());

  const [value, setValue] = useState(field.value || []);
  const error = formState?.errors?.[name];
  const isError = (error && formState.touchedFields?.[name]) || (error && formState.isSubmitted);

  const handleChange = (e, index) => {
    const valueCopy = [...value];

    // update checkbox value
    if (isToggle) {
      // only track one value, which selects only 1 checkbox at a time
      valueCopy[0] = e.target.value;
    } else {
      valueCopy[index] = e.target.checked ? e.target.value : null;
    }

    // send data to react hook form
    field.onChange(valueCopy);

    // update local state
    setValue(valueCopy);
    if (gaFlow === 'registerGK') {
      const YesNo = valueCopy[0].charAt(0).toUpperCase() + valueCopy[0].slice(1).toLowerCase();
      const dataLayer = {
        event: 'sign_up_form_field',
        category: 'Registration Events',
        action: 'Form Field Click',
        label: `Unregistered - ${YesNo}`,
        sign_up_step: 'Step 1',
        sign_up_form_field: `Unregistered - ${YesNo}`,
      };
      handleGAEventsBase(dataLayer);
    }
  };

  // subscribe to external changes to fields from outside checkbox but within hook form
  // update the fields to correctly represent the new state of user input
  useEffect(() => {
    if (field?.value && !arrayEquals(field?.value, value)) {
      setValue(field.value);
    }
  }, [field?.value, value]);

  return (
    <>
      {options?.map((option, index) => (
        <CheckboxWrapper className={`${isDisabled ? 'isDisabled' : ''}`} alignStart={alignStart} key={option}>
          <StyledCheckbox
            key={option}
            name={name}
            id={name + index}
            aria-invalid={!!error}
            role="checkbox"
            aria-labelledby={`form-${name}`}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            aria-checked={defaultChecked ? 'true' : 'false'}
            aria-disabled={isDisabled}
            defaultChecked={defaultChecked}
            disabled={isDisabled}
            isError={Boolean(isError)}
            onChange={(e) => handleChange(e, index)}
            checked={value.includes(option)}
            type="checkbox"
            value={option}
            inputRef={field.ref}
          />
          <div>
            <div className="ml-2.5">
              {labels[index] && (
                // disabling the auto focus from clicking label associated with checkbox
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <label
                  id={`form-${name + index}`}
                  htmlFor={name + index}
                  className={className}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                >
                  {labels[index]}
                </label>
              )}
            </div>
            {extraText && (
              <div className="max-w-full">
                <FormCheckboxExtraText dangerouslySetInnerHTML={renderHTML(extraText)} />
              </div>
            )}
          </div>
          {isError && showInnerErrorMsg && <ErrorMessage error={error} />}
        </CheckboxWrapper>
      ))}
    </>
  );
}

Checkboxes.propTypes = {
  errorMessage: PropTypes.string,
  isToggle: PropTypes.bool,
  showInnerErrorMsg: PropTypes.bool,
  options: PropTypes.array,
  control: PropTypes.object,
  name: PropTypes.string,
  isDisabled: PropTypes.bool,
  alignStart: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  labels: PropTypes.array,
  className: PropTypes.string,
  extraText: PropTypes.string,
  gaFlow: PropTypes.string,
};

Checkboxes.defaultProps = {
  errorMessage: '',
  isToggle: false,
  showInnerErrorMsg: false,
};
