import Image from 'next/image';
import { IconColumn } from './LogoDescriptionSectionStyles';

export default function LogoContainer({ data }) {
  if (data.length) {
    return data.map((item, index) => (
      <IconColumn key={index} className={data.length === 1 ? 'single-logo' : 'multiple-logos'}>
        <a href={item.link.href} target="_blank" aria-label={item.link.text} rel="noopener noreferrer">
          <Image
            src={item.image.url}
            alt=""
            height={data.length === 1 ? 160 : 120}
            width={data.length === 1 ? 160 : 120}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </a>
      </IconColumn>
    ));
  }

  return null;
}
