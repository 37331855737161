import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Image from 'next/image';

// Components
import OffersSpotlightCard from '../Cards/OffersSpotlightCard';

// Styles
import { BannerItem, BannerContents } from './BannerStyles';

// Helper
import { handleKeyDown } from '../../../helpers/utils';
import {
  getSpotlightMappingOffer,
  getSpotlightStandaloneMappingOffer,
  getSpotlightDetailsMappingOffer,
} from '../../../helpers/handleGoogleAnalyticsHelper';
import { guidGenerator } from '../../../helpers/guidHelper';

export default function OffersSliderTile({
  customData,
  content,
  bannersNum,
  languageCode,
  toggle,
  sectionTitle,
  index,
  bannerType,
}) {
  const { webImage, title, ctaLink } = content.cmsContent;
  const { linkedType } = content;
  const isStandalone = linkedType === 'Standalone';

  useEffect(() => {
    try {
      if (content && content.linkedType) {
        getSpotlightMappingOffer('view_promotion', content, sectionTitle, index, bannerType);
      }
    } catch {
      //
    }
  }, []);

  function handleClick(e) {
    if (isStandalone) {
      getSpotlightStandaloneMappingOffer('select_promotion', content, sectionTitle, index);
    } else {
      toggle(content?.spotlightId);
      e.preventDefault();
      getSpotlightDetailsMappingOffer('select_promotion', content, sectionTitle, index);
    }
  }

  return (
    <BannerItem id={guidGenerator()} bannersNum={bannersNum}>
      <Link
        href={`${isStandalone ? ctaLink && ctaLink?.href : '#'}`}
        aria-label={ctaLink && ctaLink?.text}
        role="link"
        onClick={() => {
          handleClick();
        }}
        tabIndex="0"
        onKeyDown={(e) => handleKeyDown(e, handleClick)}
        target={`${isStandalone ? ctaLink && ctaLink?.target : null}`}
      >
        <BannerContents className="banner-content-wrapper flex justify-center items-center" bannersNum={bannersNum}>
          <div className="relative banner-image">
            <Image
              src={(webImage && webImage?.url) || '/assets/no-image.png'}
              alt=""
              loading="eager"
              fill
              sizes="(max-width: 1400px) 800px"
              style={{
                objectFit: 'cover',
                objectPosition: '50% 50%',
              }}
            />
          </div>

          <OffersSpotlightCard
            id={guidGenerator()}
            code={content.spotlightId}
            customData={customData}
            content={content}
            bannersNum={bannersNum}
            languageCode={languageCode}
            isStandalone={isStandalone}
            toggle={toggle}
          />
        </BannerContents>
      </Link>
    </BannerItem>
  );
}

OffersSliderTile.propTypes = {
  customData: PropTypes.object,
  content: PropTypes.object,
  bannersNum: PropTypes.number,
  languageCode: PropTypes.string,
  toggle: PropTypes.func,
  sectionTitle: PropTypes.string,
  index: PropTypes.string,
  bannerType: PropTypes.string,
};
