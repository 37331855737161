import { useState } from 'react';
import PropTypes from 'prop-types';

import Image from 'next/image';

// Global State
import { useStateMachine } from 'little-state-machine';
import { updateOffers, updateNotification } from '../../../../store/actions';

// Services
import { loadOffers } from '../../../../pages/api/services';

// Components
import LoadOffer from './LoadOffer';

// Hooks
import useDragDetection from '../../../hooks/utils/useDragDetection';

// Helpers
import { insertParam, updateURI } from '../../../../helpers/navigationHelpers';
import { formatDate } from '../../../../helpers/dateHelpers';
import { updateOptedInStatusFrom } from '../../../../helpers/offersHelpers';
import {
  handleGAEvents,
  handleGaEcommImpression,
  getOffersMappedObject,
  getOffersModalMappedObject,
} from '../../../../helpers/handleGoogleAnalyticsHelper';
import { guidGenerator } from '../../../../helpers/guidHelper';

// Styles
import {
  CardItem,
  Brand,
  CardHeader,
  CardImage,
  LogoBadge,
  CardBody,
  CardContents,
  CardTitle,
  CardSubitle,
  CouponBanner,
} from '../CardStyles';

export default function OfferCard({ currentItemId, content, offersNum, toggle, offer, customData, isGrid, sectionTitle, id }) {
  const [loading, setLoading] = useState(false);

  const { title, subTitle, brand, webImage, isCouponBased, couponBasedBannerContent, getCouponLink } = content.cmsContent;

  const {
    messageOfferLoaded,
    messageOfferNotLoaded,
    messageOfferUnloaded,
    loadingLabel,
    unloadingLabel,
    loadOfferLabel,
    offerLoadedLabel,
    offerPreloadedLabel,
    expireLabel,
    getCouponLabel,
  } = customData;

  const {
    actions,
    state: {
      session: { accessToken },
      language: { locale },
      offers: {
        offersList,
        selectedFilters,
        limitedTimeOffersList,
        offersForYouList,
        moreOffersList,
        moreWaysToEarn,
        featuredOffer,
      },
      navigation: { page },
      location,
    },
  } = useStateMachine({ updateOffers, updateNotification });

  const status = offer && offer.isOptedIn;
  const isLoadable = offer?.tags && offer.tags.includes('loadable');
  const isEvergreen = offer?.tags && offer.tags.includes('evergreen');

  const { handleMouseDown, dragging } = useDragDetection();

  const handleTileClick = () => {
    handleGaEcommImpression(
      'offer_select_item',
      getOffersMappedObject(offer, parseInt(id?.split('-')[1]) + 1, sectionTitle || '', location?.province)
    );
  };

  const handleChildClick = (e) => {
    if (dragging) {
      e.preventDefault();
      return;
    }

    updateURI(insertParam('offer', offer.offerCode));
    handleTileClick();
    toggle(currentItemId);
  };
  const handleCouponBasedOfferClick = (e) => {
    handleGaEcommImpression(
      'offer_add_to_cart',
      getOffersModalMappedObject(
        offer,
        parseInt(id?.split('-')[1]) + 1,
        sectionTitle,
        'Offer Loaded',
        'offers_load_on_tile',
        location?.province
      )
    );
  };
  const handleOfferLoadUnload = async (e) => {
    e.persist();
    if (isLoadable && !status) {
      handleGaEcommImpression(
        'offer_add_to_cart',
        getOffersModalMappedObject(
          offer,
          parseInt(id?.split('-')[1]) + 1,
          sectionTitle,
          'Offer Loaded',
          'offers_load_on_tile',
          location?.province
        )
      );
    }
    if (isLoadable && status) {
      handleGaEcommImpression(
        'offer_remove_from_cart',
        getOffersModalMappedObject(
          offer,
          parseInt(id?.split('-')[1]) + 1,
          sectionTitle,
          'Offer unloaded',
          'offers_unload_offer_tile',
          location?.province
        )
      );
    }
    setLoading(true);
    const reqBody = [{ offerId: offer.offerId, optIn: !offer.isOptedIn }];

    const response = await loadOffers(reqBody, accessToken);
    if (response?.data?.data === true) {
      const offerClicked = { ...offer };

      // find all instances where the loaded offer would be located.
      if (page === '/' || page === '/offers/limited-time') {
        actions.updateOffers({
          limitedTimeOffersList: updateOptedInStatusFrom(limitedTimeOffersList, offerClicked),
        });
      } else if (selectedFilters === 'featured') {
        // TODO: optimize this with a conditional helper that will cherry pick the arrays based on the tags/categories of the offer (See derive offers from helper)
        actions.updateOffers({
          limitedTimeOffersList: updateOptedInStatusFrom(limitedTimeOffersList, offerClicked),
          offersForYouList: updateOptedInStatusFrom(offersForYouList, offerClicked),
          moreOffersList: updateOptedInStatusFrom(moreOffersList, offerClicked),
          moreWaysToEarn: updateOptedInStatusFrom(moreWaysToEarn, offerClicked),
          featuredOffer: updateOptedInStatusFrom(featuredOffer, offerClicked),
          offersList: updateOptedInStatusFrom(offersList, offerClicked),
        });
      } else {
        actions.updateOffers({ offersList: updateOptedInStatusFrom(offersList, offerClicked) });
      }

      actions.updateNotification({
        message: offer.isOptedIn ? messageOfferLoaded : messageOfferUnloaded,
        status: offer.isOptedIn ? 'success' : 'warning',
      });
    } else {
      actions.updateNotification({
        message: messageOfferNotLoaded,
        status: 'error',
      });
      handleGAEvents(
        `${content.cmsContent.brand?.title} | ${title}`,
        'Partner Offers',
        'Offer Loaded Fail',
        'offers_loaded_fail'
      );
    }
    setLoading(false);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      updateURI(insertParam('offer', offer.offerCode));
      toggle(currentItemId);
    }
  };

  return (
    <CardItem id={guidGenerator()} offersNum={offersNum} className="flex card-item card-content-wrapper" isGrid={isGrid}>
      <CardContents className="card-contents" offersNum={offersNum}>
        <div
          tabIndex="0"
          className="offer-link"
          role="link"
          onMouseDownCapture={handleMouseDown}
          onClickCapture={handleChildClick}
          onKeyDown={handleKeyDown}
          style={{ cursor: 'pointer' }}
        >
          <CardHeader offersNum={offersNum} className="w-full">
            {brand && brand?.logoTransparentBG?.url && (
              <LogoBadge className="rounded-full overflow-hidden">
                <Image
                  src={brand?.logoTransparentBG?.url}
                  alt={brand?.title}
                  aria-hidden="true"
                  quality={25}
                  fill
                  sizes="100vw"
                  style={{
                    objectFit: 'contain',
                  }}
                />
              </LogoBadge>
            )}

            {webImage && (
              <CardImage className="relative w-full h-full" aria-hidden="true">
                <Image
                  src={webImage?.url || '/assets/no-image.png'}
                  alt=""
                  loading="eager"
                  fill
                  sizes="(max-width: 1400px) 800px"
                  style={{
                    objectFit: 'cover',
                    objectPosition: '50% 50%',
                  }}
                />
                {isCouponBased && couponBasedBannerContent && (
                  <CouponBanner className="coupon-banner">{couponBasedBannerContent}</CouponBanner>
                )}
              </CardImage>
            )}
          </CardHeader>

          <CardBody>
            {brand && <Brand className="font-bold brand">{brand?.title}</Brand>}

            {title && <CardTitle className="font-bold">{title}</CardTitle>}

            {subTitle && <CardSubitle>{subTitle}</CardSubitle>}
            {content.endDate && (
              <p className="text-xs m-0 card-details">
                {expireLabel} {formatDate(content.endDate, 'MMM DD', locale)}
              </p>
            )}
          </CardBody>
        </div>

        <LoadOffer
          status={status}
          isLoadable={isLoadable}
          isEvergreen={isEvergreen}
          isCouponBased={isCouponBased}
          offerLoadedLabel={offerLoadedLabel}
          loadOfferLabel={loadOfferLabel}
          offerPreloadedLabel={offerPreloadedLabel}
          handleOfferLoadUnload={handleOfferLoadUnload}
          handlePreloadedClick={handleChildClick}
          handleCouponBasedOfferClick={handleCouponBasedOfferClick}
          unloadingLabel={unloadingLabel}
          loadingLabel={loadingLabel}
          loading={loading}
          getCouponLink={getCouponLink}
          getCouponLabel={getCouponLabel}
        />
      </CardContents>
    </CardItem>
  );
}

OfferCard.propTypes = {
  currentItemId: PropTypes.string,
  content: PropTypes.object,
  offersNum: PropTypes.number,
  toggle: PropTypes.func,
  offer: PropTypes.object,
  customData: PropTypes.object,
  isGrid: PropTypes.bool,
  sectionTitle: PropTypes.string,
  id: PropTypes.string,
};
