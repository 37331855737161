/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';

import Image from 'next/image';
import Link from 'next/link';
import { renderHTML } from '@agility/nextjs';

import WrapperLayout from '../layouts/WrapperLayout';
import Title from '../common/Headings/Title';
import {
  ContentCol,
  NoCardContents,
  CardAvatarWrapper,
  NoCardAvatar,
  InnerColumnBox,
  BackgroundHeroPFC,
} from './Shop/PointsShopStyles';

export default function NoCardApply({ customData }) {
  const {
    noCardTitle,
    image,
    sectionHeader,
    debitCardTitle,
    debitCardMessage,
    debitCardLinkLabelSR,
    debitCardLink,
    creditCardTitle,
    creditCardMessage,
    creditCardLinkSR,
    creditCardLink,
    debitCardImage,
    creditCardImage,
    howItWorksTitle,
    noCardHowItWorksMessage,
  } = customData;

  const bankCards = [
    {
      cardTitle: creditCardTitle,
      cardMessage: creditCardMessage,
      cardLinkLabel: creditCardLinkSR,
      cardLink: creditCardLink,
      cardImage: creditCardImage,
    },
    {
      cardTitle: debitCardTitle,
      cardMessage: debitCardMessage,
      cardLinkLabel: debitCardLinkLabelSR,
      cardLink: debitCardLink,
      cardImage: debitCardImage,
    },
  ];

  return (
    <>
      <WrapperLayout className="w-full relative page-bottom-spacing" isGray mobileIsWhite>
        <div className="lg:pt-4 global-content-wrapper">
          <div>
            <Title className="mb-8 lg:mb-12">{noCardTitle}</Title>
          </div>

          <div className="sm:flex justify-between">
            <ContentCol className="sm:pr-8 relative">
              <div>
                {howItWorksTitle && <h2>{howItWorksTitle}</h2>}
                {noCardHowItWorksMessage && <div dangerouslySetInnerHTML={renderHTML(noCardHowItWorksMessage)} />}
              </div>

              <BackgroundHeroPFC className="hidden sm:block w-full">
                <div className="img-container">
                  <Image
                    src={`${image ? image.url : '/assets/placeholder.svg'}`}
                    alt=""
                    loading="eager"
                    role="presentation"
                    fill
                    sizes="100vw"
                    style={{
                      objectFit: 'cover',
                      objectPosition: 'bottom',
                    }}
                  />
                </div>
              </BackgroundHeroPFC>
            </ContentCol>
            <ContentCol className="cards-col">
              <InnerColumnBox>
                {sectionHeader && <h3 className="font-bold text-md mt-8 mb-8">{sectionHeader}</h3>}
                {bankCards && (
                  <div className="flex justify-center flex-col">
                    {bankCards.map((card, index) => (
                      <div key={index} className="flex flex-1 items-start mb-8">
                        <CardAvatarWrapper>
                          <NoCardAvatar className="rounded-full">
                            <Image
                              src={`${card.cardImage ? card.cardImage.url : '/assets/placeholder.svg'}`}
                              alt={card.cardTitle || ''}
                              loading="eager"
                              fill
                              sizes="100vw"
                              style={{
                                objectFit: 'contain',
                                objectPosition: 'center top',
                              }}
                            />
                          </NoCardAvatar>
                        </CardAvatarWrapper>
                        <NoCardContents>
                          {card.cardTitle && <p className="font-bold text-sm mb-2">{card.cardTitle}</p>}
                          {card.cardMessage && <p className="text-sm mb-2">{card.cardMessage}</p>}
                          <Link href={card.cardLink.href} title={card.cardLinkLabel} target={card.cardLink.target}>
                            {card.cardLink.text}
                          </Link>
                        </NoCardContents>
                      </div>
                    ))}
                  </div>
                )}
              </InnerColumnBox>
            </ContentCol>
          </div>
        </div>
      </WrapperLayout>
    </>
  );
}
NoCardApply.propTypes = { customData: PropTypes.object };
